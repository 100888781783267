import { Checkbox } from "@components/lineup/checkbox";
import useAnalytics from "@hooks/useAnalytics";
import useGlobalState from "@hooks/useGlobalState";
import { useJoinGroupMutation, useLeaveGroupMutation } from "@hooks/useGroupMutation";
import useSession from "@hooks/useSession";
import { Button } from "@styles/buttons.styled";
import { InputErrorText } from "@styles/inputs.styled";
import { colors } from "@styles/styleUtils";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import settings from "src/settings";
import styled from "styled-components";
import { ManageGroupdDialogCSS } from "@styles/modal.styled";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
const { BASE_PATH } = settings;

export const ContentContainer = styled.div`
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DescriptionContainer = styled.div`
  width: 80%;
  max-width: 350px;
  margin: 0 auto 25px auto;
`;

export const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.14;
  text-align: center;
`;

export const LineupRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
  border-top: 0.5px solid ${colors.lvl_2};
  &:first-child {
    border-top: none;
  }
  button {
    align-self: center;
    margin-right: 10%;
  }
  label {
    margin-right: auto;
    padding-left: 10%;
    align-self: center;
  }
`;

export const LineupsContainer = styled.div`
  border-top: 0.5px solid ${colors.lvl_2};
  max-height: 35vh;
  overflow-y: auto;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  padding: 22px 0;
  border-top: 0.5px solid ${colors.lvl_2};

  button {
    margin: 0 auto;
    width: 85%;
    max-width: 345px;
  }
`;

function calcGroupEntries(sessionEntries, groupId) {
  const entriesInGroups = [];
  sessionEntries?.map((entry) =>
    entry.group_memberships?.forEach((groupMembership) => {
      if (groupMembership.group_id == groupId) {
        entriesInGroups.push(entry.entry_id);
      }
    }),
  );
  return entriesInGroups;
}

export default function ManageLineupModal() {
  const { updateDataLayer } = useAnalytics();
  const [state, dispatch] = useGlobalState();
  const { mutateAsync: leaveGroup, isPending: leaveGroupIsLoading } = useLeaveGroupMutation();
  const { data: session } = useSession();
  const router = useRouter();
  const { mutateAsync: joinGroup, isPending: joinGroupIsLoading } = useJoinGroupMutation();
  const [maxEntriesMsg, setMaxEntriesMsg] = useState(false);
  const originalEntriesInGroup = calcGroupEntries(session?.entries, state.modal.options.activeGroup?.group_id);
  const [selectedLineupIds, setSelectedLineupId] = useState(originalEntriesInGroup);
  const maxMembersPerUser = state.modal.options.activeGroup?.max_entries;
  const apiLoading = joinGroupIsLoading || leaveGroupIsLoading;
  const isCommissioner = session?.user?.id == state?.modal?.options?.activeGroup?.creator_id;
  const cantLeave = selectedLineupIds.length === 0 && state.modal.options.mayLeaveGroup === false;
  const canLeave = selectedLineupIds.length === 0 && state.modal.options.mayLeaveGroup === true;
  const leavingCommisioner = isCommissioner && selectedLineupIds.length === 0;

  useEffect(() => {
    function calcGroupEntries(sessionEntries, groupId) {
      const entriesInGroups = [];
      sessionEntries?.map((entry) =>
        entry.group_memberships?.forEach((groupMembership) => {
          if (groupMembership?.group_id == groupId) {
            entriesInGroups.push(entry.entry_id);
          }
        }),
      );
      return entriesInGroups;
    }
    setSelectedLineupId(calcGroupEntries(session?.entries, state.modal.options.activeGroup?.group_id));
  }, [state.modal.options.activeGroup?.group_id, session?.entries]);

  const handleLineupChange = (id) => {
    // Handle clicking a checkbox that is alrleady clicked
    if (selectedLineupIds.includes(id)) {
      const modifiedArray = [...selectedLineupIds];
      modifiedArray.splice(selectedLineupIds.indexOf(id), 1);
      setSelectedLineupId(modifiedArray);
    } else {
      // reached max members per user
      if (selectedLineupIds.length === maxMembersPerUser) {
        const modifiedArray = [...selectedLineupIds];
        modifiedArray.splice(0, 1, id);
        setSelectedLineupId(modifiedArray);
        setMaxEntriesMsg(true);
        setTimeout(() => {
          setMaxEntriesMsg(false);
        }, 2000);
      } else {
        setSelectedLineupId([...selectedLineupIds, id]);
      }
    }
  };

  async function submitLineupPicks(e) {
    e.preventDefault();

    if (!selectedLineupIds.length) {
      const entriesInGroup = [];
      session?.entries?.forEach((entry) => {
        entry.group_memberships.forEach((group) => {
          if (group.group_id == state.modal.options.activeGroup.group_id) {
            entriesInGroup.push(entry.entry_id);
          }
        });
      });
      await leaveGroup({
        entry_ids: entriesInGroup,
        group_id: state.modal.options.activeGroup?.group_id,
      }).then(() => {
        dispatch((state) => {
          state.modal.openModal = null;
          state.modal.options.mayLeaveGroup = false;
        });
      });
    } else {
      const leaveEntries = [];
      originalEntriesInGroup.forEach((id) => {
        if (selectedLineupIds.indexOf(id) === -1) {
          leaveEntries.push(id);
        }
      });

      session?.entries?.forEach((entry) => {
        entry.group_memberships.forEach((group) => {
          if (group.group_id === state.modal.options.activeGroup.group_id) {
            if (selectedLineupIds.indexOf(group.entry_id) !== -1) {
              selectedLineupIds.splice(selectedLineupIds.indexOf(group.entry_id), 1);
            }
          }
        });
      });

      if (leaveEntries.length > 0) {
        await leaveGroup({
          entry_ids: leaveEntries,
          group_id: state.modal.options.activeGroup?.group_id,
        });
      }
      await joinGroup({
        entry_ids: selectedLineupIds,
        group_id: state.modal.options.activeGroup?.group_id,
        password: state.modal.options.pwInput,
      }).then(() => {
        if (state.modal.options.showGroupCreationSuccess) {
          dispatch((state) => {
            state.modal.openModal = "GROUP_CREATION_SUCCESS";
          });
        } else {
          router.push(`${BASE_PATH}/group/${state.modal.options.activeGroup.group_id}`);
          dispatch((state) => {
            state.modal.openModal = null;
          });
        }
      });
    }
  }
  const isOpen = useMemo(() => state.modal.openModal === "MANAGE_LINEUPS", [state.modal.openModal]);

  useEffect(() => {
    if (isOpen) {
      updateDataLayer({
        page: {
          pageInfo: {
            pageName: "slc groups manage lineups",
            mmlUrl: "ncaa:watchmml:play:slc:groups:detail:manage_lineups",
            section: "play",
            subsection: "play:slc",
            challengeGameName: "slc",
          },
        },
      });
    }
  }, [isOpen, updateDataLayer]);

  return (
    <MMLModal
      removeContentPadding
      maxWidth={303}
      css={ManageGroupdDialogCSS}
      isOpen={isOpen}
      title="MANAGE LINEUPS"
      handleClose={() => {
        dispatch((state) => {
          state.modal.openModal = null;
        });
      }}
    >
      <ContentContainer>
        <DescriptionContainer>
          <Description>
            Select up to {maxMembersPerUser} lineups for &quot;
            {state.modal.options.activeGroup?.name}&quot;, or deselect all lineups to leave the group.
          </Description>
          <InputErrorText $shouldShow={maxEntriesMsg}>Maximum lineups reached for this group</InputErrorText>
        </DescriptionContainer>
        <form>
          <LineupsContainer>
            {session?.entries?.map(({ name, entry_id }) => {
              return (
                <LineupRow key={entry_id}>
                  <label>{name}</label>
                  <Checkbox
                    checked={selectedLineupIds.includes(entry_id)}
                    onCheckedChange={() => handleLineupChange(entry_id)}
                  />
                </LineupRow>
              );
            })}
          </LineupsContainer>
          <InputErrorText
            $shouldShow={leavingCommisioner && !router.pathname.includes("create-group")}
            style={{ padding: "15px" }}
          >
            You cannot remove all your lineups from a group you created. Please try deleting the group instead if
            desired.
          </InputErrorText>
          <ButtonContainer>
            <Button
              disabled={cantLeave || apiLoading || leavingCommisioner}
              onClick={(e) => submitLineupPicks(e)}
              $primary
              type="submit"
              size="md"
            >
              {canLeave && !joinGroupIsLoading
                ? "Leave Group"
                : joinGroupIsLoading || leaveGroupIsLoading
                ? "Submitting.."
                : "Done"}
            </Button>
          </ButtonContainer>
        </form>
      </ContentContainer>
    </MMLModal>
  );
}
