import useGlobalState from "@hooks/useGlobalState";
import useSession from "@hooks/useSession";
import { RadioFlex, RadioGroup, RadioGroupIndicator, RadioGroupRadio, RadioLabel } from "@styles/inputs.styled";
import { colors } from "@styles/styleUtils";
import React from "react";
import styled from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";

type SeletLineupProps = {
  setLineupValue: (id: number) => void;
  activeLineupName: string;
};

const StyledRadioGroupRadio = styled(RadioGroupRadio)`
  margin-right: 0;
`;

const StyledRadioFlex = styled(RadioFlex)`
  border-top: 0.5px solid ${colors["lvl_2"]};
  height: 57px;
  padding: 0px 18px !important;
`;

const ContentContainer = styled.div`
  width: 303px;
  text-align: center;
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default function SelectLineup({ setLineupValue, activeLineupName }: SeletLineupProps) {
  const [state, dispatch] = useGlobalState();
  const { data: session } = useSession();

  const lineups = session?.entries || [];

  const handleLineupChange = (id: number) => {
    setLineupValue(id);
    dispatch((state) => {
      state.modal.openModal = null;
    });
  };

  return (
    <MMLModal
      handleClose={() => {
        dispatch((state) => {
          state.modal.openModal = null;
        });
      }}
      maxWidth={303}
      isOpen={state.modal.openModal === "SELECT_LINEUP"}
      title="Select Lineup"
      removeContentPadding
    >
      <ContentContainer>
        <form>
          <RadioGroup defaultValue={activeLineupName} aria-label="Select a Lineup">
            {lineups?.length &&
              lineups.map((lineup) => (
                <StyledRadioFlex key={lineup.entry_id}>
                  <RadioLabel htmlFor={lineup.name}>{lineup.name}</RadioLabel>
                  <StyledRadioGroupRadio
                    value={lineup.name}
                    id={lineup.name}
                    onClick={() => handleLineupChange(lineup.entry_id)}
                  >
                    <RadioGroupIndicator />
                  </StyledRadioGroupRadio>
                </StyledRadioFlex>
              ))}
          </RadioGroup>
        </form>
      </ContentContainer>
    </MMLModal>
  );
}
