import useAnalytics from "@hooks/useAnalytics";
import useGlobalState from "@hooks/useGlobalState";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import { Button } from "@styles/buttons.styled";
import {
  ButtonContainer,
  ContentContainer,
  CopiedCopy,
  Description,
  DescriptionContainer,
  GroupInviteList,
  SVGContainer,
} from "@styles/groups/settings/groupInvite.styles";
import { useEffect, useMemo, useState } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { gameUrls } from "src/lib/url-helpers";
import settings from "src/settings";
import { ManageGroupdDialogCSS } from "@styles/modal.styled";
import useClipBoard from "@hooks/useClipboard";

const { APP_ENV } = settings;

export default function GroupInviteModal({ group, id, joinToken }) {
  const [state, dispatch] = useGlobalState();
  const [copied, setCopied] = useState(false);
  const { copyToClipboard } = useClipBoard();

  const gameName = "March Madness Men's Tournament Run";

  const passwordlessShareUrl = `${gameUrls[APP_ENV]}${gameUrls.slcPath}/group/${id}?joinToken=${joinToken}&iid=bcg_share_web_other_group_email`;

  const isOpen = useMemo(() => state.modal.openModal === "GROUP_INVITE", [state.modal.openModal]);
  const { updateDataLayer } = useAnalytics();

  useEffect(() => {
    if (isOpen) {
      updateDataLayer({
        page: {
          pageInfo: {
            pageName: "slc group invite",
            mmlUrl: "ncaa:watchmml:play:slc:groups:detail:invite",
            section: "play",
            subsection: "play:slc",
            challengeGameName: "slc",
          },
        },
      });
    }
  }, [isOpen, updateDataLayer]);

  return (
    <MMLModal
      maxWidth={303}
      removeContentPadding
      css={ManageGroupdDialogCSS}
      isOpen={isOpen}
      title="Group Invite"
      handleClose={() => {
        dispatch((state) => {
          state.modal.openModal = null;
        });
      }}
    >
      <ContentContainer>
        <DescriptionContainer>
          <Description>
            Invite your friends to join the group, <br />
            &quot;{group?.name}&quot;
          </Description>
        </DescriptionContainer>
        <GroupInviteList>
          {!state.isNative ? (
            <li className="fb-container">
              <FacebookShareButton
                style={{ display: "flex", alignItems: "center" }}
                url={passwordlessShareUrl}
                quote={`You're invited to join group ${group?.name}! Join here: ${passwordlessShareUrl}`}
              >
                <SVGContainer>
                  <div className="fb-icon" />
                </SVGContainer>{" "}
                <span>Invite Via Facebook</span>
              </FacebookShareButton>
            </li>
          ) : null}
          {!state.isNative ? (
            <li className="twitter-container">
              <TwitterShareButton
                style={{ display: "flex", alignItems: "center" }}
                url={passwordlessShareUrl}
                title={`${gameName} ${passwordlessShareUrl}`}
              >
                <SVGContainer>
                  <div className="twitter-icon" />
                </SVGContainer>{" "}
                <span>Invite Via Twitter</span>
              </TwitterShareButton>
            </li>
          ) : null}
          <li
            className="email-container"
            onClick={() =>
              dispatch((state) => {
                state.modal.openModal = "EMAIL_GROUP";
              })
            }
          >
            <SVGContainer className="email">
              <div className="email-icon" style={{ display: "flex", alignItems: "center" }} />
            </SVGContainer>{" "}
            <span>Invite Via Email</span>
          </li>
          <li className="link-container" onClick={() => copyToClipboard(passwordlessShareUrl, setCopied)}>
            <SVGContainer>
              <div className="link-icon" style={{ display: "flex", alignItems: "center" }} />
            </SVGContainer>{" "}
            <span>Copy Link</span>
          </li>
          {copied && <CopiedCopy>Link Copied to Clipboard!</CopiedCopy>}
        </GroupInviteList>
        <ButtonContainer>
          <Button
            $primary
            size="md"
            onClick={() =>
              dispatch((state) => {
                state.modal.openModal = null;
              })
            }
          >
            Done
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </MMLModal>
  );
}
