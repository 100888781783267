import ErrorComponent from "@components/ErrorComponent";
import useGlobalState from "@hooks/useGlobalState";
import React from "react";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";

export default function ApplicationError() {
  const [state, dispatch] = useGlobalState();
  return (
    <MMLModal
      maxWidth={303}
      isOpen={state.modal.openModal === "APPLICATION_ERROR"}
      handleClose={() => {
        dispatch((state) => {
          state.modal.openModal = null;
        });
      }}
    >
      <ErrorComponent />
    </MMLModal>
  );
}
