import useGame, { getGameTeamImage, Matchup, Team, useLiveMatchups, useTeamPrice } from "@hooks/useGame";
import useGlobalState from "@hooks/useGlobalState";
import { useMarketplace } from "@hooks/useMarketplace";
import Bolt from "@icons/Bolt-lg.svg";
import ExitOff from "@icons/exit-off.svg";
import RemoveBlueSVG from "@icons/remove-blue.svg";
import Locked from "@icons/Locked.svg";
import { useTheme } from "next-themes";
import { colors } from "src/styles/styleUtils";
import styled from "styled-components";

type SelectedTeamProps = {
  team?: Team;
  isEmpty?: boolean;
  isTradePreview?: boolean;
  isAddingToTrade?: boolean;
  pointsBank?: string;
  isTradeClosingModal?: boolean;
  isSellingTeam?: boolean;
  removeTeamInTrade?(team: Team, teamPrice: string): void;
  gameMatchup?: Matchup;
};

const PickedTeamContainer = styled.div<{ isEliminated?: boolean }>`
  display: flex;
  border: 0.5px solid ${colors["lvl_2"]};
  padding: 10px 15px;
  border-radius: 7px;
  align-items: center;
  height: 50px;

  h2,
  img,
  .points-container {
    opacity: ${({ isEliminated }) => (isEliminated ? 0.5 : 1)};
  }
`;

const PickedTeam = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
`;

const PickedTeamName = styled.h2`
  font-size: 2rem;
  font-stretch: condensed;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 12px;

  sub {
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.4px;
    position: relative;
    bottom: 4px;
  }
`;

const PointsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 600;
  font-stretch: condensed;
  justify-content: flex-end;
  letter-spacing: normal;
  line-height: normal;

  button {
    position: relative;
    margin-left: 10px;
    height: 28px;
    width: 28px;
  }
`;

const StyledBolt = styled(Bolt)`
  margin-right: 5px;
`;

const StyledRemove = styled(ExitOff)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: ${colors["lvl_3"]};
    border-radius: 50px;
  }
`;

const EmptyTeamLogo = styled.span`
  width: 32px;
  height: 32px;
  margin: 0 15px 0 0;
  border-radius: 27px;
  background-color: ${colors.lvl_3};
`;

const NotSelectedCopy = styled.p`
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.2rem;
`;

function SelectedTeamComponent({
  team,
  isEmpty,
  isTradePreview,
  isSellingTeam,
  isTradeClosingModal,
  removeTeamInTrade,
  isAddingToTrade,
}: SelectedTeamProps) {
  const [{ marketplace }, dispatch] = useGlobalState();
  const picksDrawerIsClosed = !marketplace.isMyPicksOpen;
  const { getTeamPrice } = useTeamPrice();
  const { data: game } = useGame();
  const teamPrice = getTeamPrice(team?.team_id);
  const { theme: theme } = useTheme();

  const imageBackground = theme === "dark" ? "bgd" : "bgl";
  const { removeTeam, tradeAdd } = useMarketplace(team?.team_id);

  const teamHasPlayedCurrentRound = game?.state?.locked_teams.some((teamId) => teamId === team?.team_id);

  const liveMatchups = useLiveMatchups();
  const hasLiveMatch = liveMatchups?.some(
    (matchup) => matchup.team_1_id === team?.team_id || matchup.team_2_id === team?.team_id,
  );

  const handleTrade = () => {
    if (isAddingToTrade) tradeAdd(team, getTeamPrice(team?.team_id));
    else isSellingTeam ? removeTeamInTrade(team, teamPrice) : removeTeam(isTradePreview);
  };

  const handleRemoveTeam = () => {
    dispatch((state) => {
      state.marketplace.errorMessage = "";
    });
    return removeTeam(isTradePreview);
  };

  return (
    <>
      {isEmpty ? (
        <PickedTeamContainer>
          <EmptyTeamLogo></EmptyTeamLogo>
          <NotSelectedCopy>Not Selected</NotSelectedCopy>
        </PickedTeamContainer>
      ) : (
        <PickedTeamContainer isEliminated={team?.is_eliminated}>
          <PickedTeam>
            {team?.isFirstFour ? (
              <img src={`/assets/images/teams/tbd-bg${theme === "light" ? "l" : "d"}.svg`} width="32" height="32" />
            ) : (
              <img src={getGameTeamImage(team?.link, imageBackground)} width="32" height="32" />
            )}
            <PickedTeamName>
              <sub>{team?.seed}</sub> {team?.school}
            </PickedTeamName>
          </PickedTeam>
          <ActionContainer>
            <PointsContainer className="points-container">
              <StyledBolt />
              {teamPrice}
            </PointsContainer>
            {isTradePreview ? (
              <button
                disabled={teamHasPlayedCurrentRound || hasLiveMatch}
                tabIndex={picksDrawerIsClosed && -1}
                onClick={() => handleTrade()}
              >
                {teamHasPlayedCurrentRound || hasLiveMatch ? (
                  <Locked />
                ) : !isAddingToTrade ? (
                  <StyledRemove />
                ) : (
                  <RemoveBlueSVG />
                )}
              </button>
            ) : hasLiveMatch ? (
              <button disabled={true}>
                <Locked />
              </button>
            ) : (
              !isTradeClosingModal && (
                <button tabIndex={picksDrawerIsClosed && -1}>
                  <StyledRemove onClick={() => handleRemoveTeam()} />
                </button>
              )
            )}
          </ActionContainer>
        </PickedTeamContainer>
      )}
    </>
  );
}

export default SelectedTeamComponent;
