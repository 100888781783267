import { Bundle, SLCEntry } from "@hooks/useBundle";
import useGlobalState from "@hooks/useGlobalState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ModalOptions } from "src/types/global-state";
import useApiFetch, {
  dynamicApiEndpoint,
  dynamicConfPickemApiEndpoint,
  dynamicSlcApiEndpoint,
  dynamicWomensBcgApiEndpoint,
  dynamicWomensConfPickemApiEndpoint,
  handleBackendError,
} from "./useApiFetch";
import settings from "src/settings";

export default function useCreateEntryMutation() {
  const { fetchApiEndpoint } = useApiFetch(true);
  const queryClient = useQueryClient();
  const [, dispatch] = useGlobalState();
  const { API_BACKEND_URL } = settings;

  type EntryType = ModalOptions["entryType"];

  interface BundleCreateEntryArgs {
    entryType: EntryType;
    confidence_mode?: boolean;
  }
  function createEntry(entryDetails: BundleCreateEntryArgs) {
    const { entryType, confidence_mode } = entryDetails;
    const endpoints: { [key in EntryType]: string } = {
      slc: `${API_BACKEND_URL}${dynamicSlcApiEndpoint}/entries.json`,
      bcg: `${API_BACKEND_URL}${dynamicApiEndpoint}/entries/create.json`,
      wbcg: `${API_BACKEND_URL}${dynamicWomensBcgApiEndpoint}/entries/create.json`,
      wctp: `${API_BACKEND_URL}${dynamicWomensConfPickemApiEndpoint}/entries.json`,
      mctp: `${API_BACKEND_URL}${dynamicConfPickemApiEndpoint}/entries.json`,
    };

    const body = entryType === "wctp" || entryType === "mctp" ? JSON.stringify({ confidence_mode }) : null;
    return fetchApiEndpoint<SLCEntry>(
      endpoints[entryType],
      {
        method: "POST",
        body,
      },
      { applyGameErrorMessages: true },
    );
  }

  // The 4 Generics here are: Successful Response Type, Error Type, Variables Type, and Context Type (returned from onMutate)
  return useMutation<SLCEntry, Response | Error, BundleCreateEntryArgs, BundleContext>({
    mutationFn: createEntry,
    onMutate: () => {
      const prevSession = queryClient.getQueryData<Bundle>(["bundle"]);
      return { prevSession };
    },
    onSuccess: (response, variables) => {
      handleBackendError(response, variables.entryType);

      queryClient.setQueryData(["bundle"], (prevBundle: Bundle) => {
        const newBundle = prevBundle ? structuredClone(prevBundle) : null;

        if (!newBundle) {
          return;
        }

        // 👋 If there is ever a new game don't forget to update the bundle here!
        if (newBundle && variables.entryType === "wbcg") {
          newBundle.wbcg_entries = [...(newBundle?.wbcg_entries || [])];
        } else if (newBundle && variables.entryType === "bcg") {
          newBundle.mbcg_entries = [...(newBundle?.mbcg_entries || [])];
        } else if (newBundle && variables.entryType === "mctp") {
          newBundle.mctp_entries = [...(newBundle?.mctp_entries || [])];
        } else if (newBundle && variables.entryType === "wctp") {
          newBundle.wctp_entries = [...(newBundle?.wctp_entries || [])];
        } else {
          newBundle.mslc_entries = [...(newBundle?.mslc_entries || []), response];
        }
        return newBundle;
      });
      dispatch((state) => {
        state.modal.openModal = null;
      });
    },
    onError: (error, vars, context) => {
      queryClient.setQueryData(["bundle"], context.prevSession);
    },
  });
}

interface BundleContext {
  prevSession: Bundle;
}
