import styled from "styled-components";

const PixelContainer = styled.div`
  position: absolute;
  margin: -1px;
  width: 0px;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
`;

export default function AdScript() {
  return (
    <>
      <PixelContainer id="px-container" />
    </>
  );
}
