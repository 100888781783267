import { useQuery } from "@tanstack/react-query";
import useApiFetch, { dynamicSlcApiEndpoint } from "./useApiFetch";
import { CTPEntry, SLCEntry } from "./useBundle";
import useGlobalState from "./useGlobalState";
import { GroupData } from "./useGroups";

/**
 * Hook to access a users session
 */
export default function useSession() {
  const [{ apiAuthToken }] = useGlobalState();
  const { fetchApiEndpoint } = useApiFetch();
  const MINUTES = 60 * 1000;

  return useQuery({
    queryKey: ["session"],
    queryFn: () => fetchApiEndpoint<Session>(`${dynamicSlcApiEndpoint}/session.json`),
    enabled: Boolean(apiAuthToken),
    refetchOnWindowFocus: false,
    refetchInterval: 2 * MINUTES,

    initialData: {
      entries: [],
      mctp_entries: [],
      wctp_entries: [],
      user: null,
    },
  });
}

export interface Session {
  entries: SLCEntry[];
  mctp_entries: CTPEntry[];
  wctp_entries: CTPEntry[];
  rollover_groups?: {
    ncaamsalarycap: GroupData[];
  };
  user: {
    bcgId: number;
    brand: string;
    forceLogout: boolean;
    id: string;
    mbcgPlayerStatus: number;
    wbcgPlayerStatus: number;
    mslcPlayerStatus: number;
    mctpPlayerStatus: number;
    wctpPlayerStatus: number;
    name: string;
    staticEndpointKey: string;
    urbanAirshipId: string;
  };
}

export const getEntryWithCustomFlags = (session: Session) => {
  const entryWithFlags = session?.entries?.find((e) => Boolean(e.flags));
  const defaultEntryFallback = session?.entries?.find((e) => e.is_default);
  const firstEntryFallback = session?.entries?.[0];
  return entryWithFlags || defaultEntryFallback || firstEntryFallback;
};
