import useGlobalState from "./useGlobalState";
import useJavaScriptBridge from "./useJavaScriptBridge";

const useClipBoard = () => {
  const [{ isNative }] = useGlobalState();
  const { fireAction } = useJavaScriptBridge();

  const copyToClipboard = (str: string, setCopied) => {
    // 🚨 navigator.clipboard is only available for sites with HTTPS, so this may not work within dev environment.
    if (navigator?.clipboard?.writeText && !isNative)
      return navigator.clipboard.writeText(str).then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      });
    if (isNative) {
      fireAction({
        action: "init_clipboard",
        clipboardUrl: str,
      }).then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      });
    }
    console.warn("Warning: The Clipboard API is not available & requires an https connection.");
  };

  return { copyToClipboard };
};

export default useClipBoard;
