import { getGameTeamImage, Team } from "@hooks/useGame";
import styled, { useTheme } from "styled-components";

interface TeamBubbleProps {
  team?: Team;
}

const TeamBubble = styled.div<TeamBubbleProps>`
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center !important;
  height: 47px;
  width: 47px;
  background: ${(props) => props.color};
  border-radius: 100px;
`;

function TeamBubbleCongrats({ team }: TeamBubbleProps) {
  const theme = useTheme();
  const color = team?.color || "transparent";
  const school = team?.school || "";
  const teamLink = team?.link || "";

  return (
    <TeamBubble color={color}>
      {team?.isFirstFour || !team ? (
        <img
          src={`/assets/images/teams/tbd-bg${theme.mode === "light" ? "l" : "d"}.svg`}
          width="47"
          height="47"
          alt="TBD"
        />
      ) : (
        <img src={getGameTeamImage(teamLink, "bgd")} width="30" height="30" alt={school} />
      )}
    </TeamBubble>
  );
}

export default TeamBubbleCongrats;
