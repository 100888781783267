"use client";
import useCookie, { bcgNativeDeviceType } from "@hooks/useCookie";
import { Team } from "@hooks/useGame";
import { createContext, useCallback, useContext, useEffect } from "react";
import { DaltonProfileValues, TAuthSteps, TDaltonIdentityTypes } from "src/types/auth";
import { ModalState } from "src/types/global-state";
import { Updater, useImmer } from "use-immer";

const EMPTY_PICKS = [
  {
    slot_id: null,
    bankPercentage: "0",
  },
  {
    slot_id: null,
    bankPercentage: "0",
  },
  {
    slot_id: null,
    bankPercentage: "0",
  },
  {
    slot_id: null,
    bankPercentage: "0",
  },
  {
    slot_id: null,
    bankPercentage: "0",
  },
];

export type SelectedPick = {
  slot_id: number;
  bankPercentage: string;
};

type GlobalState = {
  apiAuthToken: string;
  currentAuthScreen: TAuthSteps;
  magicLinkCompleted: boolean;
  dalton: {
    wipEmail: string;
    token: string;
    profile: DaltonProfileValues;
    identityType: TDaltonIdentityTypes;
    credential?: string;
    isSocialReg?: boolean;
  };
  onetrust: {
    consentLabel: string;
    consentAction: () => void;
  };
  isLoading: boolean;
  isNative: boolean;
  nativeDeviceType: string;
  isIframe: boolean;
  modal: ModalState;
  staticEndpointKey: string;
  lineup: {
    activeEntryId: number;
  };
  marketplace: {
    selectedPicks: SelectedPick[];
    originalBankBalance: string;
    bankBalance: string;
    sortOrder: string;
    errorMessage: string;
    isMyPicksOpen: boolean;
    tradePreview: {
      selectedPicks: SelectedPick[];
      teamsToBuy: SelectedPick[];
      teamsToSell: Team[];
      buyTotalPoints: number;
      sellTotalPoints: number;
      errorMessage: string;
    };
  };
};

export const initialState: GlobalState = {
  apiAuthToken: null,
  currentAuthScreen: "AUTH_LANDING",
  magicLinkCompleted: false,
  dalton: {
    wipEmail: null,
    token: null,
    profile: null,
    identityType: null,
    credential: null,
    isSocialReg: null,
  },
  onetrust: {
    consentLabel: "Do not sell or share my information",
    consentAction: Function.prototype(),
  },
  isLoading: false,
  isNative: false,
  nativeDeviceType: null,
  isIframe: false,
  modal: { openModal: null, options: {} },
  staticEndpointKey: null,
  lineup: {
    activeEntryId: null,
  },
  marketplace: {
    bankBalance: "0",
    originalBankBalance: "0",
    selectedPicks: EMPTY_PICKS,
    sortOrder: "seed-high-low",
    errorMessage: "",
    isMyPicksOpen: false,
    tradePreview: {
      selectedPicks: EMPTY_PICKS,
      teamsToBuy: [],
      teamsToSell: [],
      buyTotalPoints: 0,
      sellTotalPoints: 0,
      errorMessage: "",
    },
  },
};

const GlobalStateContext = createContext(initialState);
const GlobalStateDispatchContext = createContext<Updater<GlobalState>>(undefined);

// Add displayName values only to have clearer component names when using the react devtools.
GlobalStateContext.displayName = "GlobalStateContext";
GlobalStateDispatchContext.displayName = "GlobalStateDispatchContext";

/**
 * Global application state Provider components.
 */
export function GlobalStateProvider({ children, ...appProps }) {
  const { getCookie } = useCookie();
  const apiAuthToken = appProps.authToken || getCookie("apiAuthTokenCookieName") || "";
  const daltonToken = appProps.daltonToken || getCookie("daltonTokenCookieName") || "";
  const staticEndpointKey = appProps.staticEndpointKey || getCookie("staticEndpointKeyCookieName") || "";
  const nativeDeviceType = appProps.nativeDeviceType;

  const isNative = Boolean(appProps.isNative || getCookie(bcgNativeDeviceType as any));
  const isIframe = Boolean(appProps.isIframe || (typeof window !== "undefined" && window.isIframe));

  // On mount adjust initialState based on initial appProps. appProps are props delivered from Server-Side logic to avoid data flashes client-side. These values are set on init from the _app component.
  const initialStateWithProps = structuredClone(initialState);
  initialStateWithProps.isNative = isNative;
  initialStateWithProps.nativeDeviceType = nativeDeviceType;
  initialStateWithProps.isIframe = isIframe;
  initialStateWithProps.apiAuthToken = apiAuthToken;
  initialStateWithProps.dalton.token = daltonToken;
  initialStateWithProps.staticEndpointKey = staticEndpointKey;

  // We use Immer in a useState hook fashion to make deep immutable state changes using its update function to update state with mutable patterns (the update Fn is named "dispatch" below).
  // Reference: https://immerjs.github.io/immer/example-setstate | https://immerjs.github.io/immer/typescript
  const [state, dispatch] = useImmer<GlobalState>(initialStateWithProps);

  useEffect(() => {
    // if we init with native/iframe app flags, set sessionStorage values to persist through SSR.
    if (isNative) {
      localStorage.setItem("isNative", "t");
    }
    if (isIframe) {
      window.isIframe = true;
    }
  }, [isNative, isIframe]);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalStateDispatchContext.Provider value={dispatch}>{children}</GlobalStateDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}

/**
 * Anscillary internal hook to call useContext, retrieve the context, and avoid extra useContext() calls throughout the app.
 */
function useGlobalStateContext() {
  const context = useContext(GlobalStateContext);
  return context;
}

/**
 * Anscillary internal hook to call useContext, retrieve the context, and avoid extra useContext() calls throughout the app.
 * Also includes a safegaurd to throw an error if called above the Provider in the component tree.
 */
function useGlobalStateDispatch() {
  const context = useContext(GlobalStateDispatchContext);
  if (!context) {
    throw new Error(
      `useGlobalStateDispatch must be used within a GlobalStateDispatchContext Provider. context: ${context}`,
    );
  }
  return context;
}

/**
 * The main hook to useContext within components to access global application state, and to dispatch state changes.
 * @example const [state, dispatch] = useGlobalState();
 */
export default function useGlobalState(): [GlobalState, Updater<GlobalState>] {
  return [useGlobalStateContext(), useGlobalStateDispatch()];
}

// ################
//  Helper Methods
// ################

/**
 * Tiny hook to dispatch auth views from anywhere without needing to useGlobalState.
 * @example
 * const { setAuthView } = useAuthView()
 * setAuthView('SIGN_IN')
 */
export function useAuthView() {
  const [, dispatch] = useGlobalState();

  return {
    setAuthView: useCallback(
      (screen: TAuthSteps) =>
        dispatch((newState) => {
          newState.currentAuthScreen = screen;
        }),
      [dispatch],
    ),
  };
}

declare global {
  interface Window {
    isIframe?: boolean;
  }
}
