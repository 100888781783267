"use client";
import useGlobalState from "@hooks/useGlobalState";
import styled from "styled-components";
import { breakpoints, colors, media, pxToRem } from "@styles/styleUtils";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import { MMLButton } from "@sportstech/f2p-ncaa-component-library";

const MMLModalPaddingWrapper = styled.div`
  position: relative;
  // Helps overwrite modal styles without passing css prop
  .modal-content-wrapper {
    padding-bottom: 24px !important;
  }
`;

const Title = styled.p`
  letter-spacing: -0.3px;
  font-weight: 900;
  font-style: normal;
  font-size: ${pxToRem(16)};
  color: ${colors["lvl_-5"]};
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  max-width: 207px;
  margin: 0 auto;
  margin-bottom: 5px;
`;

const Description = styled.p`
  font-size: ${pxToRem(16)};
  line-height: 1.38;
  color: ${colors["lvl_-2"]};
  text-align: center;
  max-width: 300px;
  margin: 0 auto;

  ${media.svp} {
    font-size: ${pxToRem(12)};
    letter-spacing: 0.4px;
    line-height: 1.33;
    width: 237px;
  }
`;

const StyledMMLButton = styled(MMLButton)`
  margin-top: 16px;
  width: 100%;
`;

type NotLoggedInModalProps = {
  isLoggedIn?: boolean;
  handleLoginRedirect: () => void;
};

const NotLoggedInModal = ({ handleLoginRedirect }: NotLoggedInModalProps) => {
  const [state, dispatch] = useGlobalState();
  const isMobileViewport = typeof window !== "undefined" && matchMedia(`(max-width: ${breakpoints.svp})`).matches;
  const modalWidth = isMobileViewport ? 303 : 388;

  const closeModal = () => {
    dispatch((state) => {
      state.modal.openModal = null;
    });
  };

  return (
    <MMLModalPaddingWrapper>
      <MMLModal
        handleClose={() => {
          handleLoginRedirect();
          closeModal();
        }}
        isOpen={state.modal.openModal === "LINEUPS_UNAUTHORIZED"}
        showCloseIcon={true}
        maxWidth={modalWidth}
      >
        <Title>SIGN IN TO VIEW</Title>
        <Description>Sign in or create an account to view this entry and make your picks</Description>

        <StyledMMLButton size="sm" $primary onClick={handleLoginRedirect}>
          Sign in
        </StyledMMLButton>
      </MMLModal>
    </MMLModalPaddingWrapper>
  );
};

export default NotLoggedInModal;
