import useGlobalState from "@hooks/useGlobalState";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import { colors } from "@styles/styleUtils";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import FaceBookIcon from "@icons/Social-FB-Stroke.svg";
import XIcon from "@icons/Social-X-Stroke.svg";
import PrintIcon from "@icons/UI-Print-Stroke.svg";
import LinkIcon from "@icons/Play-Link-Stroke.svg";
import { gameUrls } from "src/lib/url-helpers";
import settings from "src/settings";
import useAnalytics from "@hooks/useAnalytics";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import useClipBoard from "@hooks/useClipboard";
import useApiFetch from "@hooks/useApiFetch";
import useJavaScriptBridge from "@hooks/useJavaScriptBridge";
const { APP_ENV } = settings;

const SubHeader = styled.h4`
  font-size: 12px;
  font-weight: 325;
  letter-spacing: 0.4px;
  line-height: 1.33;
  color: ${colors["lvl_-2"]};
  text-align: center;
  min-width: 303px;
`;

const ShareCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
`;

const ShareCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 267px;
  height: 72px;
  border: 1px solid ${colors.lvl_2};
  border-radius: 4px;
  margin: 8px 0;
  cursor: pointer;

  p {
    font-size: 12px;
    margin-top: 5px;
  }
  &:last-child {
    margin-bottom: 24px;
  }
  .vertical-align {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const ShareImageButton = styled.a`
  height: 100%;
  text-decoration: none;
`;

const ShareMobileButton = styled.button`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type ShareLineupProps = {
  entryId: number;
};

function objectToGetParams(object: { [key: string]: string | number | undefined | null }) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join("&")}` : "";
}

export default function ShareLineup({ entryId }: ShareLineupProps) {
  const [state, dispatch] = useGlobalState();
  const [copied, setCopied] = useState<boolean>(false);
  const { copyToClipboard } = useClipBoard();
  const { fireAction } = useJavaScriptBridge();
  const shareUrl = `${gameUrls[APP_ENV]}/mens-tournament-run/lineup/${entryId}`;
  const shareImageUrl = `${gameUrls[APP_ENV]}/api/v1/ncaamsalarycap/entries/${entryId}/share-image.json`;
  const [shareImage, setShareImage] = useState<string>("");
  const { fetchApiEndpoint } = useApiFetch();
  const { pushEventData } = useAnalytics();

  function handleAnalytics(target) {
    pushEventData({
      eventName: "share_bracket",
      eventAction: "bracket_share",
      eventType: "bcg",
      eventTarget: target,
    });
  }

  // Handle sharing for mobile device
  const handleNativeShare = (platform: string) => {
    let url = "";
    switch (platform) {
      case "facebook":
        url = "https://m.facebook.com/sharer.php" + objectToGetParams({ u: shareUrl });
        break;
      case "x":
        url = "https://twitter.com/intent/tweet" + objectToGetParams({ url: shareUrl });
        break;
      default:
        url = "";
        break;
    }
    if (url) {
      handleAnalytics(platform);

      if (state.nativeDeviceType.includes("android")) {
        fireAction({
          action: "open_external_url",
          extLinkUrl: url,
        });
      } else {
        if (typeof window !== "undefined") {
          // protect SSR
          if (window.navigator.share) {
            try {
              window.navigator.share({
                title: "NCAA® March Madness® Tournament Run",
                text: "View my lineup in the official game of NCAA® March Madness®",
                url: shareUrl,
              });
            } catch (e) {
              console.error("Share API failed");
            }
          }
        }
      }
    }
  };

  // Generate Share Lineup Image
  useEffect(() => {
    fetchApiEndpoint(shareImageUrl).then((data: { image: string }) => {
      setShareImage(data.image);
    });
  }, [fetchApiEndpoint, shareImageUrl]);

  return (
    <MMLModal
      isOpen={state.modal.openModal === "SHARE_LINEUP"}
      title="Share your lineup"
      showCloseIcon={true}
      removeContentPadding={true}
      handleClose={() =>
        dispatch((state) => {
          state.modal.openModal = null;
        })
      }
      maxWidth={303}
    >
      <SubHeader>Share your picks from your lineup</SubHeader>
      <ShareCardContainer>
        <ShareCard>
          {state.isNative ? (
            <ShareMobileButton onClick={() => handleNativeShare("facebook")}>
              <FaceBookIcon />
              <p>Share via Facebook</p>
            </ShareMobileButton>
          ) : (
            <FacebookShareButton onClick={() => handleAnalytics("facebook")} className="vertical-align" url={shareUrl}>
              <FaceBookIcon />
              <p>Share via Facebook</p>
            </FacebookShareButton>
          )}
        </ShareCard>
        <ShareCard>
          {state.isNative ? (
            <ShareMobileButton onClick={() => handleNativeShare("x")}>
              <XIcon /> <p>Share via X</p>
            </ShareMobileButton>
          ) : (
            <TwitterShareButton
              onClick={() => handleAnalytics("x")}
              className="vertical-align"
              url={shareUrl}
              title={`March Madness Men's Tournament Run`}
            >
              <XIcon /> <p>Share via X</p>
            </TwitterShareButton>
          )}
        </ShareCard>
        {!state.isNative ? (
          <ShareCard>
            <ShareImageButton
              onClick={() => handleAnalytics("print")}
              className="vertical-align"
              rel="noreferrer noopener"
              target="_blank"
              href={shareImage}
            >
              <PrintIcon /> <p>Print Lineup</p>
            </ShareImageButton>
          </ShareCard>
        ) : null}
        <ShareCard
          onClick={() => {
            handleAnalytics("copy_link");
            return copyToClipboard(shareUrl, setCopied);
          }}
        >
          <LinkIcon /> <p>{copied ? "Link Copied to Clipboard!" : "Copy Link"}</p>
        </ShareCard>
      </ShareCardContainer>
    </MMLModal>
  );
}
