import useGlobalState from "@hooks/useGlobalState";
import LineupWarningIcon from "@icons/LineupIncomplete.svg";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import { Button } from "@styles/buttons.styled";
import { pxToRem } from "@styles/styleUtils";
import styled from "styled-components";

const MMLModalWrapper = styled.div`
  *.modal-content-wrapper {
    padding: 16px !important;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: ${pxToRem(24)};
    margin-bottom: 8px;
  }

  svg {
    margin: 22px 0;
  }
`;

const Description = styled.span`
  font-size: ${pxToRem(14)};
  text-align: center;

  span {
    line-height: 1.5;
    display: block;

    &:last-child {
      margin-bottom: 20px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;

  & button {
    width: 100%;
  }
`;

export default function LineupIncomplete() {
  const [state, dispatch] = useGlobalState();

  return (
    <MMLModalWrapper>
      <MMLModal maxWidth={303} isOpen={state.modal.openModal === "LINEUP_INCOMPLETE"}>
        <Title>
          <LineupWarningIcon />
          <h2>LINEUP INCOMPLETE</h2>
        </Title>
        <Description>
          <span>Time is running out! Lineups lock</span>
          <span>today at 12 PM ET. Get your picks in</span>
          <span>before time runs out.</span>
        </Description>
        <ButtonContainer>
          <Button
            size="md"
            onClick={() =>
              dispatch((state) => {
                state.modal.openModal = null;
              })
            }
            $primary
          >
            Return To Marketplace
          </Button>
          {/*
           * Currently this modal isn't being used
           * If it ever gets used again be sure to update the onClick here
           */}
          <Button size="md" onClick={() => Function.prototype()} $secondary>
            Exit Anyway
          </Button>
        </ButtonContainer>
      </MMLModal>
    </MMLModalWrapper>
  );
}
