import { useCallback, useEffect } from "react";
import { debug } from "src/pages/debug";
import settings from "src/settings";
import useGlobalState from "./useGlobalState";
import useCookie from "./useCookie";
const {
  APP_ENV,
  ENV_NAMES: { PROD, DEV },
} = settings;

const loggingEnabled = true;

/**
 * Hook to utilize window messages to parent Android app, iOS app, and browser window environments respectively.
 * @example const { fireAction } = useJavaScriptBridge();
 */
const useJavaScriptBridge = () => {
  const [{ isNative, isIframe, apiAuthToken, staticEndpointKey, dalton }] = useGlobalState();
  const { getCookie } = useCookie();

  useEffect(() => {
    if (typeof window !== "undefined" && !(window.webkit || window.Android) && APP_ENV === DEV) {
      window.Android = {
        action: () => Function.prototype(),
      };
    }
  }, []);

  useEffect(() => {
    // Passes "navigate" event to the native app if any external link is click
    // Then redirect is handled in the native app
    if (isNative && window.parent) {
      document.addEventListener(
        "click",
        function (event) {
          try {
            const target = event.target as HTMLElement;
            const externalHref = (target as HTMLAnchorElement).href;
            if (externalHref) {
              const externalURL = new URL(externalHref);
              const currentURL = new URL(window.location.href);

              if (target.tagName === "A" && externalURL.hostname !== currentURL.hostname) {
                // Deeplink if its the ncaa site
                if (externalHref.includes("ncaa.com")) {
                  event.preventDefault();
                  // Native URLs are different than web... need to translate
                  const urlTranslations = {
                    ["/contact-us"]: "/faq",
                    ["/privacy"]: "/privacy",
                    ["/privacy#adchoices"]: "/adchoices",
                    ["/tos"]: "/terms",
                  };

                  const nativeSettingsDeeplink = "ncaamml://settings";
                  const deeplinkUrl = `${nativeSettingsDeeplink}${
                    urlTranslations[externalURL.pathname + externalURL.hash]
                  }`;

                  fireAction({
                    action: "navigate",
                    deeplinkUrl,
                    returnUrl: window.location.href,
                  });
                }
              }
            }
          } catch (e) {
            console.error(e);
            return;
          }
        },
        false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fireAction = useCallback(
    (payload: BridgePayload) => {
      /**
       * Converts payload to a JSON string and optionally adds user values.
       * In concert with back-end processing we set the bcgAuthorizationToken in the following format: apiAuthToken + "$" + daltontoken
       */

      function payloadWithAuthValues(payload: BridgePayload) {
        const bcgAuthorizationToken = `${apiAuthToken}${dalton.token ? `$${dalton.token}` : ""}`;
        if (payload?.returnUrl) {
          if (apiAuthToken) payload.bcgAuthorizationToken = bcgAuthorizationToken;
          payload.staticEndpointKey = getCookie("staticEndpointKeyCookieName");
        }
        return JSON.stringify(payload);
      }

      return new Promise<void>((resolve) => {
        const jsonPayload = payloadWithAuthValues(payload);

        function logAction(success = true) {
          if (loggingEnabled && APP_ENV !== PROD) {
            if (success) {
              console.groupCollapsed("📡 Firing Bridge action");
              debug(JSON.stringify(JSON.parse(jsonPayload), null, 2));
              debug("window.Android:", window.Android);
              debug("window.webkit:", window.webkit);
              console.groupEnd();
            } else {
              console.warn(`Warning: no bridge handler found.`);
              console.groupCollapsed("📡 Bridge action status:");
              debug("window.Android:", window.Android);
              debug("window.webkit:", window.webkit);
              debug("isNative:", isNative);
              debug(`payload: ${JSON.stringify(JSON.parse(jsonPayload), null, 2)}`);
              console.groupEnd();
            }
          }
        }

        if (window.Android?.action && isNative) {
          logAction();
          window.Android.action(jsonPayload);
        } else if (window.webkit?.messageHandlers?.action?.postMessage && isNative) {
          logAction();
          window.webkit.messageHandlers.action.postMessage(jsonPayload);
        } else if (isIframe && window.parent && new RegExp("ncaa.com").test(document.referrer || "")) {
          logAction();
          window.postMessage(jsonPayload);
        } else {
          logAction(null);
        }
        resolve();
      });
    },
    [apiAuthToken, staticEndpointKey, dalton.token, isNative, isIframe],
  );

  return { fireAction };
};

export default useJavaScriptBridge;

interface BridgePayload {
  [key: string]: any;
  bcgAuthorizationToken?: string;
  staticEndpointKey?: string;
  returnUrl?: string;
}

declare global {
  interface Window {
    Android?: any;
    webkit?: any;
  }
}
