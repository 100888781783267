import * as Collapsible from "@radix-ui/react-collapsible";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled, { keyframes } from "styled-components";
import { colors, media, pxToRem } from "../styleUtils";

const slideDown = keyframes`
  from { transform: translateY(calc(-50% - 1px)) };
  to { transform: translateY(0) };
`;

const slideUp = keyframes`
  from { transform: translateY(0) };
  to { transform: translateY(calc(-50% - 1px)) };
`;

export const GroupsContainer = styled.div`
  padding: ${pxToRem(43)} ${pxToRem(86)};
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;

  ${media.svp} {
    padding: ${pxToRem(43)} 0;
  }

  .group-create-button-container {
    margin-left: ${pxToRem(16)};
    margin-right: ${pxToRem(16)};
  }

  .group-create-button {
    width: ${pxToRem(302.5)};
    margin: 0 auto;
    margin-bottom: ${pxToRem(24)};
    font-style: oblique;
    font-weight: 350;
    letter-spacing: 1.2px;
    font-size: ${pxToRem(12)};

    ${media.singleColumn} {
      width: ${pxToRem(305)};
    }

    ${media.svp} {
      width: 100%;
      height: ${pxToRem(32)};
      font-size: ${pxToRem(10)};
      margin-bottom: ${pxToRem(16)};
    }
  }
`;

export const GroupsSection = styled.div`
  margin-bottom: ${pxToRem(21)};

  ${media.singleColumn} {
    margin-bottom: ${pxToRem(18)};
  }
  ${media.svp} {
    margin-bottom: ${pxToRem(27)};
  }

  .no-groups {
    font-size: ${pxToRem(14)};
    line-height: 1.2;
    font-style: normal;
    font-weight: 325;
    color: ${colors["lvl_-5"]};

    ${media.singleColumn} {
      font-size: ${pxToRem(12)};
      line-height: 16px;
      letter-spacing: 0.4px;
    }

    ${media.svp} {
      padding-left: ${pxToRem(16)};
    }
  }
`;

export const GroupsSectionLabel = styled.h3<{ hasGroups?: boolean }>`
  font-size: ${pxToRem(10)};
  letter-spacing: 1px;
  color: ${colors["lvl_-5"]};
  font-weight: 350;
  font-style: oblique;
  text-transform: uppercase;
  padding-bottom: ${pxToRem(8)};

  ${media.svp} {
    padding-left: 16px;
  }
`;

export const GroupRow = styled.div`
  background-color: transparent;
  width: 100%;
  border-bottom: 1px solid ${colors.lvl_2};
  padding: 12px 8px;
  height: 56px;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.singleColumn} {
    height: 52px;
    padding: 12px 4px;
  }

  a {
    text-decoration: none;
  }

  .join-group-btn {
    background: transparent;
    border: none;
    transition: 0.2s ease-in-out;
    :hover {
      transform: scale(1.2);
    }
    ${media.svp} {
      margin-right: 20px;
    }
  }

  :hover {
    background-color: ${colors.lvl_4};

    .group-name {
      transform: translate(10px);
    }
  }
`;

export const CollapsibleContent = styled(Collapsible.Content)`
  overflow: hidden;
  &[data-state="open"] {
    .collapsible-content-container {
      animation: ${slideDown} 0.2s ease-in-out;
    }
  }
  &[data-state="closed"] {
    .collapsible-content-container {
      animation: ${slideUp} 0.2s ease-in-out forwards;
    }
  }
`;

export const CollapsibleTrigger = styled(Collapsible.Trigger)`
  color: ${colors.bcg_accent_1};
  font-size: 10px;
  line-height: 2;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.2s ease-out;
  padding: 5px;
  width: 75px;
  border-radius: 3px;
  :hover {
    background-color: ${colors.lvl_4};
  }
`;

export const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.svp} {
    padding-left: ${pxToRem(16)};
    padding-right: ${pxToRem(16)};
  }
`;

export const StyledDropDownTrigger = styled(DropdownMenu.Trigger)`
  background: ${colors.lvl_5};
  width: 100%;
  max-width: 192px;
  border-radius: 3px;
  margin-left: 1rem;
  border: 1px solid ${colors["lvl_2"]};
  display: flex;
  align-items: center;
  position: relative;
  ${media.svp} {
    max-width: 156px;
  }
  ${media.singleColumn} {
    max-width: 113px;
  }

  .dropDownText {
    font-size: 12px;
    margin-left: 12px;
  }

  .carrot {
    width: 12px;
    position: absolute;
    right: 8px;
    transition: transform 0.2s ease-in-out;
  }

  &[data-state="open"],
  &:hover,
  &:focus-visible {
    outline: 1px solid ${colors["lvl_-5"]};
  }

  &[data-state="open"],
  &:focus-visible {
    .carrot {
      transform: rotate(180deg);
    }
  }
`;

export const StyledDropDownContent = styled(DropdownMenu.Content)`
  background: ${colors.lvl_5};
  border: 1px solid ${colors["lvl_2"]};
  border-radius: 3px;
  width: 192px;
  margin-top: 5px;

  ${media.svp} {
    width: 156px;
  }
  ${media.singleColumn} {
    max-width: 113px;
  }
`;

export const StyledDropDownItem = styled(DropdownMenu.Item)`
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  :not(:last-child) {
    border-bottom: 0.5px solid ${colors["lvl_-5"]};
  }

  &:last-child {
    border-radius: 0 0 3px 3px;
  }

  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  :hover {
    background-color: ${colors.lvl_4};
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid ${colors["lvl_-5"]};
  }
`;

export const ListLoading = styled.div`
  font-size: ${pxToRem(12)};
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: ${colors.bcg_accent_1};
  margin-top: 30px;
`;

export const GroupHeaderSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 74px;
  align-items: center;
  position: relative;

  .back-container {
    position: absolute;
    cursor: pointer;
    left: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 25px;
    width: 25px;

    .back-carrot {
      transform: rotate(180deg);
    }
  }
`;

export const AdvancedViewSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 25px;
  height: 71px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  border-top: 1px solid ${colors.lvl_2};
  border-bottom: 1px solid ${colors.lvl_2};
  label {
    font-size: ${pxToRem(12)};
    letter-spacing: 0.4px;
    margin-right: 10px;
  }
  ${media.svp} {
    padding-left: 25px;
    justify-content: space-between;
  }
`;

export const GroupDetailsLayout = styled.div`
  max-width: 939px;
  width: 100%;
  margin: 0 auto;
`;

export const GroupInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;

  ${media.mvp} {
    margin: 40px 25px;
  }

  ${media.svp} {
    flex-flow: column;
  }

  button {
    background-color: ${colors.slc_accent};
    color: white;
    width: 175px;
    transition: background-color 0.2s ease-in-out;

    ${media.svp} {
      width: 72px;
    }
    :hover {
      background-color: ${colors.slc_accent} !important;
      opacity: 0.8 !important;
    }
  }
`;

export const GroupInfo = styled.div<{ joinedGroup?: boolean; teamColor?: string }>`
  display: flex;
  width: 100%;
  flex-flow: ${({ joinedGroup }) => (joinedGroup ? "column" : "row")};
  justify-content: space-between;

  ${media.svp} {
    border-bottom: 1px solid ${({ joinedGroup }) => (joinedGroup ? colors.lvl_2 : "transparent")};
    padding-bottom: 15px;
  }
  .group-verified {
    position: relative;
    .logo-bg {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ teamColor }) => teamColor};
      img {
        width: 35px;
        height: 35px;
      }
      .verified {
        position: absolute;
        width: 22px;
        right: -7px;
        bottom: -4px;
      }
    }
  }
  .info-flex-box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-left: 10px;
    ${media.svp} {
      margin-left: 19px;
      margin-top: 13px;
    }
  }
  .group-name {
    align-items: center;
    display: flex;
    color: ${colors["lvl_-5"]};
    font-weight: normal;
    font-size: 14px;
    font-stretch: condensed;
    transition: 0.2s ease-in-out;
    line-height: 16.87px;

    ${media.singleColumn} {
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
    }
    ${media.svp} {
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
    }

    .verified {
      margin-left: 3px;
      width: ${pxToRem(18)};
    }
  }
  .group-members {
    font-size: 12px;
    color: ${colors["lvl_-1"]};
    line-height: 14.46px;
    ${media.singleColumn} {
      font-size: 10px;
      line-height: 12.05px;
    }
    ${media.svp} {
      font-size: 10px;
      line-height: 12.05px;
    }
  }
`;

export const JumpToMyRankingContainer = styled.div`
  position: sticky;
  top: 94.5vh;
  width: 100%;

  button {
    margin: 0 auto;
    width: 345px;
    margin-bottom: 12px;
  }
`;
