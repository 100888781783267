import { useQuery } from "@tanstack/react-query";
import { colors } from "src/styles/styleUtils";
import useApiFetch, { staticSlcApiEndpoint } from "./useApiFetch";
import settings from "src/settings";

export const MAX_INITIAL_TEAMS_COUNT = 5;
export const MAX_LINEUPS_PER_USER = 10;

const roundDates = {
  2: {
    marketplaceOpens: "3/13 19:00",
    marketplaceCloses: "3/17 12:00",
  },
  3: {
    marketplaceOpens: "3/19 01:00",
    marketplaceCloses: "3/19 12:10",
  },
  4: {
    marketplaceOpens: "3/21 01:00",
    marketplaceCloses: "3/24 19:00",
  },
  5: {
    marketplaceOpens: "3/26 01:00",
    marketplaceCloses: "3/26 18:09",
  },
  6: {
    marketplaceOpens: "3/28 01:00",
    marketplaceCloses: "4/02 18:00",
  },
  7: {
    marketplaceOpens: "4/03 01:00",
    marketplaceCloses: "4/04 21:00",
  },
};

export interface Team {
  abbrev: string;
  color: string;
  conference_display_name?: string;
  conference_name?: string;
  current_round_percent_owned?: string;
  isFirstFour?: boolean;
  isPlayInMatchup?: boolean;
  is_eliminated: boolean;
  link: string;
  nick: string;
  overall_seed: number;
  potentialPrice?: string;
  price?: string;
  school: string;
  seed: number;
  slot_id?: number;
  team_id: number;
}

export interface Pick {
  slot_id: number;
}

export interface Round {
  round_id: number;
  round_number: 2 | 3 | 4 | 5 | 6 | 7;
  picking_status: "pre_picks_open" | "picks_open" | "picks_locked";
  is_current: boolean;
}

export interface Price {
  starting_price: string;
  dividend: string;
  pickable: boolean;
  ending_price: string;
}

export interface PriceRecord {
  [teamId: string]: Price;
}

export interface State {
  current_round: Round;
  first_scoring_run: boolean;
  locked_teams: number[];
  can_view_others_picks: boolean;
  can_create_lineups: boolean;
  tournament_started: boolean;
}

export interface Matchup {
  matchup_id: number;
  next_matchup_id: number;
  next_matchup_team_pos: number;
  team_1_id: number;
  team_2_id: number;
  team_1_score: number;
  team_2_score: number;
  winner_id: number;
  /** 1: Pregame | 2: Pregame (about to start) | 3: In Progress | 4: Finished */
  state: 1 | 2 | 3 | 4;
  starts_at: string;
  clock: string;
  per: number;
  broadcaster: string;
}
export interface Game {
  teams: Team[];
  rounds: Round[];
  prices: {
    [key in Round["round_number"]]: PriceRecord;
  };
  state: State;
  matchups: Matchup[];
  potential_prices: {
    [key in "team_id"]: PriceRecord;
  };
  first_four_pairs: [number, number][];
}

/**
 * Hook that uses react-query to interface with the Game API store (for game.json)
 */
export default function useGame() {
  const { fetchApiEndpoint } = useApiFetch();

  return useQuery({
    queryKey: ["game", "mens-slc"],
    queryFn: () => fetchApiEndpoint<Game>(`${staticSlcApiEndpoint}/game.json`),
    refetchInterval: 30 * 1000,
    staleTime: 15 * 1000,

    // a difference greater than staleTime to invalidate initialData on mount.
    initialDataUpdatedAt: Date.now() - 60 * 1000,

    refetchOnWindowFocus: false,

    initialData: {
      teams: [],
      rounds: [],
      prices: null,
      potential_prices: null,
      matchups: [],
      state: {
        current_round: null,
        locked_teams: [],
        first_scoring_run: false,
        can_view_others_picks: false,
        can_create_lineups: false,
        tournament_started: false,
      },
      first_four_pairs: [],
    },
  });
}

export function getGameTeamImage(teamName: string, background: "bgd" | "bgl") {
  return `https://i.turner.ncaa.com/sites/default/files/cssu/mml/${settings.CURRENT_TOURNAMENT}/teams/${background}/${teamName}.png`;
}

export function useTeamPrice(): {
  getTeamPrice: (teamId: number, round?: number) => string;
  getPotentialTeamPrice: (teamId: number) => string;
  getLoyaltyTeamPrice: (teamId: number, round?: number) => string;
  getStartingTeamPrice: (teamId: number, round?: number) => string;
} {
  const { data: game } = useGame();
  const prices = game.prices;
  const potential_prices = game.potential_prices;
  const currentRoundId = game?.state?.current_round?.round_id ?? null;

  return {
    getTeamPrice: (teamId: number, round?: number) => prices?.[round || currentRoundId]?.[teamId]?.ending_price ?? null,
    getPotentialTeamPrice: (teamId: number) => potential_prices?.[teamId] ?? null,
    getLoyaltyTeamPrice: (teamId: number, round?: number) =>
      prices?.[round || currentRoundId]?.[teamId]?.dividend ?? null,
    getStartingTeamPrice: (teamId: number, round?: number) =>
      prices?.[round || currentRoundId]?.[teamId]?.starting_price ?? null,
  };
}

export const getSelectedPicksForCurrentRound = (currrentEntryPicks, currrentRound) => {
  return [...Array(5)].map((elem, index) => {
    const currentEntrySlot = currrentEntryPicks?.[currrentRound][index + 1];
    if (currentEntrySlot) {
      return {
        slot_id: currentEntrySlot,
        bankPercentage: "",
      };
    }
    return {
      slot_id: null,
      bankPercentage: "",
    };
  });
};

export const getMarketplaceGameStates = (game) => {
  const pickingStatus = game?.state?.current_round?.picking_status;
  const currentRoundId = game?.state?.current_round?.round_id;
  const lastRound = 7;

  const showTradeView = game.state?.tournament_started;

  const initialPickingWindow =
    currentRoundId <= 2 && (pickingStatus === "picks_open" || pickingStatus === "pre_picks_open");

  const marketPlaceIsClosed = pickingStatus === "picks_locked" || pickingStatus === "pre_picks_open";

  const gameIsOver = currentRoundId == lastRound && marketPlaceIsClosed;

  const hideMyPicks = (currentRoundId == lastRound && marketPlaceIsClosed) || marketPlaceIsClosed;

  return {
    showTradeView,
    initialPickingWindow,
    marketPlaceIsClosed,
    hideMyPicks,
    gameIsOver,
  };
};

export function useMatchupsForCurrentRound() {
  const { data: game } = useGame();
  const roundId = game?.state?.current_round?.round_id ?? null;
  let matchups = game?.matchups?.filter((matchup) =>
    matchup.matchup_id.toString().startsWith(roundId ? roundId.toString() : null),
  );
  if (roundId === 2) {
    const firstFour = game?.matchups?.filter((matchup) => matchup.matchup_id.toString().startsWith("1"));
    matchups = matchups.concat(firstFour);
  }
  return matchups;
}

export function getMatchupsForaGivenRound(matchups, roundId) {
  const filteredMatchups = matchups?.filter((matchup) => matchup.matchup_id.toString().startsWith(String(roundId)));
  return filteredMatchups;
}

export function getTournamentRounds(currentTournamentRound) {
  const tournamentRounds = [];
  for (let i = currentTournamentRound; i - 2 >= 0; i--) {
    tournamentRounds.push(i);
  }
  return tournamentRounds;
}

export function formatMatchDateHeader(currentMatchup) {
  return new Date(currentMatchup.starts_at).toLocaleDateString("en-us", {
    weekday: "long",
    day: "numeric",
    month: "long",
  });
}

export function formatMatchDate(currentMatchup) {
  return new Date(currentMatchup.starts_at).toLocaleDateString("en-us", {
    weekday: "short",
    day: "numeric",
    month: "short",
  });
}

export function useMyTournamentGames(myTeams: number[]) {
  const { data: game } = useGame();
  const roundId = game?.state?.current_round?.round_id ?? null;

  const tournamentRounds = getTournamentRounds(roundId);

  return tournamentRounds.reduce((acc, roundNumber) => {
    const matchups = getMatchupsForaGivenRound(game?.matchups, roundNumber);
    const teamMatchups = myTeams.reduce((acc, teamId) => {
      const currentMatchup = matchups.find((matchup) => matchup.team_1_id === teamId || matchup.team_2_id === teamId);

      if (currentMatchup) {
        const formattedMatchDate = formatMatchDateHeader(currentMatchup);

        const newMatchup = {
          ...currentMatchup,
          starts_at: formattedMatchDate,
        };

        const matchupWithSameDate = acc.find((matchup) => matchup.date === newMatchup.starts_at);
        const indexOfMatchup = acc.indexOf(matchupWithSameDate);

        if (matchupWithSameDate) {
          acc[indexOfMatchup] = {
            date: formattedMatchDate,
            games: [
              ...acc[indexOfMatchup].games,
              {
                team: teamId,
                matchup: {
                  ...newMatchup,
                  starts_at: formatMatchDate(currentMatchup),
                },
              },
            ],
          };

          return acc;
        } else {
          return acc.concat({
            date: formattedMatchDate,
            games: [
              {
                team: teamId,
                matchup: {
                  ...newMatchup,
                  starts_at: formatMatchDate(currentMatchup),
                },
              },
            ],
          });
        }
      }

      return acc;
    }, []);

    acc[roundNumber] = teamMatchups.length
      ? teamMatchups.sort((a, b) => Number(new Date(a.date)) - Number(new Date(b.date)))
      : teamMatchups;

    return acc;
  }, []);
}

export function useCurrentLineup(myTeams: number[]) {
  const { data: game } = useGame();
  const matchups = game?.matchups;

  const teamMatchups = myTeams.reduce((acc, teamId) => {
    const allMatchupsForTeam = matchups.filter(
      (matchup) => matchup.team_1_id === teamId || matchup.team_2_id === teamId,
    );
    const latestMatchup = allMatchupsForTeam.sort((a, b) => b.matchup_id - a.matchup_id)[0];

    if (latestMatchup) {
      const formattedMatchDate = formatMatchDateHeader(latestMatchup);

      const newMatchup = {
        ...latestMatchup,
        starts_at: formattedMatchDate,
      };

      const matchupWithSameDate = acc.find((matchup) => matchup.date === newMatchup.starts_at);
      const indexOfMatchup = acc.indexOf(matchupWithSameDate);

      if (matchupWithSameDate) {
        acc[indexOfMatchup] = {
          date: formattedMatchDate,
          games: [
            ...acc[indexOfMatchup].games,
            {
              team: teamId,
              matchup: {
                ...newMatchup,
                starts_at: formatMatchDate(latestMatchup),
              },
            },
          ],
        };

        return acc;
      } else {
        return acc.concat({
          date: formattedMatchDate,
          games: [
            {
              team: teamId,
              matchup: {
                ...newMatchup,
                starts_at: formatMatchDate(latestMatchup),
              },
            },
          ],
        });
      }
    }

    return acc;
  }, []);
  return teamMatchups.sort((a, b) => Number(new Date(a.date)) - Number(new Date(b.date)));
}

type StateTimes = {
  [status in Round["picking_status"]]: Date;
};

export function getDynamicMarketCountdown(currentRound: number): StateTimes {
  if (!currentRound) return null;

  const year = new Date().getFullYear();

  return {
    pre_picks_open: new Date(`${year}/${roundDates[currentRound].marketplaceOpens}`),
    picks_open: new Date(`${year}/${roundDates[currentRound].marketplaceCloses}`),
    picks_locked: new Date(`${year}/${roundDates[currentRound + 1]?.marketplaceOpens}`),
  };
}

export function isFirstFourPairs(teamId: number, teams: Team[], firstFourPairs: [number, number][]) {
  if (!teamId) return false;

  const pair = firstFourPairs.find((pair) => pair.includes(teamId));

  if (pair) {
    const pairedTeamsInfo = pair.map((id) => teams.find((team) => team.team_id === id));
    return {
      abbrev: `${pairedTeamsInfo[0].abbrev} / ${pairedTeamsInfo[1].abbrev}`,
      color: colors.lvl_3,
      is_eliminated: false,
      link: `${pairedTeamsInfo[0].link} / ${pairedTeamsInfo[1].link}`,
      nick: `${pairedTeamsInfo[0].nick} / ${pairedTeamsInfo[1].nick}`,
      // school is set to the abbrev because names may be too long
      school: `${pairedTeamsInfo[0].abbrev} / ${pairedTeamsInfo[1].abbrev}`,
      seed: pairedTeamsInfo[0].seed,
      overall_seed: pairedTeamsInfo[0].overall_seed,
      team_id: pairedTeamsInfo[0].team_id,
      isFirstFour: true,
    };
  } else return false;
}

export function getPendingMatchup(team1Id: number, team2Id: number, teams: Team[]) {
  const team1 = teams.find((team) => team.team_id === team1Id);
  const team2 = teams.find((team) => team.team_id === team2Id);

  if (!team1 || !team2 || !teams.length) return false;

  return {
    abbrev: `${team1.abbrev} / ${team2.abbrev}`,
    color: colors.lvl_3,
    is_eliminated: false,
    link: ``,
    nick: `${team1.nick} / ${team2.nick}`,
    school: `${team1.abbrev} / ${team2.abbrev}`,
    seed: team1.seed,
    overall_seed: team1.overall_seed,
    team_id: team1.team_id,
    isPlayInMatchup: true,
  };
}

export function useLiveMatchups() {
  const { data: game } = useGame();
  return game.matchups?.filter((matchup) => matchup.state === 3);
}

export function useAlmostLiveMatchups() {
  const { data: game } = useGame();
  const currentRoundId = game?.state?.current_round?.round_id ?? null;
  const almostLiveGames = game.matchups?.filter((matchup) => {
    const isMatchupCurrentRound = matchup.matchup_id.toString().startsWith(currentRoundId.toString());

    if (isMatchupCurrentRound) {
      return Date.parse(matchup.starts_at) - Date.now() < 90 * 1000;
    }
  });
  return almostLiveGames;
}

/**
 * Returns whether any games have started.
 * @returns `true` if March Madnessgames have started, `false` otherwise
 */
export function useGamesStarted() {
  const { data: game } = useGame();
  return game.state?.tournament_started;
}
