import useGlobalState from "@hooks/useGlobalState";
import { media, pxToRem } from "@styles/styleUtils";
import { useState } from "react";
import styled from "styled-components";

const UpdatedTermsContainer = styled.div<{ $isOpen: boolean }>`
  background-color: black;
  padding: 22px;
  max-width: 600px;
  width: 97vw;
  border-radius: 6px;
  display: ${({ $isOpen: isOpen }) => (isOpen ? "block" : "none")};
  color: white;
  position: fixed;
  left: 5px;
  transform: translate(0%, -50%);
  bottom: -50px;
  z-index: 999;

  ${media.svp} {
    padding: 15px;
  }

  svg {
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 7px;
  }
`;

const Description = styled.p`
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  max-width: 95%;
  margin: 0;

  ${media.svp} {
    font-size: ${pxToRem(12)};
  }

  a {
    color: #009cde !important;
  }

  a:visited {
    color: inherit;
  }
`;

export default function UpdatedTerms() {
  const [{ isNative }] = useGlobalState();

  // 👋 Hiding the updated terms ribbon for now. Leaving here just in case this will be reused for next year
  // const shownUpdatedTermsModal = localStorage.getItem("shown-updated-terms-modal");
  const shownUpdatedTermsModal = true;
  const [isOpen, setIsOpen] = useState(!shownUpdatedTermsModal && !isNative ? true : false);

  function handleTermsClose() {
    setIsOpen(false);
    localStorage.setItem("shown-updated-terms-modal", "true");
  }

  return (
    <UpdatedTermsContainer $isOpen={isOpen}>
      <svg
        onClick={() => handleTermsClose()}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M18.893 18.401c.323.325-.16.82-.49.49L12 12.489l-6.403 6.402c-.328.329-.814-.165-.49-.49L11.51 12 5.107 5.597c-.326-.327.164-.816.49-.49L12 11.51l6.403-6.401c.326-.327.815.162.49.49l-6.403 6.401 6.403 6.402z"
        />
      </svg>
      <Description>
        We have updated our <a href="https://www.ncaa.com/tos">Terms of Service.</a> By using NCAA sites and/or
        maintaining an account with us, you agree to our updated{" "}
        <a href="https://www.ncaa.com/tos">Terms of Service,</a> including an updated arbitration clause. By clicking
        &quot;X&quot;, you acknowledge you have read and agree to the updated{" "}
        <a href="https://www.ncaa.com/tos">Terms of Service.</a>
      </Description>
    </UpdatedTermsContainer>
  );
}
