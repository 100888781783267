import { Button } from "@styles/buttons.styled";
import { useTheme } from "next-themes";

const ThemeChanger = () => {
  const { resolvedTheme, setTheme } = useTheme();

  return (
    <>
      <h3>{resolvedTheme?.[0].toUpperCase() + resolvedTheme?.slice(1)} Theme</h3>
      <div>
        <Button onClick={() => setTheme(resolvedTheme === "light" ? "dark" : "light")}>Toggle Theme</Button>
      </div>
    </>
  );
};

export default ThemeChanger;
