import * as Switch from "@radix-ui/react-switch";
import { colors } from "@styles/styleUtils";
import styled from "styled-components";

// Filter this Styled Component to remove prop warnings: https://github.com/styled-components/styled-components/issues/1198#issuecomment-425650423
const StyledSwitch = styled(({ secondary, ...props }) => <Switch.Root {...props} />)`
  width: 50px;
  height: 30px;
  background-color: ${({ theme }) => (theme.mode === "light" ? colors.lvl_5 : colors["lvl_-5"])};
  border-radius: 9999px;
  position: relative;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  &[data-state="unchecked"] {
    border: 1px solid ${({ theme }) => (theme.mode === "light" ? colors["lvl_-5"] : colors.lvl_5)};
  }
  &[data-state="checked"] {
    background-color: ${({ theme, secondary }) =>
      secondary || theme.mode === "dark" ? colors.bcg_accent_2 : colors["lvl_-5"]};
  }

  &[data-opt-in-toggle] {
    &[data-state="checked"] {
      border: 1px solid ${({ theme }) => (theme.mode === "light" ? colors["lvl_-5"] : colors.lvl_5)};
      background-color: ${({ theme, secondary }) =>
        secondary || theme.mode === "dark" ? colors.bcg_accent_2 : colors["lvl_-5"]};
    }

    &[data-state="unchecked"] {
      background-color: ${({ theme }) => (theme.mode === "light" ? "rgba(118, 119, 131, 0.16)" : colors["lvl_-5"])};
      border: none;
    }
  }
`;

const StyledSwitchThumb = styled(Switch.Thumb)`
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: ${colors["lvl_-5"]};
  border-radius: 9999px;
  transition: transform 0.2s;
  transform: translateX(2px);
  &[data-state="unchecked"] {
    background-color: ${({ theme }) => (theme.mode === "light" ? colors["lvl_-5"] : colors.lvl_5)};
  }
  &[data-state="checked"] {
    transform: translateX(23px);
    background-color: ${colors.lvl_5};
  }
  [data-opt-in-toggle] & {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);

    &[data-state="checked"] {
      transform: translateX(23px);
      background-color: ${colors.lvl_5};
    }

    &[data-state="unchecked"] {
      background-color: ${({ theme }) => (theme.mode === "light" ? "#FDFDFD" : colors.lvl_5)};
    }
  }
`;

type SwitchProps = Switch.SwitchProps & {
  secondary?: boolean;
};

const SwitchComponent = ({ secondary = false }: SwitchProps) => {
  return (
    <StyledSwitch secondary={secondary}>
      <StyledSwitchThumb />
    </StyledSwitch>
  );
};

export default SwitchComponent;
