import useGlobalState from "@hooks/useGlobalState";
import useJavaScriptBridge from "@hooks/useJavaScriptBridge";
import { useRouter } from "next/router";
import { useEffect } from "react";
import useAds from "./useAds";

export default function PagesRouteEffects() {
  const [{ isNative }] = useGlobalState();
  const router = useRouter();
  const { fireAction } = useJavaScriptBridge();
  const { firePixel } = useAds();

  useEffect(() => {
    const sendRouteChangeEvent = (_url: string) => {
      // console.log(`>>> App is changing to ${_url}`);

      if (isNative && typeof window !== "undefined") {
        const returnUrl = new URL(window.location.href);

        fireAction({
          action: "navigate",
          returnUrl: returnUrl.href,
        });
      }

      firePixel("TWITTER_PAGE", "bcg");
    };

    // You can listen to different events happening inside the Next.js Router. routeChangeStart fires when a route starts to change.
    router.events.on("routeChangeStart", sendRouteChangeEvent);

    return () => {
      // When the component is unmounted you'll want to unsubscribe from the event with `off` method
      router.events.off("routeChangeStart", sendRouteChangeEvent);
    };
  }, [isNative, router, fireAction, firePixel]);

  return null;
}
