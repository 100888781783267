export const rootFontSize = 10;
/**
 * Converts pixels to a rem value.
 * @example pxToRem(32) // '2rem'
 * pxToRem('32') // '2rem'
 * pxToRem('32px') // '2rem'
 */
export const pxToRem = (px: number | string): string =>
  `${(Number(px.toString().replace("px", "")) / rootFontSize).toFixed(2)}rem`;

export const maxPageWidth = "1074px";

export const colors = {
  lvl_0: "var(--color_lvl_0)",
  lvl_1: "var(--color_lvl_1)",
  "lvl_-1": "var(--color_lvl_-1)",
  lvl_2: "var(--color_lvl_2)",
  "lvl_-2": "var(--color_lvl_-2)",
  lvl_3: "var(--color_lvl_3)",
  "lvl_-3": "var(--color_lvl_-3)",
  lvl_4: "var(--color_lvl_4)",
  "lvl_-4": "var(--color_lvl_-4)",
  lvl_5: "var(--color_lvl_5)",
  "lvl_-5": "var(--color_lvl_-5)",
  primary_accent: "var(--color_primaryaccent)",
  secondary_accent: "var(--color_secondaryaccent)",
  bcg_accent_1: "var(--color_bcg_accent1)",
  bcg_accent_1_alt: "var(--color_bcg_accent1_alt)",
  bcg_accent_2: "var(--color_bcg_accent2)",
  bcg_accent_2_hover: "var(--color_bcg_accent2_hover)",
  bcg_accent_3: "var(--color_bcg_accent3)",
  live_error: "var(--color_live_error)",
  live_error_hover: "var(--color_live_error_hover)",
  slc_accent: "var(--color_slc_accent)",
  black: "var(--color_black)",
  // variables below do not flip based on color mode:
  primary_accent_dark: "#1da1f1",
};

/**
 * Breakpoints by viewport (small, med, large).
 */
export const breakpoints = {
  svp: "640px",
  singleColumn: "810px", // arbitrary breakpoint added by Turner for reflowing layouts to a single column
  mvp: "1024px",
  lvp: "1280px",
};

const customMediaQuery = (maxWidth = "0px") => `@media (max-width: ${maxWidth})`;
/**
 * Quick max-width media queries for Small, Medium, & Large Viewports.
 * @example import { media } from '../style-utils'
 * const Div = styled.div`
 *   ${media.svp} {
 *    font-size: 20px;
 *   }
 * `
 */
export const media = {
  svp: customMediaQuery(breakpoints.svp),
  singleColumn: customMediaQuery(breakpoints.singleColumn),
  mvp: customMediaQuery(breakpoints.mvp),
  lvp: customMediaQuery(breakpoints.lvp),
  x: customMediaQuery,
  hover: `@media (hover: hover)`,
  motion: `@media (prefers-reduced-motion: no-preference)`, // Media Query for users with no "reduced-motion" settings enabled. It's helpful to add animation & motion-heavy styles within this query selector.
};

/**
 * Applies alpha to a given hex value.
 * @arg alpha default: 1
 * @example hexRGBA('#335596', 0.25); // rgba(51, 85, 150, 0.25)
 * hexRGBA('#eee', 0.25); // rgba(238, 238, 238, 0.25)
 */
export function hexRGBA(hex: string, alpha = 1): string {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    console.error(`Bad Hex Value: hexRGBA(${hex})`);
    return hex;
  }

  let c;
  c = hex.substring(1).split("");
  if (c.length == 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = `0x${c.join("")}`;

  return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(", ")}, ${alpha})`;
}
