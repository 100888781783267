/**
 * Add 3rd party tracking vendor scripts to the WM consent management instance after initialized.
 * Pixels: gtag | fb | sm_beacon
 */
export function addThirdPartyVendors() {
  function gtag(...args) {
    window.dataLayer.push(args);
  }
  try {
    const fbq = function (...args) {
      fbq.callMethod ? fbq.callMethod.apply(fbq, args) : fbq.queue.push(args);
    };
    window.fbq = fbq;
    window._fbq = fbq;
    fbq.push = fbq;
    fbq.loaded = false;
    fbq.version = "2.0";
    fbq.queue = [];
    fbq.disablePushState = true; // disables automatic pageview tracking
    fbq("init", "418245194992316");
    fbq("track", "PageView"); // NOTE: moved to navigate.js.erb
    window.fbq = fbq;
    window.dataLayer = window.dataLayer || [];
    gtag("js", new Date());
    gtag("config", "DC-5994466");
    gtag("config", "AW-945280042");
    window.gtag = gtag;
    window.WBD.UserConsent.addScript(
      {
        async: true,
        src: "https://www.googletagmanager.com/gtag/js?id=DC-5994466",
      },
      ["social-vendor"],
    );
    // window.WM.UserConsent.addScript(
    //   {
    //     async: true,
    //     src: "https://connect.facebook.net/en_US/fbevents.js",
    //   },
    //   ["social-vendor"],
    // );
    window["$$d2cxFn"] = "sm_beacon";
    window.sm_beacon =
      window.sm_beacon ||
      function (...args) {
        (window.sm_beacon.q = window.sm_beacon.q || []).push(args);
      };
    window.sm_beacon("setup", "beacon", "48c96adc-aaa2-4a31-874c-9c8592e07cd5");
    window.sm_beacon("setup", "user_tracking", true);
    window.sm_beacon("send", "pageview");
    window.WBD.UserConsent.addScript(
      {
        async: true,
        src: "https://analytics-sm.com/js/v1/beacon.js",
      },
      ["social-vendor"],
    );
  } catch (error) {
    console.warn("Warning: error initializing vendors", error);
  }
}
