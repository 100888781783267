import useApiFetch, { staticApiEndpoint } from "@hooks/useApiFetch";
import { useQuery } from "@tanstack/react-query";

export default function useBcgGame() {
  const { fetchApiEndpoint } = useApiFetch();

  return useQuery({
    queryKey: ["game", "mens-bcg"],
    queryFn: () => fetchApiEndpoint<BcgGame>(`${staticApiEndpoint}/game.json`),
    refetchInterval: 30 * 1000,
    refetchOnWindowFocus: false,
  });
}

interface BcgGroup {
  id: string;
  name: string;
  membersCount: number;
  isPrivate: boolean;
  creatorId: string;
  maxMembersPerUser: number;
  scoringMode: string;
  showPttcPromo: boolean;
  isVerified: boolean;
  backgroundColor: string;
  avatarBackgroundColor: string;
  imageUrl: string;
  hasFeaturedPersonalities: boolean;
  canJoinPostLock: boolean;
}
interface BcgTeam {
  seed: number;
  school: string;
  abbrev: string;
  classname: string;
  nick: string;
  color: string;
  colors: {
    [hexColor: string]: number;
  };
  id: number;
  link: string;
  is_eliminated: boolean;
}
interface BcgDashLeaderboardEntry {
  championTeamId: string;
  id: string;
  isPostlock: boolean;
  name: string;
  percentile: number;
  picksLockedInAt: string;
  possiblePointsRemaining: number;
  rank: number;
  tiebreakerLoser: number;
  tiebreakerWinner: number;
  totalPoints: number;
  tplusTotalPoints: number;
  upsetPossiblePointsRemaining: number;
  userId: string;
}

export type Games =
  | "ncaawbracketchallenge"
  | "ncaambracketchallenge"
  | "ncaamsalarycap"
  | "ncaamconfpickem"
  | "ncaawconfpickem";

export type GamesAbbrv = "slc" | "bcg" | "wbcg" | "mctp" | "wctp";

export interface BcgGame {
  play_dashboard: {
    bcg_open: boolean;
    wbcg_open: boolean;
    slc_open: boolean;
    mctp_open: boolean;
    wctp_open: boolean;
  };
  app_live: boolean;
  video: number[];
  groups: BcgGroup[];
  teams: {
    [id: string]: BcgTeam;
  };
  min_poll_ms: number;
  dashboardLeaderboard: BcgDashLeaderboardEntry[];
  selection_show: {
    /** e.g. "2022-03-13T18:00:00.000-04:00" */
    iso: string;

    /** e.g. 1647208800000 */
    value: number;
    game_id: string;
  };
  picking_lock: {
    /** e.g. "2021-02-11T12:00:00.000-05:00" */
    iso: string;

    /** e.g. 1613062800000 */
    value: number;
  };
  picking_open: boolean;
  picking_locked: boolean;
  signups_open: boolean;
  pre_picking_open: boolean;
  dashboard_ordering: Games[];
  post_picking_open: boolean;
  postlock_brackets_open: boolean;
  group_creation_open: boolean;
  group_joining_open: boolean;
  post_selection_sunday: boolean;
  group_joining_locked: boolean;
  game_state: "picking_open" | "picking_locked" | "registration_open" | "pre_launch" | "closed";
  state: -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  /** e.g. 1581961286000 */
  scored_at: number;
  /** number in ms */
  update_interval: number;
  /** number in ms */
  session_stale_ms: number;
  school_img_url: string;
  school_img_size: string;
  bcg_errors: {
    [errorCode: string]: string;
  };
  dalton_base_url: string;
  logos: {
    href: string;
    type: "sponsor" | "sponsor_horizontal" | "sponsor_stacked" | "prop1" | "prop2" | "prop3";
    title: string;
    description: string;
  }[];
  regions: {
    [regionId: string]: {
      abbrev: string;
      id: string;
      turner_id: number;
      position: string;
      name: string;
    };
  };
  matchups: {
    [matchupId: string]: {
      id: number;
      round_id: number;
      region_id: string;
      winnerTB: string;
      winnerBracket: number;
      region_matchup_i: number;
      tmT: number;
      tmB: number;
      tmV: number;
      tmH: number;
      tmTisH: boolean;
      ptsH: number;
      ptsV: number;
      state: number;
      stateExtended: string;
      stateDetail: string;
      scored: boolean;
      per: number;
      loc: number;
      tmLabelT: string;
      tmLabelB: string;
      tmConsensusV: number;
      tmConsensusH: number;
      /** e.g. 1553035200 */
      time: number;
      round_day_uid: string;
      broadcaster_classname: string;
      tmT_plays_in: boolean;
      tmB_plays_in: boolean;
    };
  };
  updated_first_four_picks: boolean;
  predict_disabled: boolean;
  dalton_fallback_enabled: boolean;
  autosave_timeout_ms: number;
  autosave_picks_threshold: number;
  maDisabledProperties: MatchupAnalysisProperties[];
  corporate_group_ids: {
    aflac?: number;
  };
  show_prizing_modal?: boolean;
}

type MatchupAnalysisProperties =
  | "overall_three_points_pct"
  | "overall_fastbreak_pts"
  | "overall_field_goals_pct"
  | "overall_free_throws_pct"
  | "opponent_pts"
  | "overall_points_in_paint"
  | "overall_points"
  | "seed"
  | "overall_turnovers"
  | "overall_winning_pct"
  | "steals_per_game"
  | "rebound_margin"
  | "last_ten_games"
  | "blocked_shots_per_game"
  | "offensive_rebounds_per_game"
  | "awp"
  | "opponent_efficiency"
  | "netranking"
  | "overall_efficiency"
  | "overall_second_chance_pts"
  | "sos"
  | "true_shooting_percentage"
  | "assist_turnover_ratio";
