import { colors, media } from "@styles/styleUtils";
import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;

  ${media.svp} {
    min-height: 50vh;
  }
`;

export const DescriptionContainer = styled.div`
  width: 80%;
  max-width: 350px;
  margin: 0 auto 25px auto;

  ${media.mvp} {
    padding: 32px 0 16px 0;
  }

  ${media.svp} {
    width: 90%;
    padding: 22px 0 0 0;
    margin-bottom: 15px;
  }
`;

export const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.14;
  text-align: center;

  ${media.mvp} {
    font-size: 1.6rem;
  }
`;

export const GroupInviteList = styled.ul`
  list-style-type: none;
  margin: 0 0 40px 8%;
  padding: 0;

  li {
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.fb-container {
      .fb-icon {
        transition: mask-image 0.2s ease-in-out, -webkit-mask-image 0.2s ease-in-out;
        width: 100%;
        height: 100%;
        background-color: var(--color_lvl_-5);
        -webkit-mask-image: url(/assets/images/socialShare/glyphs-bcg-18-px-fb-off.svg);
        mask-image: url(/assets/images/socialShare/glyphs-bcg-18-px-fb-off.svg);
        mask-repeat: no-repeat;
        mask-size: cover;
      }
      :hover {
        .fb-icon {
          width: 100%;
          background-color: var(--color_lvl_-5);
          -webkit-mask-image: url(/assets/images/socialShare/glyphs-bcg-18-px-fb-on.svg);
          mask-image: url(/assets/images/socialShare/glyphs-bcg-18-px-fb-on.svg);
          mask-repeat: no-repeat;
          mask-size: cover;
        }
      }
    }

    &.twitter-container {
      .twitter-icon {
        transition: mask-image 0.2s ease-in-out, -webkit-mask-image 0.2s ease-in-out;
        width: 100%;
        height: 100%;
        background-color: var(--color_lvl_-5);
        -webkit-mask-image: url(/assets/images/socialShare/glyphs-bcg-twitter-off.svg);
        mask-image: url(/assets/images/socialShare/glyphs-bcg-twitter-off.svg);
        mask-repeat: no-repeat;
        mask-size: cover;
      }
      :hover {
        .twitter-icon {
          width: 100%;
          height: 100%;
          background-color: var(--color_lvl_-5);
          -webkit-mask-image: url(/assets/images/socialShare/glyphs-bcg-twitter-on.svg);
          mask-image: url(/assets/images/socialShare/glyphs-bcg-twitter-on.svg);
          mask-repeat: no-repeat;
          mask-size: cover;
        }
      }
    }

    &.email-container {
      display: flex;
      align-items: center;
      .email-icon {
        transition: mask-image 0.2s ease-in-out, -webkit-mask-image 0.2s ease-in-out;
        width: 100%;
        height: 100%;
        background-color: var(--color_lvl_-5);
        -webkit-mask-image: url(/assets/images/socialShare/glyphs-bcg-18-px-mail-off.svg);
        mask-image: url(/assets/images/socialShare/glyphs-bcg-18-px-mail-off.svg);
        mask-repeat: no-repeat;
        mask-size: cover;
      }
      :hover {
        .email-icon {
          width: 100%;
          height: 100%;
          background-color: var(--color_lvl_-5);
          -webkit-mask-image: url(/assets/images/socialShare/glyphs-bcg-18-px-mail-on.svg);
          mask-image: url(/assets/images/socialShare/glyphs-bcg-18-px-mail-on.svg);
          mask-repeat: no-repeat;
          mask-size: cover;
        }
      }
    }
    &.link-container {
      display: flex;
      align-items: center;
      .link-icon {
        transition: mask-image 0.2s ease-in-out, -webkit-mask-image 0.2s ease-in-out;
        width: 100%;
        height: 100%;
        background-color: var(--color_lvl_-5);
        -webkit-mask-image: url(/assets/images/socialShare/glyphs-bcg-link-off.svg);
        mask-image: url(/assets/images/socialShare/glyphs-bcg-link-off.svg);
        mask-repeat: no-repeat;
        mask-size: cover;
      }
      :hover {
        .link-icon {
          width: 100%;
          height: 100%;
          background-color: var(--color_lvl_-5);
          -webkit-mask-image: url(/assets/images/socialShare/glyphs-bcg-link-on.svg);
          mask-image: url(/assets/images/socialShare/glyphs-bcg-link-on.svg);
          mask-repeat: no-repeat;
          mask-size: cover;
        }
      }

      span {
        position: relative;
        top: 2px;
      }

      svg {
        display: inline;
        vertical-align: middle;
        margin-right: 8px;
      }
    }

    ${media.mvp} {
      display: flex;
      justify-content: flex-start;
      margin: 0 0 40px 21%;
      text-align: center;

      li {
        flex: 0 0 18%;
        position: relative;

        &:after {
          position: absolute;
          display: block;
          right: 0;
          top: 12px;
          content: "";
          width: 1px;
          height: 57px;
          background-color: ${colors.lvl_3};
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }

    ${media.x("768px")} {
      li {
        flex: 0 0 25%;
      }
    }

    ${media.svp} {
      display: block;
      justify-content: left;
      text-align: left;
      margin: 0 0 30px 8%;
    }
  }
`;

export const SVGContainer = styled.div`
  width: 28px;
  height: 28px;
  text-align: center;
  display: inline-block;
  margin-right: 8px;

  &.email {
    svg {
      width: 18px;
      margin: 0 auto;
    }
  }

  ${media.mvp} {
    display: block;
    text-align: center;

    &.email {
      svg {
        margin-top: 3px;
      }
    }
  }

  ${media.svp} {
    display: inline-block;
    text-align: center;
    margin-right: 8px;
  }
`;

export const ButtonContainer = styled.div`
  button {
    display: none;
  }

  ${media.mvp} {
    button {
      display: block;
      width: 80%;
      max-width: 345px;
      margin: 0 auto;
    }
  }
`;

export const CopiedCopy = styled.div`
  width: 83%;
  position: absolute;
  text-align: center;
`;
