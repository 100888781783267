import fetch, { Request } from "cross-fetch";
import produce from "immer";
import { useCallback, useContext } from "react";
import useCookie from "./useCookie";
import useGlobalState from "./useGlobalState";
import settings from "src/settings";
import { UnauthenticatedRedirectContext } from "../components/nextJsClientComponents/UnauthenticatedRequestProvider";
import { ApiBackendError, SessionData, handleBackendError } from "./useApiFetch";

export const dynamicApiEndpoint = `/mens-bracket-challenge/api/v2/ncaabracketchallenge`;
export const staticApiEndpoint = `/mens-bracket-challenge/api/static-v2/ncaabracketchallenge`;

export const dynamicWomensBcgApiEndpoint = `/womens-bracket-challenge/api/v2/ncaabracketchallenge`;
export const staticWomensBcgApiEndpoint = `/womens-bracket-challenge/api/static-v2/ncaabracketchallenge`;

export const dynamicSlcApiEndpoint = `/api/v1/ncaamsalarycap`;
export const staticSlcApiEndpoint = `/api/static-v1/ncaamsalarycap`;

export const dynamicConfPickemApiEndpoint = `/api/v1/ncaamconfpickem`;
export const staticConfPickemApiEndpoint = `/api/static-v1/ncaamconfpickem`;

export const dynamicWomensConfPickemApiEndpoint = `/api/v1/ncaawconfpickem`;
export const staticWomensConfPickemApiEndpoint = `/api/static-v1/ncaawconfpickem`;

export const v3StaticApiEndpoint = `/api/static/v3/year/2022/accounts`;

const staticRouteRegex = /^\/api\/static-/;

const defaultHeaders = {
  "content-type": "application/json; charset=utf-8",
};
const defaultParams = {
  method: "GET",
  headers: defaultHeaders,
};
export const defaultBCGErrors = {
  400: "Unable to process request, please try again!",
  401: "Requested action cannot be performed at this time.",
  403: "Requested action is unavailable.",
  404: "Unable to find network connection!",
  406: "Request limited for your IP. Contact us if you feel this is an error: MMLiveHelp@turner.com",
  420: "Invalid Email or Password.",
  424: "Account or Password incorrect.",
  500: "Unable to process request, please try again!",
  502: "Unable to process request, please try again!",
  503: "Service temporarily unavailable.",
  504: "Network connection time out.",
  523: "Server is not responding, please try again!",
  generic: "Technical difficulty, please try again!",
};
const defaultOptions = {
  applySessionHeaders: true,
  applyGameErrorMessages: true,
};

/**
 * Hook that provides a fetch function that adds baseline headers, and values for our backend API or any api.
 * Session headers are applied by default, unless specified when invoking the hook or as an option on the fetch function.
 * @example
 * import useApiFetch from "@hooks/useApiFetch";
 * ...
 * const { fetchApiEndpoint } = useApiFetch();
 */

function useApiFetchWithStatusCode(applySessionHeaders = true) {
  const [{ apiAuthToken, isNative }, dispatch] = useGlobalState();
  const { setCookie, removeAllCookies } = useCookie();
  const handleUnauthenticatedRequest = useContext(UnauthenticatedRedirectContext);
  const errorCodes = defaultBCGErrors; // todo: set to (game.bcg_errors || defaultBCGErrors)
  const genericError = errorCodes.generic || defaultBCGErrors.generic;
  const { API_BACKEND_URL } = settings;

  const applyApiHeaders = useCallback(
    (endpoint: RequestInfo, params?: RequestInit): HeadersInit => {
      if (apiAuthToken && !staticRouteRegex.test(`${endpoint}`)) {
        const newHeaders = {
          Authorization: `Bearer ${apiAuthToken}`,
          ...defaultHeaders,
          ...{ buildType: isNative ? "native" : "web" },
          ...(params?.headers ?? {}),
        };
        return newHeaders;
      }
      return params?.headers ?? {};
    },
    [apiAuthToken, isNative],
  );

  const fetchApiEndpointWithStatusCode = useCallback(
    <T,>(endpoint: RequestInfo, params?: RequestInit, options?: Partial<typeof defaultOptions>): Promise<T> => {
      const fetchOptions = {
        ...defaultOptions, // defaults
        applySessionHeaders, // set via hook invocation
        ...(options || {}), // incoming explicit options
      };

      const newParams = produce(params || defaultParams, (draft) => {
        draft.method = params?.method || defaultParams.method;
        draft.headers = {
          ...defaultParams.headers, // defaults
          ...(params?.headers ?? {}), // incoming header params
          ...(fetchOptions.applySessionHeaders ? applyApiHeaders(endpoint, params) : {}), // session token params
        };
      });
      const req = new Request(`${endpoint}`);

      return fetch(req, newParams).then((res) => {
        // respext x-set-authorization-bearer in an api response to set our auth cookie.
        const setTokenHeader = "x-set-authorization-bearer";
        if (res.headers.has(setTokenHeader)) {
          const token = res.headers.get(setTokenHeader);
          dispatch((state) => {
            state.apiAuthToken = token;
          });
          setCookie("apiAuthTokenCookieName", token);
        }

        return res.text().then((text) => {
          try {
            // eslint-disable-next-line
            const data: T | ApiBackendError = JSON.parse(text);

            if ((data as SessionData)?.user?.forceLogout) {
              // if a forceLogout property is received on the user object we log out instantly.
              fetchApiEndpointWithStatusCode(`${API_BACKEND_URL}${dynamicApiEndpoint}/session/signout.json`, {
                method: "POST",
              }).then(() => {
                removeAllCookies();
                handleUnauthenticatedRequest();
              });
            }

            if (res.status > 400 && res.status !== 424) {
              if ((data as ApiBackendError)?.messages && options?.applyGameErrorMessages) {
                handleBackendError(data as ApiBackendError);
              }
              if (fetchOptions.applyGameErrorMessages && res.status in errorCodes) {
                throw errorCodes[res.status];
              }

              throw res;
            }

            return {
              ...data,
              responseOptions: res,
            } as T;
          } catch (error) {
            // Any errors parsing JSON data from a 200 response (empty response body) we catch & do not throw.
            if (!`${res.status}`.startsWith("2")) {
              throw error;
            }
            try {
              const json = JSON.parse(text);
              return json;
            } catch (error) {
              return text as unknown as T;
            }
          }
        });
      });
    },
    [applySessionHeaders, dispatch, genericError, errorCodes, setCookie, removeAllCookies, applyApiHeaders],
  );

  return { fetchApiEndpointWithStatusCode };
}

export default useApiFetchWithStatusCode;
