import useGlobalState from "@hooks/useGlobalState";
import { colors } from "@styles/styleUtils";
import styled, { keyframes } from "styled-components";

const squeeze = keyframes`
    50%,
    80% {
        transform: scaleX(0.1);
    }
    0% {
        transform: scaleX(1);
    }
  `;

const overlayShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const LoadingBlock = styled.div`
  width: 12px;
  height: 7px;
  margin: 7px;
  background: ${colors.primary_accent_dark};
  animation: ${squeeze} 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite;
`;

const LoadingBlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: ${colors.lvl_5};
  animation: ${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  & ${LoadingBlock}:nth-child(1) {
    animation-delay: -0.9s;
  }
  & ${LoadingBlock}:nth-child(2) {
    animation-delay: -0.6s;
  }
  & ${LoadingBlock}:nth-child(3) {
    animation-delay: -0.3s;
  }
  & ${LoadingBlock}:nth-child(4) {
    animation-delay: 0s;
  }
`;

export default function Loading() {
  const [{ isLoading }] = useGlobalState();

  return isLoading ? (
    <LoadingBlocksContainer>
      <LoadingBlock />
      <LoadingBlock />
      <LoadingBlock />
      <LoadingBlock />
    </LoadingBlocksContainer>
  ) : null;
}
