import { colors, media } from "src/styles/styleUtils";
import styled from "styled-components";
import { ringsideCondensed } from "../../lib/fonts";

export const TradePreviewContainer = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  padding: 0px 16px;

  ${media.svp} {
    grid-template-columns: 1fr;
  }
`;

export const TradePreviewColumn = styled.div``;

export const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 16px;
  margin: 0 auto;
  margin-bottom: 10px;

  ${media.svp} {
    max-width: 100%;
    div:first-child {
      display: none;
    }
  }

  div {
    text-align: center;
    width: 345px;
    display: flex;
    justify-content: center;

    ${media.svp} {
      width: 100%;
    }
  }

  small {
    color: ${colors.live_error};
    font-size: 1rem;
  }
`;

export const TradePreviewColumnHeaderContainer = styled.h3`
  border-bottom: solid 0.5px ${colors.lvl_2};
  display: flex;
  justify-content: flex-end;

  ${media.svp} {
    border-bottom: none;
  }
`;

export const TradePreviewColumnHeader = styled.h3`
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: auto;
`;

export const TradePreviewColumnPoints = styled.div`
  font-family: ${ringsideCondensed.style.fontFamily};
  font-size: 1.3rem;
  font-stretch: condensed;
  font-style: normal;
  font-weight: ${ringsideCondensed.style.fontWeight};
  letter-spacing: normal;
  line-height: normal;

  svg {
    display: inline;
  }
`;

export const TradePreviewTeamsContainer = styled.div`
  padding-top: 16px;

  ${media.svp} {
    padding-top: 10px;
  }
`;

export const SelectedPicksList = styled.ul`
  margin: 0 auto 32px auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  ${media.svp} {
    margin-bottom: 20px;

    &.sell {
      margin-bottom: 0px;
    }
  }
`;

export const TradePreviewButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 16px;
  margin-bottom: 30px;
  padding: 0px 16px;

  ${media.svp} {
    column-gap: 8px;
    padding: 0px 16px;
  }

  button {
    width: 345px;
    font-style: oblique;

    ${media.svp} {
      width: 50%;
    }
  }
`;

export const MyLineupContainer = styled.div`
  padding: 2px 16px 20px 16px;
  min-height: 50px;

  ul {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 8px;

    ${media.svp} {
      row-gap: 10px;
      padding-bottom: 15px;
      grid-template-columns: 1fr;
    }

    li {
      width: 100%;
      margin: 0px;
    }
  }

  ${media.svp} {
    padding: 5px 16px;
  }
`;

export const TradePreviewHeaderContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding: 4px 16px 8px 16px;

  ${media.svp} {
    padding: 10px 16px;
  }
`;

export const TradePreviewHeader = styled.h3`
  font-size: 1.2rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: oblique;
  line-height: 1.25;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-right: auto;
  align-self: center;
`;

export const TradePreviewErrorMessage = styled.div`
  text-align: center;
`;
