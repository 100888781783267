import ErrorSvg from "@icons/Error.svg";
import { colors, media } from "@styles/styleUtils";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.svp} {
    padding: 3rem;
    margin: 0 1rem;
  }

  & svg {
    width: 54px;
    height: 54px;
    margin-bottom: 24px;
    color: ${colors.live_error};
  }
`;

const Header = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
`;

const SubHeader = styled.h3`
  font-size: 4.8rem;
  line-height: 1;
`;

function ErrorComponent() {
  return (
    <Container>
      <ErrorSvg />
      <Header>Technical difficulty, please try again!</Header>
      <SubHeader></SubHeader>
    </Container>
  );
}

export default ErrorComponent;
