import { Session } from "@hooks/useSession";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAnalytics from "./useAnalytics";
import useApiFetch, { dynamicSlcApiEndpoint, handleBackendError } from "./useApiFetch";
import { SLCEntry } from "./useBundle";
import useGame from "./useGame";
import { GroupData, GroupLeaderboardQueryData } from "./useGroups";

export interface CreateAndEditGroupProps {
  name: string;
  password?: string;
  max_entries: number;
  can_join_postlock: boolean;
  group_id?: number;
  is_private?: boolean;
}

interface JoinGroupProps {
  entry_ids: number[];
  group_id: number;
  password?: string;
}

interface LeaveGroupProps {
  entry_ids: number[];
  group_id: number;
}

interface RemoveLineupProps {
  entry_ids: number[];
  group_id: number;
}
interface MessageGroupProps {
  subject: string;
  message: string;
  group_id: number;
}

interface InviteUserProps {
  emails: string[];
  group_id: number;
}

interface DeleteGroupProps {
  group_id: string | number;
}

export function useCreateGroupMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();

  function createGroup(creatGroupArgs: CreateAndEditGroupProps) {
    const { name, password, max_entries, can_join_postlock } = creatGroupArgs;
    return fetchApiEndpoint<GroupData>(
      `${dynamicSlcApiEndpoint}/groups.json`,
      {
        method: "POST",
        body: JSON.stringify({
          name: name,
          password: password,
          max_entries: max_entries,
          can_join_postlock: can_join_postlock,
        }),
      },
      { applyGameErrorMessages: true },
    );
  }

  return useMutation<GroupData, Response, CreateAndEditGroupProps>({
    mutationFn: createGroup,
    onMutate: () => {
      const prevGroups = queryClient.getQueryData(["groups"]);
      return { prevGroups };
    },
    onSuccess: (response) => {
      handleBackendError(response);
      queryClient.invalidateQueries({
        queryKey: ["groups"],
      });
    },
    onError: (error) => {
      console.warn(error);
      //   queryClient.setQueryData(["session"], context.prevGroups);
    },
  });
}

export function useJoinGroupMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();
  const { pushEventData } = useAnalytics();
  const { data: game } = useGame();
  const currentRound = game?.state?.current_round?.round_number;

  function joinGroup({ entry_ids, group_id, password }: JoinGroupProps) {
    return fetchApiEndpoint<Session>(
      `${dynamicSlcApiEndpoint}/groups/${group_id}/join.json`,
      {
        method: "PUT",
        body: JSON.stringify({
          entry_ids,
          password,
        }),
      },
      { applyGameErrorMessages: true },
    );
  }

  return useMutation<Session, Response, JoinGroupProps>({
    mutationFn: joinGroup,
    onMutate: () => {
      const prevGroups = queryClient.getQueryData(["groups"]);
      return { prevGroups };
    },
    onSuccess: async (response, context) => {
      const { group_id } = context;
      const array = [];
      const objects = {};

      // const myEntryIds = response?.entries.map((entry) => entry.entry_id);
      const groupData = queryClient.getQueryData(["group", group_id]);

      const entriesInGroup = response.entries.filter((entry) =>
        entry.group_memberships?.some((g) => {
          return g.group_id == Number(group_id);
        }),
      );

      await queryClient.setQueryData(["session"], () => {
        return response;
      });

      for (let x = 0; x < entriesInGroup.length; x++) {
        const entry = entriesInGroup[x];
        const member = entry?.group_memberships.find((x) => {
          return x.group_id == Number(group_id);
        });

        objects[x] = {
          entry_id: entry?.entry_id,
          name: entry?.name,
          rank: member?.group_rank,
          net_worth: Number(entry?.net_worth),
          current_round_picks: entry?.picks[currentRound],
        };
        array.push(objects[x]);
      }

      queryClient.invalidateQueries({
        queryKey: ["group", group_id],
      });

      if (groupData) {
        await queryClient.setQueryData(["group", group_id], (prevData: GroupLeaderboardQueryData) => {
          const data = structuredClone(prevData);
          return data;
        });
      }

      pushEventData({
        eventName: "join_group_complete",
        eventAction: "join_group_complete",
        eventType: "slc",
        eventTarget: "join_group_complete",
        challengeGameName: "slc",
      });
    },
    onError: (error) => {
      console.warn(error);
    },
  });
}

export function useLeaveGroupMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();

  function leaveGroup({ entry_ids: entry_ids, group_id: group_id }) {
    return fetchApiEndpoint<Session>(
      `${dynamicSlcApiEndpoint}/groups/${group_id}/leave.json`,
      {
        method: "PUT",
        body: JSON.stringify({
          entry_ids: entry_ids,
        }),
      },
      { applyGameErrorMessages: true },
    );
  }

  return useMutation<Session, Response, LeaveGroupProps>({
    mutationFn: leaveGroup,
    onMutate: () => {
      const prevGroups = queryClient.getQueryData(["groups"]);
      return { prevGroups };
    },
    onSuccess: async (response, context) => {
      const { group_id } = context;
      const myEntryIds = response?.entries.map((entry) => entry.entry_id);

      await queryClient.setQueryData(["session"], () => {
        return response;
      });

      const groupData = queryClient.getQueryData(["group", group_id]);
      if (groupData) {
        await queryClient.setQueryData(["group", group_id], (prevData: GroupLeaderboardQueryData) => {
          const data = structuredClone(prevData);
          data.leaderboard = data.leaderboard.filter((x) => !myEntryIds.includes(x.entry_id));
          return data;
        });
      }
    },
    onError: (error) => {
      console.warn(error);
    },
  });
}

export function useEditGroupMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();

  function editGroup(props) {
    return fetchApiEndpoint<GroupData>(
      `${dynamicSlcApiEndpoint}/groups/${props.group_id}.json`,
      {
        method: "PUT",
        body: JSON.stringify({
          ...props,
        }),
      },
      { applyGameErrorMessages: true },
    );
  }

  return useMutation<GroupData, Response, CreateAndEditGroupProps>({
    mutationFn: editGroup,
    onSuccess: (response, context) => {
      const { group_id } = context;
      handleBackendError(response);
      queryClient.invalidateQueries({
        queryKey: ["bundle"],
      });
      queryClient.invalidateQueries({
        queryKey: ["groups"],
      });
      const groupData: GroupData = queryClient.getQueryData(["group", group_id]);

      queryClient.setQueryData(["group", group_id], () => {
        return {
          ...groupData,
          group: response,
        };
      });
    },
    onError: (error) => {
      console.warn(error);
    },
  });
}

export function useCommissionerGroupMessageMutation() {
  const { fetchApiEndpoint } = useApiFetch();

  function messageGroup({ subject, message, group_id }: MessageGroupProps) {
    return fetchApiEndpoint(`${dynamicSlcApiEndpoint}/groups/${group_id}/commissioner-message.json`, {
      method: "POST",
      body: JSON.stringify({
        subject,
        message,
      }),
    });
  }

  return useMutation({ mutationFn: messageGroup });
}

export function useInviteUserMutation() {
  const { fetchApiEndpoint } = useApiFetch();

  function inviteUser({ emails, group_id }: InviteUserProps) {
    return fetchApiEndpoint(`${dynamicSlcApiEndpoint}/groups/${group_id}/send-invite.json`, {
      method: "POST",
      body: JSON.stringify({
        emails,
      }),
    });
  }

  return useMutation({ mutationFn: inviteUser });
}

export function useDeleteGroupMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();
  const { pushEventData } = useAnalytics();

  function deleteGroup(group_id) {
    return fetchApiEndpoint<SLCEntry>(`${dynamicSlcApiEndpoint}/groups/${group_id}.json`, {
      method: "DELETE",
    });
  }

  return useMutation<SLCEntry, Response, DeleteGroupProps>({
    mutationFn: deleteGroup,
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ["bundle"],
      });
      queryClient.invalidateQueries({
        queryKey: ["groups"],
      });
      queryClient.setQueryData(["session"], (prevSession: Session) => {
        const newSession = structuredClone(prevSession);
        newSession.entries = [...(newSession.entries || []), response];
        return newSession;
      });
      pushEventData({
        eventName: "delete_group",
        eventAction: "delete_group",
        eventType: "slc",
        eventTarget: "delete_group",
        challengeGameName: "slc",
      });
    },
    onError: (error) => {
      console.warn(error);
    },
  });
}

export function useRemoveLineupMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();

  function removeLineup({ entry_ids, group_id }: RemoveLineupProps) {
    return fetchApiEndpoint<Session>(`${dynamicSlcApiEndpoint}/groups/${group_id}/remove-member.json`, {
      method: "PUT",
      body: JSON.stringify({
        entry_ids: entry_ids,
      }),
    });
  }

  return useMutation<Session, Response, RemoveLineupProps>({
    mutationFn: removeLineup,
    onSuccess: async (res, context) => {
      const { group_id, entry_ids } = context;

      return queryClient.setQueryData(["group", group_id], (prevData: GroupLeaderboardQueryData) => {
        const data = structuredClone(prevData);
        data.leaderboard = data.leaderboard.filter((x) => !entry_ids.includes(x.entry_id));
        return data;
      });
    },
    onError: (error) => {
      console.warn(error);
      //   queryClient.setQueryData(["session"], context.prevGroups);
    },
  });
}

export function useRolloverGroups() {
  const { fetchApiEndpoint } = useApiFetch(true);
  // const queryClient = useQueryClient();

  function renewGroup({ group_id, entry_ids }: RemoveLineupProps) {
    return fetchApiEndpoint<Session>(`${dynamicSlcApiEndpoint}/groups/${group_id}/rollover-complete.json`, {
      method: "POST",
      body: JSON.stringify({
        invited_user_ids: entry_ids,
      }),
    });
  }

  return useMutation<Session, Response, RemoveLineupProps>({
    mutationFn: renewGroup,
    onSuccess: async () => {
      // const { group_id } = context;
      // const session = queryClient.getQueryData(["session"]);
      // const entriesInGroup = session?.entries.filter((entry) => {
      //   return entry?.group_memberships?.some((g) => g?.group_id == group_id);
      // });
      // return queryClient.setQueryData(["group", group_id], (prevData: GroupLeaderboardQueryData) => {
      //   const data = structuredClone(prevData);
      //   return {
      //     ...data,
      //     leaderboard: entriesInGroup,
      //   };
      // });
    },
    onError: (error) => {
      console.warn(error);
      //   queryClient.setQueryData(["session"], context.prevGroups);
    },
  });
}
