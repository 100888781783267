import useBcgGame, { GamesAbbrv } from "@hooks/bcg/useBcgGame";
import useAuth from "@hooks/useAuth";
import fetch from "cross-fetch";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import _set from "lodash.set";
import { BaseRouter } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { createNonce, gameUrls } from "src/lib/url-helpers";
import { debug } from "src/pages/debug";
import settings from "src/settings";
import {
  DaltonIdentityTypesResponse,
  DaltonProfileValues,
  DaltonUserResponse,
  OauthJwtValues,
  TDaltonIdentityTypes,
  TDaltonProfile,
} from "src/types/auth";
import useAds from "./useAds";
import useAnalytics from "./useAnalytics";
import useApiFetch, { dynamicApiEndpoint } from "./useApiFetch";
import useBundle from "./useBundle";
import useCookie from "./useCookie";
import useGlobalState, { initialState } from "./useGlobalState";
import useJavaScriptBridge from "./useJavaScriptBridge";
import useApiFetchWithStatusCode from "./useApiFetchWithStatusCode";
import { useSearchParams } from "next/navigation";

const {
  APP_ENV,
  SEASON,
  ENV_NAMES: { PROD },
} = settings;

/**
 * Hook to bring in Dalton functions and business logic.
 * @example
 * const { signIn } = useDalton()
 */
export default function useDalton() {
  const [{ dalton, isNative, isIframe, modal }, dispatch] = useGlobalState();
  const { setCookie, getCookie, removeAllCookies, removeCookie } = useCookie();
  const { fetchApiEndpoint } = useApiFetch(false);
  const { fetchApiEndpointWithStatusCode } = useApiFetchWithStatusCode(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const { fireAction } = useJavaScriptBridge();
  const { pushEventData } = useAnalytics();
  const { firePixel } = useAds();
  const { data: game } = useBcgGame();
  const { handleGameRedirect, autoCreateEntry } = useAuth();
  const { data: bundle } = useBundle();
  const searchParams = useSearchParams().toString();
  const queryParams = searchParams.length ? `?${searchParams}` : "";
  const { query } = useRouter();

  const defaultDaltonUrl = `https://audience${APP_ENV === PROD ? "" : ".qa"}.ncaa.com`;
  const daltonUrl = game?.dalton_base_url || defaultDaltonUrl;
  const ARKOSE_LOGIN_KEY = !daltonUrl.includes("qa")
    ? "A81F9530-112A-47B2-BA4B-8CB41D7C6DD6"
    : "34483B44-45CD-49AB-8D98-C7965FB41CF9";
  const ARKOSE_REGISTRATION_PW_RESET_KEY = !daltonUrl.includes("qa")
    ? "95218C8B-B84E-413C-B875-785B35F92134"
    : "9E0FB286-426D-405B-95A4-0B59440069B5";

  const { API_BACKEND_URL } = settings;

  /**
   * Use this endpoint to log in a returning user.
   */
  const daltonSignIn = useCallback(
    (principal: string, credential: string, identityType: TDaltonIdentityTypes, arkoseToken?: string) => {
      const endpoint = `/core/api/1/user/login`;
      const body = JSON.stringify({
        principal,
        credential,
        identityType,
        apps: ["ncaa"],
      });

      return fetchApiEndpointWithStatusCode<string>(`${daltonUrl}${endpoint}`, {
        method: "POST",
        headers: {
          "x-arkose-token": arkoseToken,
        },
        body,
      });
    },
    [fetchApiEndpointWithStatusCode, daltonUrl],
  );

  interface IdentityPayload {
    identityType: string;
    credential: string;
    principal?: string;
    arkoseToken?: string;
    responseOptions?: any;
  }
  /**
   * Use this endpoint to register a new User with a new Identity.
   */
  const daltonIdentity = useCallback(
    (payload: IdentityPayload) => {
      const endpoint = `/core/api/1/identity`;
      const { arkoseToken, ...bodyPayload } = Object.assign({}, payload);

      return fetchApiEndpointWithStatusCode<string>(`${daltonUrl}${endpoint}`, {
        method: "POST",
        headers: {
          "x-arkose-token": payload.arkoseToken,
        },
        body: JSON.stringify({
          identityRequests: [bodyPayload],
        }),
      });
    },
    [daltonUrl, fetchApiEndpointWithStatusCode],
  );

  /**
   * Use this endpoint to unsubscribe a user to the marketing newsletter.
   */
  const daltonUnsubscribe = useCallback(
    (token: string, email: string) => {
      const endpoint = `/newsletters/api/1/subscriptions/delete`;
      return fetchApiEndpoint(`${daltonUrl}${endpoint}`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          subscriptions: [
            {
              newsletterName: "offers_marketing",
              emailAddress: email,
            },
          ],
        }),
      });
    },
    [fetchApiEndpoint, daltonUrl],
  );

  /**
   * Use this endpoint to subscribe a user to the marketing newsletter.
   */
  const daltonSubscribe = useCallback(
    (token: string, email: string) => {
      const endpoint = `/newsletters/api/1/subscriptions/add`;
      return fetchApiEndpoint(`${daltonUrl}${endpoint}`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          emailAddress: email,
          newsletters: ["offers_marketing"],
        }),
      });
    },
    [fetchApiEndpoint, daltonUrl],
  );

  interface CreateProfilePayload {
    token: string;
    email: string;
    firstName: string;
  }

  /**
   * Use this endpoint to create a new profile on an existing user identity in Dalton.
   */
  const daltonCreateProfile = useCallback(
    (data: CreateProfilePayload) => {
      const endpoint = `/core/api/1/user`;
      const body = JSON.stringify({
        emailAddress: data.email,
        apps: ["ncaa"],
        firstName: data.firstName || "",
        attributes: {
          ncaa: {
            [`bcg${SEASON}Player`]: true,
          },
        },
      });
      return fetchApiEndpointWithStatusCode<string>(`${daltonUrl}${endpoint}`, {
        method: "POST",
        headers: {
          Authorization: `${data.token}`,
        },
        body,
      });
    },
    [fetchApiEndpointWithStatusCode, daltonUrl],
  );

  /**
   * Use this endpoint to update a user profile.
   */
  const daltonUserProfile = useCallback(
    (token: string, data: object) => {
      const endpoint = `/core/api/1/user/profile`;
      return fetchApiEndpoint<DaltonUserResponse["user"]>(`${daltonUrl}${endpoint}`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: JSON.stringify({ ...data }),
      });
    },
    [daltonUrl, fetchApiEndpoint],
  );

  /**
   * Use this endpoint to send a Magic Link email to a user.
   */
  const daltonRequestMagicLink = useCallback(
    (email: string, arkoseToken: string) =>
      fetchApiEndpointWithStatusCode(`${daltonUrl}/core/api/1/passwordless/create_code`, {
        method: "POST",
        headers: {
          "x-arkose-token": arkoseToken,
        },
        body: JSON.stringify({
          principal: email,
          identityType: "EMAIL",
        }),
      }),
    [daltonUrl, fetchApiEndpointWithStatusCode],
  );

  /**
   * Use this endpoint to trigger an email with a password reset token.
   */
  const daltonRequestPasswordToken = useCallback(
    (email: string, arkoseToken: string) =>
      fetchApiEndpointWithStatusCode(`${daltonUrl}/core/api/1/identity/password_reset_token`, {
        method: "POST",
        headers: {
          "x-arkose-token": arkoseToken,
        },
        body: JSON.stringify({
          principal: email,
          identityType: "EMAIL",
        }),
      }).then(() => setCookie("authAttemptViaKey", email)),
    [daltonUrl, fetchApiEndpointWithStatusCode, setCookie],
  );

  /**
   * Use this endpoint to change a user's password.
   */
  const daltonPasswordChange = useCallback(
    (authToken: string, newPassword: string, arkoseToken?: string) =>
      fetchApiEndpointWithStatusCode(`${daltonUrl}/core/api/1/identity/password`, {
        method: "POST",
        headers: {
          Authorization: `${authToken}`,
          "x-arkose-token": arkoseToken,
        },
        body: JSON.stringify({
          credential: newPassword,
          identityType: "EMAIL",
        }),
      }),
    [daltonUrl, fetchApiEndpointWithStatusCode],
  );

  /**
   * Use this endpoint to provide a list of identity types of the user.
   */
  const daltonGetIdentityTypes = useCallback(
    (credential: string, identityType: string) =>
      fetchApiEndpointWithStatusCode<DaltonIdentityTypesResponse>(`${daltonUrl}/core/api/1/identity/identitytypes`, {
        method: "POST",
        body: JSON.stringify({
          credential,
          identityType,
        }),
      }),
    [daltonUrl, fetchApiEndpointWithStatusCode],
  );

  /**
   * Use this endpoint to add a credential and identityType to a prior valid authToken
   */
  const daltonAuthMerge = useCallback(
    (prevToken, credential, identityType) =>
      fetchApiEndpointWithStatusCode(`${daltonUrl}/core/api/1/identity/identities`, {
        method: "POST",
        headers: {
          Authorization: `${prevToken}`,
        },
        body: JSON.stringify({
          credential,
          identityType,
        }),
      }),
    [fetchApiEndpointWithStatusCode, daltonUrl],
  );

  interface AuthMergeVals {
    credential: string;
    identityType: TDaltonIdentityTypes;
  }
  const checkAuthMerge = useCallback(
    (authToken) => {
      const authMergeToken = getCookie("daltonPendingAuthMerge");
      const authMergeValues: AuthMergeVals = (authMergeToken && JSON.parse(authMergeToken)) || null;
      const credential = authMergeValues?.credential;
      const identityType = authMergeValues?.identityType;

      // if there is an authMergeCookie && previous identity values we fire a merge request with old and new parameters to merge accounts.
      if (authMergeToken && authToken && credential && identityType) {
        return daltonAuthMerge(authToken, credential, identityType)
          .then(() => {
            dispatch((state) => {
              state.dalton.credential = null;
            });
            removeCookie("daltonPendingAuthMerge");
          })
          .catch((e) => console.error(`${e}`));
      }
      return Promise.resolve();
    },
    [daltonAuthMerge, getCookie, removeCookie, dispatch],
  );

  /**
   * Update state and cookies as we get successful responses from Dalton. Set the daltonToken cookie which we use to fetch data for returning users.
   */
  const storeDaltonResponse = useCallback(
    (res: string) => {
      dispatch((state) => {
        state.dalton.token = res;
      });
      setCookie("daltonTokenCookieName", res);
    },
    [dispatch, setCookie],
  );

  interface SyncResponse {
    user: {
      bcgId: string;
      brand: "aflac" | null;
      forceLogout: boolean;
      id: string;
      name: string;
      staticEndpointKey: string;
    };
  }

  const triggerSync = useCallback(
    (res: string, subscribe?: boolean, needsEntry?: boolean, activate?: boolean, prizing?: boolean | null) => {
      const authToken = res;
      const segmentId = Cookies.get("segment-id");
      // @ts-expect-error getVisitorId seems to be an issue with TS
      const mcID = isNative ? router.query.MCMID : window?._satellite?.getVisitorId()?.getMarketingCloudVisitorID();

      return fetchApiEndpointWithStatusCode<SyncResponse>(`${API_BACKEND_URL}${dynamicApiEndpoint}/users/sync.json`, {
        method: "POST",
        body: JSON.stringify({
          authToken,
          identityType: dalton.identityType || "EMAIL",
          analytics: {
            mcid: mcID,
            webPlayer: !isNative ? true : false,
            appPlayer: isNative ? true : false,
          },
          build: isNative ? "native" : "web",
          ...(typeof subscribe === "boolean" ? { subscribe } : {}),
          ...(typeof prizing === "boolean" ? { prizing_opt_in: prizing } : {}),
          ...(isNative && segmentId ? { segment_id: segmentId } : {}),
        }),
      }).then(
        async (res) => {
          if (shouldUpdateProfile(res)) {
            dispatch((state) => {
              state.currentAuthScreen = "UPDATE_PROFILE";
            });
          } else {
            dispatch((state) => {
              state.staticEndpointKey = res.user.staticEndpointKey;
              state.dalton.credential = null;
            });
            await setCookie("staticEndpointKeyCookieName", res.user.staticEndpointKey);
            await setCookie("bcgIdCookieName", res.user.bcgId);
            await setCookie("legacy__hasSportsTechIncAccountCookie", "t");

            // if ?redirect=iframe is applied we push to the mml page rather than to the dashboard.
            const hashParams = new URLSearchParams(window.location.hash.replace("#", "?"));
            const redirect = query.redirect || hashParams.get("redirect");
            const redirectUrl = `https://${APP_ENV === PROD ? "www" : "uat"}.ncaa.com/march-madness-live/bcg`;

            if (redirect === "iframe") {
              await router.push(redirectUrl);
            }

            if (isIframe) {
              fireAction({ action: "sign_in" });
            }

            if (activate) {
              await router.push({
                pathname: "/activate",
              });
            }
            let gameId =
              modal.options.selectedGame ||
              (router.query.type as GamesAbbrv) ||
              // for social login/reg, since we lose state when be redirected to oauth
              (getCookie("selectedGame") as GamesAbbrv) ||
              "bcg";
            if (query.fromMbcgGroup || query.fromWbcgGroup) gameId = query.fromMbcgGroup ? "bcg" : "wbcg";

            // remove selectedGame and socialAuthRegister cookie for social login after a user has been logged in and redirected to the appropriate game.
            removeCookie("selectedGame");
            localStorage.removeItem("socialAuthRegister");

            // Login finalized... create entry / redirect to game
            if (!activate) {
              if (needsEntry) {
                autoCreateEntry(gameId);
              } else {
                handleGameRedirect(gameId);
              }
            }
          }
        },
        (error) => {
          console.error(error);
          dispatch((state) => {
            state.modal.openModal = "APPLICATION_ERROR";
          });
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isNative,
      isIframe,
      router,
      dispatch,
      fetchApiEndpointWithStatusCode,
      dalton.identityType,
      setCookie,
      fireAction,
      query,
    ],
  );

  const signIn = useCallback(
    (email: string, password: string, activate?: boolean, prizing?: boolean, arkoseToken?: string) => {
      const identityType = "EMAIL";
      // 1. set wipEmail in order to prefill later in the forgot password flow.
      dispatch((state) => {
        state.dalton.wipEmail = email;
        state.dalton.identityType = identityType;
        state.dalton.credential = password;
      });
      setCookie("authAttemptViaKey", email);

      // 2. return api promise
      return daltonSignIn(email, password, identityType, arkoseToken).then(async (res) => {
        // 3. Check if we need to merge a prior successful auth attempt
        await checkAuthMerge(res);

        storeDaltonResponse(res);

        // 4. check for update profile
        // if (shouldUpdateProfile(res)) {
        //   dispatch((state) => {
        //     state.currentAuthScreen = "UPDATE_PROFILE";
        //   });
        // } else {
        // 5. Otherwise call sync to finalize auth and move to dashboard

        return activate
          ? triggerSync(res, null, false, true)
          : (bundle?.mbcg_entries?.length == 0 && query.fromMbcgGroup) || query.fromWbcgGroup
          ? triggerSync(res, null, true)
          : triggerSync(res, null, false, false, prizing);
        // }
      });
    },
    [daltonSignIn, dispatch, setCookie, triggerSync, storeDaltonResponse, checkAuthMerge],
  );

  const signOut = useCallback(() => {
    return fetchApiEndpoint(`${API_BACKEND_URL}${dynamicApiEndpoint}/session/signout.json`, { method: "POST" }).then(
      () => {
        queryClient.clear();
        removeAllCookies();
        dispatch((state) => {
          state.dalton = initialState.dalton;
          state.apiAuthToken = "";
          state.staticEndpointKey = "";
        });
        if (isNative) {
          fireAction({ action: "signout" });
        } else if (isIframe) {
          fireAction({ action: "sign_out" });
        }
        router.push(`/${queryParams}`);
      },
    );
  }, [removeAllCookies, fetchApiEndpoint, router, dispatch, fireAction, isNative, isIframe, queryClient]);

  /**
   * Use this to Add Email Address to a user
   */
  const daltonAddEmailToProfile = useCallback(
    (token, email) =>
      fetchApiEndpointWithStatusCode(`${daltonUrl}/core/api/1/user/email`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          emailAddress: email,
          primary: true,
        }),
      }),
    [fetchApiEndpointWithStatusCode, daltonUrl],
  );

  /**
   * Use this endpoint to retrieve a Dalton User via their authToken. Automatically stores values to state.
   */
  const getProfile = useCallback(
    (token: string) => {
      const endpoint = `/core/api/1/user`;
      return fetchApiEndpoint<DaltonUserResponse["user"]>(`${daltonUrl}${endpoint}`, {
        headers: {
          Authorization: `${token}`,
        },
      }).then((res) => {
        storeDaltonResponse(token);
        return res;
      });
    },
    [fetchApiEndpoint, daltonUrl, storeDaltonResponse],
  );

  interface SignUpParameters {
    email: string;
    firstName: string;
    password: string;
    confirmPassword: string;
    subscribed: boolean;
    prizing: boolean | null;
    arkoseToken: string;
  }

  const signUp = useCallback(
    (params: SignUpParameters) => {
      const { email, firstName, password, subscribed, prizing, arkoseToken } = params;

      const identityType = "EMAIL";

      // 1. Set the user's identity type to email with this vector.
      dispatch((state) => {
        state.dalton.identityType = identityType;
        state.dalton.credential = password;
      });
      setCookie("authAttemptViaKey", email);

      // 2. call daltonIdentity endpoint to generate a new user identity & auth token.
      return daltonIdentity({ credential: password, principal: email, identityType, arkoseToken }).then((authToken) => {
        storeDaltonResponse(authToken);

        return daltonCreateProfile({ token: authToken, email, firstName }).then((res) => {
          storeDaltonResponse(res);
          firePixel("TWITTER_CREATE_USER", null);
          triggerSync(res, subscribed, true, false, prizing);
          return res;
        });
      });
    },
    [dispatch, setCookie, daltonIdentity, daltonCreateProfile, triggerSync, storeDaltonResponse, firePixel],
  );

  interface UpdatePayload {
    firstName: string;
    attributes: {
      ncaa: {
        bcg2022Player: boolean;
      };
    };
  }
  interface UpdateParameters {
    firstName?: string;
    subscribed?: boolean;
    currentProfile?: DaltonProfileValues;
    isSocialReg?: boolean;
  }
  const updateProfile = useCallback(
    async (params: UpdateParameters) => {
      const { firstName, subscribed, currentProfile, isSocialReg } = params;
      const { firstName: currentFirstName, email: currentEmail, currentSeasonPlayer } = currentProfile;

      const updateData: Partial<UpdatePayload> = {};

      if (firstName !== currentFirstName) {
        updateData.firstName = firstName;
      }
      if (!currentSeasonPlayer) {
        _set(updateData, `attributes.ncaa.bcg${SEASON}Player`, true);
      }

      //* If updateProfile is called from Social Registration, the Dalton endpoint is createProfile rather than updateProfile.

      if (isSocialReg) {
        const createProfilePayload = {
          token: dalton?.profile?.token || "",
          email: currentEmail || "",
          firstName: firstName,
        };

        return daltonCreateProfile(createProfilePayload)
          .then((res) => {
            storeDaltonResponse(res);
            triggerSync(res, subscribed, true, false);
          })
          .catch(async (res) => {
            if (res.status === 429) {
              return dispatch((state) => {
                state.currentAuthScreen = "AUTH_MERGE";
              });
            }
            throw res;
          });
      } else {
        return daltonUserProfile(dalton?.profile?.token, updateData)
          .then(() => {
            storeDaltonResponse(dalton?.profile?.token);
            triggerSync(dalton?.profile?.token);
          })
          .catch(async (res) => {
            throw res;
          });
      }
    },
    [dispatch, dalton?.profile?.token, storeDaltonResponse, daltonCreateProfile, triggerSync, daltonUserProfile],
  );

  function shouldUpdateProfile(res: SyncResponse) {
    /*
    debug("Dalton shouldUpdateProfile() values:", {
      res: res,
      firstName: res?.user?.firstName,
      postalCode: res?.user?.userAddressResponses?.[0]?.postalCode,
      bcgPlayer: res?.user?.userProfileResponses?.ncaa?.attributes?.[`bcg${SEASON}Player`],
      SEASON,
    });
    */

    // // if (isAflacGame){ return false; }

    if (!res?.user?.name) {
      return true;
    }
    return false;
  }

  /**
   * Use this endpoint to request a password reset token then change a user's password.
   */
  const resetPassword = useCallback(
    (resetToken: string, newPassword: string, arkoseToken: string) =>
      fetchApiEndpoint<string>(`${daltonUrl}/core/api/1/identity/password_reset_token?resetToken=${resetToken}`).then(
        (authToken) => daltonPasswordChange(authToken, newPassword, arkoseToken),
      ),
    [daltonUrl, fetchApiEndpoint, daltonPasswordChange],
  );

  const magicLinkHandleSubmit = useCallback(
    (query: BaseRouter["query"], arkoseToken) => {
      const address = query.address?.toString() ?? "";
      const token = query.token?.toString() ?? "";
      const identityType = "PWLESS_EMAIL";

      dispatch((state) => {
        state.dalton.identityType = identityType;
        state.dalton.credential = token;
      });

      const sleep = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms));

      debug("🔮 Magic Link Auth Initiated");

      return sleep(2300).then(() =>
        daltonSignIn(address, token, identityType, arkoseToken).then((res) => {
          checkAuthMerge(res);
          storeDaltonResponse(res);
          triggerSync(res);
        }),
      );
    },
    [dispatch, triggerSync, storeDaltonResponse, daltonSignIn],
  );

  /**
   * Handles clicking on an auth login button to initiate the social auth process.
   */
  const socialAuthRequest = useCallback(
    (service: "apple" | "facebook" | "google", query?: BaseRouter["query"]) => {
      const stateParam = createNonce(32);
      const currentStoredState = getCookie("oauthStateStorageKey") ?? "";
      const origin = gameUrls[APP_ENV];
      const appleReturnURL = `${origin}/api/v2/ncaabracketchallenge/session/apple.json${
        isIframe ? "?redirect=iframe" : ""
      }`;
      const fbGoogReturnURL = `${origin}/auth${isIframe ? "?redirect=iframe" : ""}`;

      if (APP_ENV !== PROD) {
        console.groupCollapsed("🔐 socialAuth handler values:");
        // prettier-ignore
        debug(JSON.stringify({
          service: service,
          stateParam: stateParam,
          currentStoredState: currentStoredState,
          appleReturnURL: appleReturnURL,
          fbGoogReturnURL: fbGoogReturnURL,
        }, null, 2));
        console.groupEnd();
      }

      setCookie("authAttemptViaKey", service);

      // We set a brand new state param if state values aren't available on the URL, or the query state values don't match,
      if (!query?.state || !currentStoredState || currentStoredState !== query?.state) {
        // debug('service_auth_click | New state param set')
        setCookie("oauthStateStorageKey", stateParam);
      }

      const SERVICE_BASE_URLS = {
        facebook: "https://www.facebook.com/v7.0/dialog/oauth",
        apple: "https://appleid.apple.com/auth/authorize",
        google: "https://accounts.google.com/o/oauth2/v2/auth",
      };
      const PARAM_VALUES = {
        facebook: {
          client_id: "132621393503550", // todo: Game.social.facebook.app_id
          redirect_uri: fbGoogReturnURL,
          state: stateParam,
          response_type: "token",
          scope: "email",
        },
        apple: {
          client_id: "com.ncaa.mml",
          redirect_uri: appleReturnURL,
          nonce: stateParam,
          response_type: "id_token code",
          scope: "name email",
          response_mode: "form_post",
        },
        google: {
          client_id: "192423378773.apps.googleusercontent.com", // todo: Game.social.google.client_id
          redirect_uri: fbGoogReturnURL,
          nonce: stateParam,
          response_type: "id_token token",
          scope: "email profile",
        },
      };
      const baseUrl = SERVICE_BASE_URLS[service];
      const paramString = new URLSearchParams(PARAM_VALUES[service]).toString();
      const serviceUrl = `${baseUrl}?${paramString}`;

      if (isNative) {
        return fireAction({
          action: `init_${service}_login`,
          extLinkUrl: serviceUrl,
          returnUrl: `${origin}`,
        });
      } else {
        return new Promise<void>((resolve) => {
          if (typeof window !== "undefined") {
            setTimeout(() => {
              window.top.location.replace(serviceUrl);
              resolve();
            }, 1000);
          }
        });
      }
    },
    [getCookie, setCookie, isNative, fireAction, isIframe],
  );

  const checkIdentityTypes = (query) => {
    const access_token = query.access_token?.toString() ?? "";
    const id_token = query.id_token?.toString() ?? "";
    const jwtObj = id_token && jwt_decode<OauthJwtValues>(id_token);

    // inspect the issuer value on the jwt to determine which service is making this auth attempt.
    const service = jwtObj?.iss?.includes("google.com")
      ? "google"
      : jwtObj?.iss?.includes("apple.com")
      ? "apple"
      : access_token
      ? "facebook"
      : "";
    const identityType = (service.toUpperCase() + (service === "facebook" ? "_MOBILE" : "")) as TDaltonIdentityTypes;

    const credentials = {
      facebook: `132621393503550|${access_token}`, // facebook app_id + "|" + access_token
      google: `192423378773.apps.googleusercontent.com|${id_token}`, // google client_id + '|' + id_token
      apple: id_token,
    };

    const credential = credentials[service];

    return daltonGetIdentityTypes(credential, identityType);
  };

  const socialSignInWithoutDaltonEmail = (query, arkoseToken) => {
    const id_token = query.id_token?.toString() ?? "";
    const code = query.code?.toString() ?? "";
    const jwtObj = id_token && jwt_decode<OauthJwtValues>(id_token);
    const email = jwtObj?.email ?? "";
    // Facebook values (fb does not use a JWT)
    const access_token = query.access_token?.toString() ?? "";

    // inspect the issuer value on the jwt to determine which service is making this auth attempt.
    const service = jwtObj?.iss?.includes("google.com")
      ? "google"
      : jwtObj?.iss?.includes("apple.com")
      ? "apple"
      : access_token
      ? "facebook"
      : "";

    const credentials = {
      facebook: `132621393503550|${access_token}`, // facebook app_id + "|" + access_token
      google: `192423378773.apps.googleusercontent.com|${id_token}`, // google client_id + '|' + id_token
      apple: id_token,
    };

    const identityType = (service.toUpperCase() + (service === "facebook" ? "_MOBILE" : "")) as TDaltonIdentityTypes;
    const credential = credentials[service];

    daltonSignIn(service === "apple" ? code : "", credential, identityType, arkoseToken).then(async (res) => {
      // 3. Check if we need to merge a prior successful auth attempt
      storeDaltonResponse(res);
      await checkAuthMerge(res);
      await pushEventData({
        eventName: "login_challenge_complete",
        eventAction: service,
        eventTarget: "login_complete",
        eventType: "play",
      });
      // If using the facebook service we can prefill their UpdateProfile fields back making a fb graph api call
      if (service === "facebook") {
        await fetch(`https://graph.facebook.com/me?fields=name,email&access_token=${access_token}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then(async (response) => {
            if (response.email) {
              await daltonAddEmailToProfile(res, response.email);
            }
          })
          .catch(function (e) {
            console.error(e);
            console.error("Error Fetching Facebook (Graph) Profile Info");
          });
      } else {
        if (email) {
          await daltonAddEmailToProfile(res, email);
        }
      }
      await triggerSync(res);
      await router.replace(`/${isIframe ? "?redirect=iframe" : ""}`, undefined, { shallow: true });
      await handleGameRedirect(getCookie("selectedGame"));
      await removeCookie("selectedGame");
      await removeCookie("joinGroupInfo");
    });
  };

  const socialAuthHandleSubmit = useCallback(
    (
      query: BaseRouter["query"],
      arkoseToken,
      status,
      userIdentities,
      setArkosePublicKey?,
      setArkoseComplete?,
      setNeedsEmailAddedToProfile?,
    ) => {
      const storedState = getCookie("oauthStateStorageKey") ?? "";
      const id_token = query.id_token?.toString() ?? "";
      const code = query.code?.toString() ?? "";
      const jwtObj = id_token && jwt_decode<OauthJwtValues>(id_token);
      const name = jwtObj?.name ?? "";
      const given_name = jwtObj?.given_name ?? "";
      const email = jwtObj?.email ?? "";
      const nonce = jwtObj?.nonce ?? "";
      // Facebook values (fb does not use a JWT)
      const access_token = query.access_token?.toString() ?? "";
      const state = query.state ?? "";

      // inspect the issuer value on the jwt to determine which service is making this auth attempt.
      const service = jwtObj?.iss?.includes("google.com")
        ? "google"
        : jwtObj?.iss?.includes("apple.com")
        ? "apple"
        : access_token
        ? "facebook"
        : "";

      const credentials = {
        facebook: `132621393503550|${access_token}`, // facebook app_id + "|" + access_token
        google: `192423378773.apps.googleusercontent.com|${id_token}`, // google client_id + '|' + id_token
        apple: id_token,
      };
      const identityType = (service.toUpperCase() + (service === "facebook" ? "_MOBILE" : "")) as TDaltonIdentityTypes;
      const credential = credentials[service];

      if (APP_ENV !== PROD) {
        console.groupCollapsed("🔑 Social Auth Initiated");
        // prettier-ignore
        debug('socialAuthHandleSubmit JWT Values \n', JSON.stringify({
          service,
          credential,
          name,
          given_name,
          email,
          nonce,
          code,
          id_token,
          storedState,
          state,
          access_token,
          jwtObj,
          identityType,
        }, null, 2))
        console.groupEnd();
      }

      dispatch((state) => {
        state.dalton.identityType = identityType;
        state.dalton.credential = credential;
        state.isLoading = true;
      });
      setCookie("authAttemptViaKey", service);

      const sleep = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms));

      return sleep(800)
        .then(function socialAuthRequest() {
          // if response status is 424 email associated with the social JWT isn't in the system yet, the user should now start registering
          if (status === 424) {
            daltonIdentity({ credential, identityType, arkoseToken })
              .then(async (res: any) => {
                // Update our profile data state before switching to the UpdateProfile view.
                // If using the facebook service we can prefill their UpdateProfile fields back making a fb graph api call
                if (service === "facebook") {
                  await fetch(`https://graph.facebook.com/me?fields=name,email&access_token=${access_token}`, {
                    method: "GET",
                  })
                    .then((response) => response.json())
                    .then((response) => {
                      dispatch((state) => {
                        state.dalton.wipEmail = response.email;
                        state.dalton.profile = {
                          email: response.email ?? "",
                          firstName: response.name ?? "",
                          token: res,
                          addressId: null,
                          zipCode: null,
                          currentSeasonPlayer: true,
                        };
                      });
                    })
                    .catch(function (e) {
                      console.error(e);
                      console.error("Error Fetching Facebook (Graph) Profile Info");
                    });
                } else {
                  dispatch((state) => {
                    state.dalton.wipEmail = email;
                    state.dalton.profile = {
                      email,
                      firstName: given_name,
                      token: res,
                      addressId: null,
                      zipCode: null,
                      currentSeasonPlayer: true,
                    };
                  });
                }

                pushEventData({
                  eventName: "register_challenge_complete",
                  eventAction: service,
                  eventTarget: "register_complete",
                  eventType: "play",
                });
                try {
                  window.trackMetrics({
                    type: "account-registration-complete",
                    data: {},
                  });
                } catch (e) {} // eslint-disable-line

                // Proceed to Update Profile view where they'll sync and login for the first time.
                dispatch((state) => {
                  state.currentAuthScreen = "UPDATE_PROFILE";
                  state.dalton.isSocialReg = true;
                });
              })
              .catch((e) => {
                if (e.status === 429) {
                  setNeedsEmailAddedToProfile(true);
                  setArkosePublicKey(ARKOSE_LOGIN_KEY);
                  setArkoseComplete(false);
                }
              });
          }
          // If response (HTTP 222) contains the social provider being passed, start login flow by calling login
          if (status === 222) {
            if (!userIdentities.includes(service.toUpperCase())) {
              setCookie(
                "daltonPendingAuthMerge",
                JSON.stringify({
                  identityType:
                    getCookie("authAttemptViaKey").toUpperCase() +
                    (getCookie("authAttemptViaKey") == "facebook" ? "_MOBILE" : ""),
                  credential: credential,
                }),
              );

              if (service === "facebook") {
                fetch(`https://graph.facebook.com/me?fields=name,email&access_token=${access_token}`, {
                  method: "GET",
                })
                  .then((response) => response.json())
                  .then((response) => {
                    dispatch((state) => {
                      state.currentAuthScreen = "AUTH_MERGE";
                      state.dalton.isSocialReg = true;
                      state.dalton.identityType = userIdentities;
                      state.dalton.wipEmail = response.email;
                    });
                  })
                  .catch(function (e) {
                    console.error(e);
                    console.error("Error Fetching Facebook (Graph) Profile Info");
                  });
              } else {
                // Proceed to Update Profile view where they'll sync and login for the first time.
                dispatch((state) => {
                  state.currentAuthScreen = "AUTH_MERGE";
                  state.dalton.isSocialReg = true;
                  state.dalton.identityType = userIdentities;
                  state.dalton.wipEmail = email;
                });
              }
            } else {
              return daltonSignIn(service === "apple" ? code : "", credential, identityType, arkoseToken).then(
                async (res) => {
                  // 3. Check if we need to merge a prior successful auth attempt
                  storeDaltonResponse(res);
                  await checkAuthMerge(res);
                  await pushEventData({
                    eventName: "login_challenge_complete",
                    eventAction: service,
                    eventTarget: "login_complete",
                    eventType: "play",
                  });
                  await triggerSync(res);
                  await router.replace(`/${isIframe ? "?redirect=iframe" : ""}`, undefined, { shallow: true });
                  await handleGameRedirect(getCookie("selectedGame"));
                  await removeCookie("selectedGame");
                  await removeCookie("joinGroupInfo");
                },
              );
            }
          }
        })
        .finally(() =>
          dispatch((state) => {
            state.isLoading = false;
          }),
        );
    },
    [
      getCookie,
      setCookie,
      dispatch,
      storeDaltonResponse,
      daltonIdentity,
      daltonSignIn,
      triggerSync,
      checkAuthMerge,
      pushEventData,
      handleGameRedirect,
      removeCookie,
    ],
  );

  //! Methods returned from this hook:
  return {
    signIn,
    signOut,
    signUp,
    updateProfile,
    getProfile,
    socialAuthRequest,
    socialAuthHandleSubmit,
    magicLinkHandleSubmit,
    daltonRequestMagicLink,
    daltonRequestPasswordToken,
    daltonGetIdentityTypes,
    daltonPasswordChange,
    resetPassword,
    daltonUserProfile,
    daltonSignIn,
    daltonUnsubscribe,
    daltonSubscribe,
    checkIdentityTypes,
    socialSignInWithoutDaltonEmail,
    ARKOSE_LOGIN_KEY,
    ARKOSE_REGISTRATION_PW_RESET_KEY,
  };
}

/**
 * Use this utility function to get the values we need from a full Dalton profile object.
 */
export function getDaltonValues(profile?: TDaltonProfile): DaltonProfileValues {
  const emailRes = profile?.userEmailResponses?.find((emailEntry) => emailEntry.primary === true);
  const addressRes = profile?.userAddressResponses?.sort(
    (a, b) => new Date(b.lastUpdateDate).getTime() - new Date(a.lastUpdateDate).getTime(),
  )?.[0];
  const firstName = profile?.firstName ?? "";
  const email = emailRes?.emailAddress || "";
  const currentSeasonPlayer = ["true", true].includes(
    profile?.userProfileResponses?.ncaa?.attributes?.[`bcg${SEASON}Player`] ?? "",
  );
  const addressId = addressRes?.id || "";

  return {
    firstName,
    email,
    currentSeasonPlayer,
    addressId,
  };
}
