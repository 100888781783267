import { Team, useTeamPrice } from "@hooks/useGame";
import useGlobalState, { SelectedPick } from "@hooks/useGlobalState";
import useAnalytics from "./useAnalytics";
import usePicksMutation, { Pick } from "./usePicksMutation";

// Hook for team selection on Marketplace
export function useMarketplace(teamId = null) {
  const { pushEventData } = useAnalytics();
  const [{ marketplace, lineup }, dispatch] = useGlobalState();
  const { getTeamPrice, getPotentialTeamPrice } = useTeamPrice();
  const teamPrice = Number(getTeamPrice(teamId));
  const pointsBank = Number(marketplace.bankBalance);
  const originalBankBalance = Number(marketplace.originalBankBalance);

  const currentEntryId = lineup.activeEntryId;

  const { mutateAsync: mutatePicks } = usePicksMutation();

  const getBankBalance = (addTeam = false) => {
    if (addTeam) {
      return (pointsBank - teamPrice).toString();
    }
    return (pointsBank + teamPrice).toString();
  };

  const addTeam = (isTradePreview = false) => {
    const selectedSlot = {
      slot_id: teamId,
      bankPercentage: getTeamBankPercentage(teamPrice, originalBankBalance),
    };

    const selectedPicks = isTradePreview ? marketplace.tradePreview.teamsToBuy : marketplace.selectedPicks;

    let pickHasBeenSelected = false;

    const updatedSelectedPics = selectedPicks.reduce((acc, slot) => {
      if (!slot.slot_id && !pickHasBeenSelected) {
        (slot = selectedSlot), (pickHasBeenSelected = true);
      }
      return acc.concat(slot);
    }, []);

    // if a user adds their first pick we push an analytics event
    if (selectedPicks.every((pick) => !pick.slot_id)) {
      pushEventData({
        eventName: "begin_selections",
        eventAction: "manual",
        eventTarget: "entry_start",
        eventType: "slc",
        challengeGameName: "slc",
      });
    }

    if (isTradePreview) {
      dispatch((state) => {
        state.marketplace.tradePreview.teamsToBuy = [...marketplace.tradePreview.teamsToBuy, selectedSlot];
        state.marketplace.bankBalance = getBankBalance(true);
        state.marketplace.tradePreview.buyTotalPoints = marketplace.tradePreview.buyTotalPoints + teamPrice;
      });
    } else {
      dispatch((state) => {
        state.marketplace.selectedPicks = [...updatedSelectedPics];
        state.marketplace.bankBalance = getBankBalance(true);
      });
    }
  };

  const removeTeam = (isTradePreview = false) => {
    const updatedSelectedPics = marketplace.selectedPicks.map((pick) => {
      if (pick.slot_id === teamId) {
        return {
          slot_id: null,
          bankPercentage: "",
        };
      }
      return {
        slot_id: pick.slot_id,
        bankPercentage: pick.bankPercentage,
      };
    });

    if (isTradePreview) {
      dispatch((state) => {
        state.marketplace.tradePreview.teamsToBuy = [
          ...marketplace.tradePreview.teamsToBuy.filter((pick) => pick.slot_id !== teamId),
        ];
        state.marketplace.bankBalance = getBankBalance();
        state.marketplace.tradePreview.buyTotalPoints = marketplace.tradePreview.buyTotalPoints - teamPrice;
      });
    } else {
      dispatch((state) => {
        state.marketplace.selectedPicks = [...updatedSelectedPics];
        state.marketplace.bankBalance = getBankBalance();
      });
    }
  };

  const getTeamBankPercentage = (price: string | number, bankBalance: string | number) => {
    return ((Number(price) / Number(bankBalance)) * 100).toString();
  };

  interface MarketPlaceTeams {
    eliminated: Team[];
    open: Team[];
  }
  const getMarketplaceTeams = (teams: Team[]) => {
    return teams?.reduce<MarketPlaceTeams>(
      (acc, team) => {
        const newTeam = {
          ...team,
          price: getTeamPrice(team.team_id),
          potentialPrice: getPotentialTeamPrice(team.team_id),
        };

        if (team.is_eliminated) {
          acc.eliminated.push(newTeam);
        } else {
          acc.open.push(newTeam);
        }
        return acc;
      },
      {
        eliminated: [],
        open: [],
      },
    );
  };

  const getPayloadForTradePreview = (
    teamsToSell: Team[],
    teamsToBuy: SelectedPick[],
    currentLineup: SelectedPick[],
  ) => {
    const lineupWithTeamsToBuy = [...teamsToBuy];

    const lineupWithSoldTeamsRemoved = currentLineup.map((currentTeam, index) => {
      const slot_id = index + 1;
      if (teamsToSell.find((soldTeam) => soldTeam.team_id === currentTeam.slot_id)) {
        return {
          slot_id,
          team_id: null,
        };
      }
      return {
        slot_id,
        team_id: currentTeam.slot_id,
      };
    });

    return lineupWithSoldTeamsRemoved.map((team, index) => {
      const slot_id = index + 1;

      if (!team.team_id && lineupWithTeamsToBuy.length) {
        const boughtTeam = lineupWithTeamsToBuy.splice(0, 1);
        return {
          slot_id,
          team_id: boughtTeam[0].slot_id,
        };
      }

      return {
        slot_id,
        team_id: team.team_id,
      };
    });
  };

  const tradeAdd = (team: Team, teamPrice: string) => {
    dispatch((state) => {
      state.marketplace.tradePreview.teamsToSell = [...marketplace.tradePreview.teamsToSell, team];
      state.marketplace.bankBalance = (Number(pointsBank) + Number(teamPrice)).toString();
      state.marketplace.tradePreview.sellTotalPoints =
        Number(marketplace.tradePreview.sellTotalPoints) + Number(teamPrice);
      state.marketplace.tradePreview.errorMessage = "";
    });
  };

  const tradeRemove = (team: Team, teamPrice: string) => {
    dispatch((state) => {
      state.marketplace.tradePreview.teamsToSell = marketplace.tradePreview.teamsToSell.filter(
        (item) => item.team_id !== team.team_id,
      );
      state.marketplace.bankBalance = (Number(pointsBank) - Number(teamPrice)).toString();
      state.marketplace.tradePreview.sellTotalPoints =
        Number(marketplace.tradePreview.sellTotalPoints) - Number(teamPrice);
      state.marketplace.tradePreview.errorMessage = "";
    });
  };

  const resetTradePreview = () => {
    dispatch((state) => {
      state.marketplace.tradePreview.teamsToBuy = [];
      state.marketplace.tradePreview.teamsToSell = [];
      state.marketplace.tradePreview.buyTotalPoints = 0;
      state.marketplace.tradePreview.sellTotalPoints = 0;
    });
  };

  const cancelTradePreview = (originalBankBalance) => {
    dispatch((state) => {
      state.marketplace.bankBalance = originalBankBalance;
    });
    resetTradePreview();
  };

  const cancelInitialPicks = (originalPicks, originalBankBalance, selectedPicks) => {
    dispatch((state) => {
      state.marketplace.selectedPicks = originalPicks.length
        ? originalPicks
        : selectedPicks.map(() => {
            return { slot_id: null, bankPercentage: "" };
          });
      state.marketplace.bankBalance = originalBankBalance;
    });
    resetTradePreview();
  };

  const savePicks = (showTradeView?: boolean) => {
    const entryId = currentEntryId;
    const picksType = showTradeView ? marketplace.tradePreview.selectedPicks : marketplace.selectedPicks;

    const payload = picksType.reduce<Pick[]>((acc, selectedPick, index) => {
      const pick: Pick = {
        slot_id: index + 1,
        team_id: selectedPick.slot_id,
      };
      return acc.concat(pick);
    }, []);
    return mutatePicks({ entryId, payload });
  };

  const saveTradePreview = () => {
    const entryId = currentEntryId;
    const payload = getPayloadForTradePreview(
      marketplace.tradePreview.teamsToSell,
      marketplace.tradePreview.teamsToBuy,
      marketplace.tradePreview.selectedPicks,
    );
    return mutatePicks({ entryId, payload });
  };

  return {
    addTeam,
    removeTeam,
    getTeamBankPercentage,
    getMarketplaceTeams,
    getPayloadForTradePreview,
    resetTradePreview,
    tradeAdd,
    tradeRemove,
    savePicks,
    saveTradePreview,
    cancelTradePreview,
    cancelInitialPicks,
  };
}
