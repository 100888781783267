"use client";
import Whistle from "@icons/whistle.svg";
import useGlobalState from "@hooks/useGlobalState";
import styled from "styled-components";
import { colors, pxToRem } from "@styles/styleUtils";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import { MMLButton } from "@sportstech/f2p-ncaa-component-library";

const MMLModalPaddingWrapper = styled.div`
  position: relative;
  // Helps overwrite modal styles without passing css prop
  .modal-content-wrapper {
    padding-bottom: 24px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
`;

const StyledWhistle = styled(Whistle)`
  margin: 0 auto;
  margin-bottom: 16px;
`;

const Title = styled.p`
  letter-spacing: -0.3px;
  font-weight: 900;
  font-size: ${pxToRem(16)};
  letter-spacing: -0.37px;
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
  max-width: 207px;
  margin: 0 auto;
  margin-bottom: 5px;
`;

const Description = styled.p`
  font-size: ${pxToRem(14)};
  color: ${colors["lvl_-2"]};
  text-align: center;
  max-width: 207px;
  margin: 0 auto;
`;

const StyledMMLButton = styled(MMLButton)`
  margin-top: 16px;
  width: 100%;
`;

type GameNotLockedModalProps = {
  isLoggedIn?: boolean;
  handleDashboardRedirect: () => void;
};

const GameNotLockedModal = ({ handleDashboardRedirect: handleLoginRedirect }: GameNotLockedModalProps) => {
  const [state, dispatch] = useGlobalState();

  const closeModal = () => {
    dispatch((state) => {
      state.modal.openModal = null;
    });
  };

  return (
    <MMLModalPaddingWrapper>
      <MMLModal
        handleClose={closeModal}
        isOpen={state.modal.openModal === "GAME_NOT_LOCKED"}
        showCloseIcon={false}
        maxWidth={303}
      >
        <StyledWhistle />
        <Title>Game Isn&apos;t Locked Yet</Title>
        <Description>
          You can view other players&apos; lineups after the game locks on March 16 at 12:00pm ET.
        </Description>
        <StyledMMLButton size="sm" $primary onClick={handleLoginRedirect}>
          Return to My Lineups
        </StyledMMLButton>
      </MMLModal>
    </MMLModalPaddingWrapper>
  );
};

export default GameNotLockedModal;
