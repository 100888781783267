import useBcgGame, { GamesAbbrv } from "@hooks/bcg/useBcgGame";
import useGlobalState from "@hooks/useGlobalState";
import { Button } from "@styles/buttons.styled";
import { colors, pxToRem } from "@styles/styleUtils";
import useAuth from "@hooks/useAuth";
import React from "react";
import styled from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";

const SubTitle = styled.span`
  display: block;
  color: ${colors["lvl_-2"]};
  font-size: ${pxToRem(12)};
  margin-bottom: 2rem;
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const Explanation = styled.p<{ hideDetails: boolean }>`
  text-align: center;
  font-size: ${pxToRem(14)};
  margin-bottom: 20px;
  margin-bottom: ${({ hideDetails }) => (hideDetails ? "0" : "20px")};
`;

const TutorialVideo = styled.video`
  background: var(--color_lvl_1);
  border-radius: 8px;
  margin: 0 0 25px 0;
  width: 100%;
`;

export default function HowToPlayModal(props: {
  handleCTAClick: (arg0: GamesAbbrv, arg1: boolean) => void;
  hideDetails?: boolean;
}) {
  const { handleCTAClick, hideDetails } = props;
  const [state, dispatch] = useGlobalState();
  const { data: game } = useBcgGame();
  const { isEntryNeeded } = useAuth();

  const gameId = state.modal.options.selectedGame;

  const gameDetails = {
    explanation: "",
    fullName: "",
    poster: "",
    videoUrlMp4: "",
  };

  const isBcgClosed = !(game?.play_dashboard?.bcg_open ?? false);
  const isWbcgClosed = !(game?.play_dashboard?.wbcg_open ?? false);
  const isSlcClosed = !(game?.play_dashboard?.slc_open ?? false);
  const isCtpClosed = !(game?.play_dashboard?.mctp_open ?? false);
  const isWctpClosed = !(game?.play_dashboard?.wctp_open ?? false);

  const bcgButtonText = isBcgClosed ? "Closed" : "Play";
  const wbcgButtonText = isWbcgClosed ? "Closed" : "Play";
  const slcButtonText = isSlcClosed ? "Closed" : "Play";
  const ctpButtonText = isCtpClosed ? "Closed" : "Play";
  const wctpButtonText = isWctpClosed ? "Closed" : "Play";

  const isGameDisabled = () => {
    switch (gameId) {
      case "bcg":
        return isBcgClosed;
      case "wbcg":
        return isWbcgClosed;
      case "slc":
        return isSlcClosed;
      case "mctp":
        return isCtpClosed;
      case "wctp":
        return isWctpClosed;
    }
  };

  const buttonText = () => {
    switch (gameId) {
      case "bcg":
        return bcgButtonText;
      case "wbcg":
        return wbcgButtonText;
      case "slc":
        return slcButtonText;
      case "mctp":
        return ctpButtonText;
      case "wctp":
        return wctpButtonText;
    }
  };

  switch (gameId) {
    case "bcg":
      gameDetails.explanation =
        "Fill out your bracket for March Madness by picking who you think will win every one of the 63 NCAA tournament games.";
      gameDetails.fullName = "Men’s Bracket Challenge";
      gameDetails.videoUrlMp4 = "https://play.ncaa.com/uploads/how-to-play/mbcg-2024-v2.mp4";
      gameDetails.poster = "/assets/images/posters/2023_MBCG.png";
      break;
    case "wbcg":
      gameDetails.explanation =
        "Fill out your bracket for March Madness by picking who you think will win every one of the 63 NCAA tournament games.";
      gameDetails.fullName = "Women’s Bracket Challenge";
      gameDetails.videoUrlMp4 = "https://play.ncaa.com/uploads/how-to-play/wbcg-2024-v2.mp4";
      gameDetails.poster = "/assets/images/posters/2023_WBCG.png";
      break;
    case "slc":
      gameDetails.explanation =
        "Draft a lineup of teams after Selection Sunday, then trade them throughout the tournament as their values change with every win and loss.";
      gameDetails.fullName = "Men’s Tournament Run";
      gameDetails.videoUrlMp4 = "https://play.ncaa.com/uploads/how-to-play/tr-2024.mp4";
      gameDetails.poster = "/assets/images/posters/2023_MCTR.png";
      break;
    case "mctp":
      gameDetails.explanation =
        "Pick the winners of all 32 DI Conference Tournaments. Choose from Standard & Confidence modes and compete with friends.";
      gameDetails.fullName = "Men’s Conference Tournament Pick’Em";
      gameDetails.videoUrlMp4 = "/assets/videos/2023_Mens_PickEm.mp4";
      gameDetails.poster = "/assets/images/posters/2023_MCTP.png";
      break;
    case "wctp":
      gameDetails.explanation =
        "Pick the winners of all 32 DI Conference Tournaments. Choose from Standard & Confidence modes and compete with friends.";
      gameDetails.fullName = "Women’s Conference Tournament Pick’Em";
      gameDetails.videoUrlMp4 = "/assets/videos/2023_Womens_PickEm.mp4";
      gameDetails.poster = "/assets/images/posters/2023_WCTP.png";
      break;
  }

  return (
    <MMLModal
      maxWidth={450}
      isOpen={state.modal.openModal === "HOW_TO_PLAY"}
      title="How To Play"
      handleClose={() => {
        dispatch((state) => {
          state.modal.openModal = null;
          state.modal.options.entryType = null;
        });
      }}
    >
      <SubTitle>
        The Official Game of NCAA® March Madness® <br />
        {gameDetails.fullName}
      </SubTitle>
      <TutorialVideo controls playsInline controlsList="nodownload" poster={gameDetails.poster}>
        <source src={gameDetails.videoUrlMp4} type="video/mp4" />
        Your browser does not support embedded videos.
      </TutorialVideo>
      <Explanation hideDetails={hideDetails}>{gameDetails.explanation}</Explanation>
      {!hideDetails ? (
        <StyledButton
          size="md"
          $primary
          disabled={isGameDisabled()}
          onClick={() => handleCTAClick(gameId, isEntryNeeded(gameId))}
        >
          {buttonText()}
        </StyledButton>
      ) : null}
    </MMLModal>
  );
}
