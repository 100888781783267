import { GamesAbbrv } from "@hooks/bcg/useBcgGame";
import useAnalytics from "@hooks/useAnalytics";
import useCreateEntryMutation from "@hooks/useCreateEntryMutation";
import useGlobalState from "@hooks/useGlobalState";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { gameUrls } from "src/lib/url-helpers";
import useBundle from "./useBundle";
import { useCreateLineupMutation } from "./useLineupMutation";
import { BASE_PATH } from "src/settings";
import useCookie from "./useCookie";

export default function useAuth() {
  const [, dispatch] = useGlobalState();
  const router = useRouter();
  const { mutateAsync: createEntry } = useCreateEntryMutation();
  const { mutateAsync: createLineup } = useCreateLineupMutation();
  const { pushEventData } = useAnalytics();
  const { data: bundle } = useBundle();
  const { query } = useRouter();
  const { getCookie } = useCookie();
  const joinGroupInfoCookie = getCookie("joinGroupInfo") ? JSON.parse(getCookie("joinGroupInfo")) : undefined;

  const handleGameRedirect = useCallback(
    async (gameId) => {
      // Handle redirects to specific game from group join after login
      if (query.fromMbcgGroup || joinGroupInfoCookie?.fromMbcgGroup) {
        router.push({
          pathname: `/mens-bracket-challenge/group/${query.fromMbcgGroup || joinGroupInfoCookie?.fromMbcgGroup}`,
          query: {
            joinGroup: query.fromMbcgModalSignIn ? false : true,
            joinToken: query.joinToken || joinGroupInfoCookie?.joinToken,
          },
        });
      } else if (query.fromWbcgGroup || joinGroupInfoCookie?.fromWbcgGroup) {
        router.push({
          pathname: `/womens-bracket-challenge/group/${query.fromWbcgGroup || joinGroupInfoCookie?.fromWbcgGroup}`,
          query: {
            joinGroup: query.fromWbcgModalSignIn ? false : true,
            joinToken: query.joinToken || joinGroupInfoCookie?.joinToken,
          },
        });
      } else if (query.fromMctpGroup || joinGroupInfoCookie?.fromMctpGroup) {
        router.push({
          pathname: `/mens-conference-tournament-challenge/group/${
            query.fromMctpGroup || joinGroupInfoCookie?.fromMctpGroup
          }`,
          query: {
            joinGroup: query.fromMctpModalSignIn ? false : true,
            joinToken: query.joinToken || joinGroupInfoCookie?.joinToken,
          },
        });
      } else if (query.fromWctpGroup || joinGroupInfoCookie?.fromWctpGroup) {
        router.push({
          pathname: `/womens-conference-tournament-challenge/group/${
            query.fromWctpGroup || joinGroupInfoCookie?.fromWctpGroup
          }`,
          query: {
            joinGroup: query.fromWctpModalSignIn ? false : true,
            joinToken: query.joinToken || joinGroupInfoCookie?.joinToken,
          },
        });
      } else if (query.fromGroupInvite || joinGroupInfoCookie?.fromGroupInvite) {
        router.push({
          pathname: `${BASE_PATH}/group/${query?.groupId || joinGroupInfoCookie?.groupId}`,
          query: {
            fromGroupInvite: true,
            id: query.groupId || joinGroupInfoCookie?.groupId,
            type: query.type || joinGroupInfoCookie?.type,
            joinToken: query.joinToken || joinGroupInfoCookie?.joinToken,
            ...router.query,
          },
        });
      } else {
        const queryString = window.location.search;
        let overwrittenGameId: string = gameId;
        overwrittenGameId = overwrittenGameId + "Path";
        const url = `${gameUrls[overwrittenGameId]}/dashboard${queryString}`;
        router.push(url);
      }
    },
    [query, router, joinGroupInfoCookie],
  );

  async function autoCreateEntry(entryType: GamesAbbrv) {
    function trackEntryEvent() {
      const eventType = entryType === "bcg" ? "mbcg" : entryType;
      const eventName = entryType === "slc" ? "create_lineup" : "create_bracket";
      return pushEventData({
        eventName,
        eventType,
        challengeGameName: eventType,
        eventAction: "create_bracket", // the create_bracket action label is intentional across all games.
        eventTarget: "create",
      });
    }

    // Cannot auto create an entry for CTP. Redirect to dashboard so user can select game mode
    if (entryType.includes("ctp")) {
      router.push("/dashboard");
      dispatch((state) => {
        state.modal.options.entryType = entryType;
      });
    } else if (entryType === "slc") {
      setTimeout(() => {
        // Delay entry creation to prevent 500's from the backend
        createLineup({ entryType }).then(() => {
          trackEntryEvent();
          handleGameRedirect(entryType);
        });
      }, 250);
    } else {
      // Delay entry creation to prevent 500's from the backend
      setTimeout(() => {
        createEntry({ entryType }).then(() => {
          trackEntryEvent();
          handleGameRedirect(entryType);
        });
      }, 250);
    }
  }

  function isEntryNeeded(gameId: GamesAbbrv) {
    switch (gameId) {
      case "bcg":
        return bundle?.mbcg_entries?.length === 0;
      case "wbcg":
        return bundle?.wbcg_entries?.length === 0;
      case "slc":
        return bundle?.mslc_entries?.length === 0;
      case "mctp":
        return bundle?.mctp_entries?.length === 0;
      case "wctp":
        return bundle?.wctp_entries?.length === 0;
    }
  }

  return {
    autoCreateEntry,
    handleGameRedirect,
    isEntryNeeded,
  };
}
