import Cookies from "js-cookie";
import settings from "src/settings";

const {
  APP_ENV,
  SEASON,
  ENV_NAMES,
  ENV_NAMES: { QA },
} = settings;

const buildEnv = (Object.values(ENV_NAMES) as string[]).includes(APP_ENV) ? APP_ENV : QA; // ideally this would be replaced by simply the APP_ENV, but BCG has a ton of logic built around Build Env and uses that value for cookie prefixes.

/** _stibg-q_2021ncqa */
const CacheKeyPrefix = `_stibg-${buildEnv[0]}_${SEASON}nc${buildEnv.slice(0, 2)}`;

export const apiAuthTokenCookieName = `${CacheKeyPrefix}_sAAT`;
export const nativeAppAuthTokenCookieName = `bcg-authorization-token`;
export const daltonTokenCookieName = `${CacheKeyPrefix}_dtnToken`;
// on identityTypes 222 where the identityType is not returned - write out a cookie with the current request as "daltonMergeRequest".
export const daltonPendingAuthMerge = `daltonPendingAuthMerge`;
export const staticEndpointKeyCookieName = `${CacheKeyPrefix}_sEK`;
export const bcgIdCookieName = `${CacheKeyPrefix}_bcgId`;
export const oauthStateStorageKey = `${CacheKeyPrefix}_oassk`;
export const authAttemptViaKey = `${CacheKeyPrefix}_auth_attempt_via`;
export const hasSportsTechIncAccountCookieName = `${CacheKeyPrefix}_stia`;
export const nativeAppSegmentIdCookieName = `segment-id`;
export const bcgNativeDeviceType = `bcg-native-device-type`;
// set "selectedGame" as a value so we can later change it's value and not have eslint yell at us
export const selectedGame = "selectedGame";
// set "groupJoin" as a value so we can keep track of a signed out user trying to join a group
export const joinGroupInfo = "joinGroupInfo";

const cookieNames = {
  apiAuthTokenCookieName,
  nativeAppAuthTokenCookieName,
  nativeAppSegmentIdCookieName,
  daltonTokenCookieName,
  daltonPendingAuthMerge,
  staticEndpointKeyCookieName,
  bcgIdCookieName,
  oauthStateStorageKey,
  authAttemptViaKey,
  bcgNativeDeviceType,
  selectedGame,
  joinGroupInfo,
  legacy__hasSportsTechIncAccountCookie: hasSportsTechIncAccountCookieName, // legacy cookie only used for bcg codebase auth flow (/bracketgames).
};

const defaultCookieOptions: Cookies.CookieAttributes = { expires: 365, path: "/", domain: ".ncaa.com" };

/**
 * Hook to interface with the app's typed set of cookies.
 * @example
 * const { getCookie } = useCookie()
 */
const useCookie = () => {
  function setCookie(cookieName: keyof typeof cookieNames, value: string | object, options = defaultCookieOptions) {
    return Cookies.set(cookieNames[cookieName], value, options);
  }
  function getCookie(cookieName: keyof typeof cookieNames): string | undefined {
    return Cookies.get(cookieNames[cookieName]);
  }
  function removeCookie(cookieName: keyof typeof cookieNames) {
    return Cookies.remove(cookieNames[cookieName]);
  }
  function removeAllCookies() {
    return Object.keys(Cookies.get()).forEach((cookieName) => {
      if (cookieName !== "bcg-native-device-type") Cookies.remove(cookieName, defaultCookieOptions);
    });
  }

  return { setCookie, getCookie, removeCookie, removeAllCookies };
};

export default useCookie;
