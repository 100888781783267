import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --color_lvl_0: #8f909b;
    --color_lvl_1: #a8a9b3;
    --color_lvl_-1: #767783;
    --color_lvl_2: #c1c2ca;
    --color_lvl_-2: #5d5f6b;
    --color_lvl_-3: #464750;
    --color_lvl_3: #dfdfe7;
    --color_lvl_-4: #23232a;
    --color_lvl_4: #f4f4fa;
    --color_lvl_-5: #17171d;
    --color_lvl_5: #fdfdfd;
    --color_slc_accent: #009ADE;
    --color_black: #17171d;
    --color_primaryaccent: #0079c2;
    --color_secondaryaccent: #6cd2ff;
    --color_bcg_accent1: #005288;
    --color_bcg_accent1_alt: #004370;
    --color_bcg_accent2: #f08f0d;
    --color_bcg_accent2_hover: #f5a53d;
    --color_bcg_accent3: #00a566;
    --color_live_error: #e41e0c;
    --color_live_error_hover: #f43625;
    --color_lvl_0a: 143, 144, 155;
    --color_lvl_1a: 168, 169, 179;
    --color_lvl_-1a: 118, 119, 131;
    --color_lvl_2a: 193, 194, 202;
    --color_lvl_-2a: 93, 95, 107;
    --color_lvl_-3a: 70, 71, 80;
    --color_lvl_3a: 223, 223, 231;
    --color_lvl_-4a: 35, 35, 42;
    --color_lvl_4a: 244, 244, 250;
    --color_lvl_-5a: 23, 23, 29;
    --color_lvl_5a: 253, 253, 253;
    --solidBgAlpha: 255, 255, 255;
    --solidAlpha: 0, 0, 0;
  }
  
  [data-theme="dark"] {
    --color_lvl_1: #767783;
    --color_lvl_-1: #a8a9b3;
    --color_lvl_2: #5d5f6b;
    --color_lvl_-2: #c1c2ca;
    --color_lvl_3: #464750;
    --color_lvl_-3: #dfdfe7;
    --color_lvl_4: #23232a;
    --color_lvl_-4: #f4f4fa;
    --color_lvl_5: #17171d;
    --color_lvl_-5: #fdfdfd;
    --color_slc_accent: #009ADE;
    --color_black: #17171d;
    --color_primaryaccent: #1da1f1;
    --color_bcg_accent1: #0084db;
    --color_live_error: #f43625;
    --color_lvl_1a: 118, 119, 131;
    --color_lvl_-1a: 168, 169, 179;
    --color_lvl_2a: 93, 95, 107;
    --color_lvl_-2a: 193, 194, 202;
    --color_lvl_3a: 70, 71, 80;
    --color_lvl_-3a: 223, 223, 231;
    --color_lvl_4a: 35, 35, 42;
    --color_lvl_-4a: 244, 244, 250;
    --color_lvl_5a: 23, 23, 29;
    --color_lvl_-5a: 253, 253, 253;
    --solidBgAlpha: 0, 0, 0;
    --solidAlpha: 255, 255, 255;
  }
`;

export default GlobalStyles;
