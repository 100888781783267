export const gameUrls = {
  development: "http://play-development.ncaa.com:8000",
  qa: "https://play-qa.ncaa.com",
  rehearsal: "https://play-rehearsal.ncaa.com",
  staging: "https://play-staging.ncaa.com",
  postlock: "https://play-postlock.ncaa.com",
  ss: "https://play-ss.ncaa.com",
  production: "https://play.ncaa.com",
  slcPath: "/mens-tournament-run",
  bcgPath: "/mens-bracket-challenge",
  wbcgPath: "/womens-bracket-challenge",
  mctpPath: "/mens-conference-tournament-challenge",
  wctpPath: "/womens-conference-tournament-challenge",
};

/**
 * Generate a random alpha-numeric string of x length. Random characters are uses for a social login nonce values in provider url params.
 */
export const createNonce = (stringLength: number) => {
  let result = "";
  for (; result.length < stringLength; result += Math.random().toString(36).slice(2));
  return result.slice(0, stringLength).toUpperCase();
};
