import useGame, { getMarketplaceGameStates, getSelectedPicksForCurrentRound } from "@hooks/useGame";
import { Session } from "@hooks/useSession";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useGlobalState from "src/hooks/useGlobalState";
import useApiFetch, { dynamicSlcApiEndpoint } from "./useApiFetch";
import { SLCEntry } from "./useBundle";

interface MarketplacePickArgs {
  entryId: number;
  payload: Pick[];
}
export interface Pick {
  slot_id: number;
  team_id: number | null;
}

export default function usePicksMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();
  const [, dispatch] = useGlobalState();
  const { data: game } = useGame();
  const gameStates = getMarketplaceGameStates(game);

  function updateEntry({ entryId, payload }: MarketplacePickArgs) {
    return fetchApiEndpoint<SLCEntry>(
      `${dynamicSlcApiEndpoint}/entries/${entryId}/picks.json`,
      {
        method: "PUT",
        body: JSON.stringify({
          picks: [...payload],
        }),
      },
      { applyGameErrorMessages: true },
    );
  }

  return useMutation<SLCEntry, Response, MarketplacePickArgs, { prevSession: Session }>({
    mutationFn: updateEntry,
    onMutate: () => {
      const prevSession = queryClient.getQueryData<Session>(["session"]);
      return { prevSession };
    },
    onSuccess: (response, variables) => {
      const entryId = variables.entryId;

      queryClient.invalidateQueries({
        queryKey: ["bundle"],
      });
      queryClient.setQueryData(["session"], (prevSession: Session) => {
        const newSession = structuredClone(prevSession);
        newSession.entries = newSession.entries.map((entry) => (entry.entry_id === entryId ? response : entry));
        return newSession;
      });

      // Submitting a trade
      if (gameStates.showTradeView) {
        const currentRound = game?.state?.current_round?.round_id ?? null;
        dispatch((state) => {
          state.marketplace.tradePreview.selectedPicks = getSelectedPicksForCurrentRound(response.picks, currentRound);
          state.marketplace.bankBalance = response.bank_balance;
        });
      }
    },
    onError: (error, vars, context) => {
      queryClient.setQueryData(["session"], context.prevSession);
    },
  });
}
