import useAnalytics from "@hooks/useAnalytics";
import useCreateEntryMutation from "@hooks/useCreateEntryMutation";
import useGlobalState from "@hooks/useGlobalState";
import { Button } from "@styles/buttons.styled";
import { colors, media, pxToRem } from "@styles/styleUtils";
import { useRouter } from "next/router";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import useAuth from "@hooks/useAuth";

const Description = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  > span {
    color: ${colors["lvl_-2"]};
    font-size: ${pxToRem(12)};
    font-weight: 325;
    margin-bottom: ${pxToRem(16)};
    letter-spacing: 0.4px;
    line-height: 1.3;
    text-align: center;
  }
`;

const Form = styled.form`
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.svp} {
    min-height: 50vh;
  }
`;

const GameModeRadioInput = styled.label`
  > input[type="radio"] {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1;
    width: 1;
    margin: -1;
    padding: 0;
    border: 0;
  }
  .peer-input:checked ~ .entry-option {
    border: 1px solid ${colors["lvl_-5"]};
  }
  > .entry-option {
    border: 1px solid ${colors.lvl_2};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 200px;
    margin-bottom: 8px;
    padding: 16px;
    text-align: left;

    &:hover,
    &:focus-visible {
      background-color: ${colors["lvl_4"]};
    }

    h3 {
      font-size: ${pxToRem(12)};
      font-weight: bold;
      line-height: ${pxToRem(16)};
      letter-spacing: 0.4px;
    }

    ul {
      padding-top: 5px;
      padding-inline-start: 16px;
      color: ${colors["lvl_-1"]};
      font-size: ${pxToRem(12)};
      line-height: ${pxToRem(16)};
    }
    li {
      margin-top: ${pxToRem(10)};
    }
  }
`;

const ConfirmButton = styled(Button)`
  width: 100%;
`;

export default function CreateCtpEntry() {
  const [, dispatch] = useGlobalState();
  const [state] = useGlobalState();
  const { mutateAsync, reset, error, isPending: isCreatingEntry } = useCreateEntryMutation();
  const { updateDataLayer, pushEventData } = useAnalytics();
  const firstOptionRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const { handleGameRedirect } = useAuth();
  const { query } = router;
  const [createdEntry, setCreatedEntry] = useState<boolean>(false);

  const entryType = state.modal.options.selectedGame;
  const [selectedGameMode, setSelectedGameMode] = useState<"standard" | "confidence">("standard");

  const entrySubmission = {
    entryType,
    confidence_mode: selectedGameMode === "confidence" ? true : false,
  };

  const handleGameModeSelect = (e) => {
    const gameMode = e.target.value;
    if (["standard", "confidence"].includes(gameMode)) {
      setSelectedGameMode(gameMode);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setCreatedEntry(true);

    await mutateAsync(entrySubmission, {
      onSuccess: () => trackEntryEvent(),
      onError: () => {
        setCreatedEntry(false);
      },
    });

    const isFromGroupInvite = query?.fromGroupInvite || query?.fromMctpGroup || query?.fromWctpGroup;

    if (isFromGroupInvite && entryType && !error) {
      await router.push(state.modal.options.groupUrl);
    } else {
      handleGameRedirect(entryType);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
      const allInputs = document.querySelectorAll<HTMLInputElement>('input[type="radio"]');
      const currentIndex = Array.from(allInputs).findIndex((input) => input.value === selectedGameMode);
      let newIndex = event.key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;
      newIndex = newIndex < 0 ? allInputs.length - 1 : newIndex % allInputs.length;
      allInputs[newIndex]?.focus();
      setSelectedGameMode(allInputs[newIndex]?.value as "standard" | "confidence");
    }
  };

  function onCloseChange(openState: boolean) {
    if (openState === false) {
      reset();
      dispatch((state) => {
        state.modal.openModal = null;
        state.modal.options.entryType = null;
      });
    }
  }

  function trackEntryEvent() {
    const eventType = entryType;
    const eventName = "create_entry";
    return pushEventData({
      eventName,
      eventType,
      challengeGameName: eventType,
      eventAction: "create_bracket", // the create_bracket action label is intentional across all games.
      eventTarget: "create",
    });
  }

  const isOpen = useMemo(() => state.modal.openModal === "CREATE_CTP_ENTRY", [state.modal.openModal]);

  useEffect(() => {
    if (isOpen) {
      updateDataLayer({
        page: {
          pageInfo: {
            pageName: `${entryType} registration create entry`,
            mmlUrl: `ncaa:watchmml:play:${entryType}:create_entry`,
            section: "play",
            subsection: `play:${entryType}`,
            challengeGameName: entryType,
          },
        },
      });
    }
  }, [isOpen, updateDataLayer, entryType]);

  return (
    <MMLModal title="SELECT GAME MODE" isOpen={isOpen} handleClose={() => onCloseChange(false)} maxWidth={327}>
      <Description>
        <span>Choose the winner of all 32 conference tournaments.</span>
      </Description>
      <Form id="ncaa-play-create-entry" onSubmit={handleSubmit}>
        <div onKeyDown={handleKeyDown}>
          <GameModeRadioInput htmlFor="standard">
            <input
              ref={firstOptionRef}
              type="radio"
              id="standard"
              name="game-mode"
              value="standard"
              className="peer-input"
              checked={selectedGameMode === "standard"}
              onChange={handleGameModeSelect}
            />
            <div className="entry-option">
              <h3>Standard</h3>
              <ul>
                <li>Get points for each winner you get right</li>
                <li>Get half points if your pick is the runner-up</li>
              </ul>
            </div>
          </GameModeRadioInput>
          <GameModeRadioInput htmlFor="confidence">
            <input
              type="radio"
              id="confidence"
              name="game-mode"
              value="confidence"
              className="peer-input"
              checked={selectedGameMode === "confidence"}
              onChange={handleGameModeSelect}
            />
            <div className="entry-option">
              <h3>Confidence</h3>
              <ul>
                <li>Assign confidence points to each pick, from 1 to 32</li>
                <li>Get points for each winner you get right, based on confidence points</li>
                <li>Get half points if your pick is the runner-up</li>
              </ul>
            </div>
          </GameModeRadioInput>
        </div>
        <ConfirmButton
          $primary
          disabled={selectedGameMode === null || isCreatingEntry || createdEntry}
          size="md"
          type="submit"
        >
          {isCreatingEntry || createdEntry ? `Creating...` : `Confirm`}
        </ConfirmButton>
      </Form>
    </MMLModal>
  );
}
