import useGlobalState from "@hooks/useGlobalState";
import { colors } from "@styles/styleUtils";
import { useTheme } from "next-themes";
import { ThemeProvider as StyledComponentsProvider } from "styled-components";

export type AppTheme = {
  mode: "light" | "dark";
  isNative: boolean;
  isIframe: boolean;
} & typeof colors;

/**
 * A style provider component to give all styled components access to critical pieces of state via prop interpolation. Prop interpolation isn't the greatest but it can reduce imports.
 */
const StyleProvider = ({ children }) => {
  const { resolvedTheme } = useTheme();
  const [{ isIframe, isNative }] = useGlobalState();

  const theme = {
    ...colors,
    mode: resolvedTheme as "light" | "dark",
    isNative,
    isIframe,
  };

  return <StyledComponentsProvider theme={theme}>{children}</StyledComponentsProvider>;
};

export default StyleProvider;
