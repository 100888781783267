import useGlobalState from "@hooks/useGlobalState";
import { Session } from "@hooks/useSession";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiFetch, { dynamicSlcApiEndpoint } from "./useApiFetch";
import { SLCEntry } from "./useBundle";

/**
 * Hook to create an SLC lineup and update the slc 'session' query.
 */
export function useCreateLineupMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();
  const [, dispatch] = useGlobalState();

  interface SessionCreateEntryArgs {
    entryType: string;
  }

  function createLineup() {
    return fetchApiEndpoint<SLCEntry>(
      `${dynamicSlcApiEndpoint}/entries.json`,
      {
        method: "POST",
      },
      { applyGameErrorMessages: true },
    );
  }

  return useMutation<SLCEntry, Response, SessionCreateEntryArgs, SessionMutateContext>({
    mutationFn: createLineup,
    onMutate: () => {
      const prevSession = queryClient.getQueryData<Session>(["session"]);
      return { prevSession };
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ["bundle"],
      });
      queryClient.setQueryData(["session"], (prevSession: Session) => {
        const newSession = structuredClone(prevSession);
        newSession.entries = [...(newSession.entries || []), response];
        return newSession;
      });
      dispatch((state) => {
        state.modal.openModal = null;
      });
    },
    onError: (error, vars, context) => {
      queryClient.setQueryData(["session"], context.prevSession);
    },
  });
}

type SessionMutateContext = {
  prevSession: Session;
};

/**
 * Hook to update an SLC lineup and update the slc 'session' query.
 */
export function useEditLineupMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();

  function editEntry(vars: UpdateVars) {
    const { id, name, is_default } = vars;
    const body = JSON.stringify({ name, is_default, ...(vars.flags ? { flags: vars.flags } : {}) });
    return fetchApiEndpoint<SLCEntry>(
      `${dynamicSlcApiEndpoint}/entries/${id}.json`,
      {
        method: "PUT",
        body,
      },
      { applyGameErrorMessages: true },
    );
  }

  return useMutation<SLCEntry, Response, UpdateVars, SessionMutateContext>({
    mutationFn: editEntry,
    onMutate: () => {
      const prevSession = queryClient.getQueryData<Session>(["session"]);
      return { prevSession };
    },
    onSuccess: (entryResponse, variables) => {
      const { id, is_default } = variables;
      queryClient.invalidateQueries({
        queryKey: ["bundle"],
      });
      queryClient.setQueryData(["session"], (prevSession: Session) => {
        const newSession = structuredClone(prevSession);

        newSession.entries = newSession.entries
          .map((entry) => {
            if (entry.entry_id === id) {
              return entryResponse;
            }

            // if setting a default entry, make sure no others are set as default.
            if (is_default) {
              return { ...entry, is_default: false };
            }

            return entry;
          })
          .sort((a, b) => a.sequence - b.sequence);

        return newSession;
      });
    },
    onError: (error, vars, context) => {
      queryClient.setQueryData(["session"], context.prevSession);
    },
  });
}

interface UpdateVars {
  name: string;
  is_default: boolean;
  id: number;
  flags?: string;
}

/**
 * Hook to delete an SLC lineup and update the slc 'session' query.
 */
export function useDeleteLineupMutation() {
  const { fetchApiEndpoint } = useApiFetch();
  const queryClient = useQueryClient();

  function deleteEntry(vars: UpdateVars) {
    const { id, name, is_default } = vars;
    const body = JSON.stringify({ name, is_default });
    return fetchApiEndpoint(
      `${dynamicSlcApiEndpoint}/entries/${id}.json`,
      {
        method: "DELETE",
        body,
      },
      { applyGameErrorMessages: false },
    );
  }

  return useMutation({
    mutationFn: deleteEntry,
    onMutate: () => {
      const prevSession = queryClient.getQueryData<Session>(["session"]);
      return { prevSession };
    },
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["bundle"],
      });
      queryClient.setQueryData(["session"], (prevSession: Session) => {
        const { id, is_default } = variables;
        const newSession = structuredClone(prevSession);

        newSession.entries = newSession.entries.filter((entry) => entry.entry_id !== id);

        // if deleting a default entry, the entry with the lowest sequence becomes the new default entry.
        if (is_default) {
          const lowestSequenceEntry = newSession.entries.reduce((prev, current) => {
            return prev?.sequence < current.sequence ? prev : current;
          });

          newSession.entries = newSession.entries.map((entry) => {
            if (entry.entry_id === lowestSequenceEntry.entry_id) {
              return { ...entry, is_default: true };
            }
            return entry;
          });
        }

        return newSession;
      });
    },
    onError: (error, vars, context) => {
      queryClient.setQueryData(["session"], context.prevSession);
    },
  });
}
