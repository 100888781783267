import { Input } from "@styles/inputs.styled";
import { colors, media } from "@styles/styleUtils";
import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;

  form {
    margin: 0 7% 20px 7%;

    ${media.mvp} {
      max-width: 628px;
      width: 58%;
      margin: 0 auto;
    }

    ${media.mvp} {
      width: 90%;
    }
  }

  ${media.svp} {
    min-height: 50vh;
  }
`;

export const DescriptionContainer = styled.div`
  width: 80%;
  max-width: 350px;
  margin: 0 auto 25px auto;

  ${media.mvp} {
    padding: 32px 0 16px 0;
  }

  ${media.svp} {
    width: 90%;
    padding: 22px 0 0 0;
    margin-bottom: 25px;
  }
`;

export const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.14;
  text-align: center;

  ${media.mvp} {
    font-size: 1.6rem;
  }
`;

export const ButtonContainer = styled.div`
  button {
    display: block;
    width: 80%;
    max-width: 345px;
    margin: 0 auto;
  }

  ${media.svp} {
    button {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const MessageInput = styled(Input)`
  border: solid 0.5px ${colors["lvl_-5"]} !important;
  background-color: ${colors["lvl_5"]} !important;
  height: 32px;
  font-size: 1.4rem !important;
  color: ${colors["lvl_-5"]} !important;
`;

export const MessageTextArea = styled.textarea`
  border: solid 0.5px ${colors["lvl_-5"]};
  background-color: ${colors["lvl_5"]};
  border-radius: 3px;
  height: 32px;
  font-size: 1.4rem;
  width: 100%;
  max-width: 100%;
  height: 232px;
  margin-bottom: 5px;
  padding: 5px 12px;
  color: ${colors["lvl_-5"]};

  ${media.mvp} {
    margin-bottom: 30px;
  }

  ${media.svp} {
    margin-bottom: 10px;
  }
`;
