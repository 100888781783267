import useGlobalState from "@hooks/useGlobalState";
import StarOn from "@icons/default-star-on.svg";
import { Button } from "@styles/buttons.styled";
import { media, pxToRem } from "@styles/styleUtils";
import styled, { useTheme } from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: ${pxToRem(24)};
  }

  & svg {
    margin-right: 10px;
    width: 23px;
    height: 23px;
  }
`;

const HeaderContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  h3 {
    font-size: ${pxToRem(42)};
    margin-bottom: 10px;
    ${media.svp} {
      font-size: ${pxToRem(36)};
    }
  }
  p {
    font-size: ${pxToRem(15)};
    text-align: center;
    ${media.svp} {
      margin-bottom: 22px;
      max-width: 180px;
    }
  }
`;

const ContentsContainer = styled.div`
  margin-bottom: 20px;

  ${media.mvp} {
    margin-bottom: 32px;
  }

  img {
    margin: 0 auto;

    ${media.mvp} {
      max-width: 350px;
    }

    ${media.svp} {
      max-width: 250px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    max-width: 345px;
    margin-bottom: 20px;
  }
`;

export default function TradeConfirmation({ lineupName, isDefault }) {
  const [state, dispatch] = useGlobalState();
  const theme = useTheme();

  return (
    <MMLModal removeContentPadding maxWidth={380} svpFullScreen isOpen={state.modal.openModal === "TRADE_CONFIRMATION"}>
      <Title>
        {isDefault ? <StarOn /> : null}
        <h2>{lineupName}</h2>
      </Title>
      <HeaderContainer>
        <h3>CONGRATULATIONS!</h3>
        <p>You have successfully made your trades</p>
      </HeaderContainer>
      <ContentsContainer>
        <img src={`/assets/images/onboarding/3-Onboarding-Render-${theme.mode === "light" ? "LTMD" : "DKMD"}.gif`} />
      </ContentsContainer>
      <ButtonContainer>
        <Button
          onClick={() =>
            dispatch((state) => {
              state.modal.openModal = null;
            })
          }
          size="md"
          $primary
        >
          Continue
        </Button>
      </ButtonContainer>
    </MMLModal>
  );
}
