import SelectedTeam from "@components/marketplace/selected-team";
import useGame from "@hooks/useGame";
import useGlobalState from "@hooks/useGlobalState";
import { useMarketplace } from "@hooks/useMarketplace";
import useSession from "@hooks/useSession";
import LeftArrow from "@icons/LeftArrow.svg";
import { Button } from "@styles/buttons.styled";
import { FormErrorText } from "@styles/inputs.styled";
import { colors, pxToRem } from "@styles/styleUtils";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import {
  SelectedPicksList,
  TradePreviewColumn,
  TradePreviewColumnHeader,
  TradePreviewColumnHeaderContainer,
  TradePreviewTeamsContainer,
} from "../../styles/marketplace/tradePreview.styles";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";

const MMLModalWrapper = styled.div`
  *.modal-content-wrapper {
    padding: 24px 18px !important;
    padding-top: 44px !important;
  }
`;

const Description = styled.p`
  letter-spacing: 0.4px;
  font-size: ${pxToRem(12)};
  line-height: 16px;
  margin: 0 auto;
  text-align: center;
  max-width: 207px;
  color: ${colors["lvl_-2"]};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;

  & button {
    width: 100%;
  }
`;

const TradeClosingPreview = styled(TradePreviewColumn)`
  width: 100%;
  padding: 0;
`;

const TradeClosingPreviewTeamsContainer = styled(TradePreviewTeamsContainer)`
  padding: 10px 0;
`;

const SliderContainer = styled(Slider)``;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 15px;

  .prev-control,
  .next-control {
    padding: 5px;

    &:hover {
      cursor: pointer;
    }

    &.hide-control {
      visibility: hidden;
    }
  }

  .prev-control {
    margin-right: auto;
    margin-left: -5px;
  }

  .next-control {
    margin-right: -5px;
  }

  .page-indicator {
    font-size: 1.6rem;
    font-weight: 600;
    margin-right: auto;
    margin-left: auto;
    width: 100%;

    span {
      padding: 0 2px;
    }
  }
`;

const RightArrow = styled(LeftArrow)`
  transform: rotate(180deg);
`;

const TradeClosingPreviewHeaderContainer = styled(TradePreviewColumnHeaderContainer)`
  margin-bottom: 25px;

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

const CountdownTimer = styled.div`
  background: #f4f4fa;
  border-radius: 12px 12px 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: ${pxToRem(14)};
  text-align: center;
  padding: 5px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const ErrorMessageText = styled(FormErrorText)`
  font-size: ${pxToRem(12)};
`;

type TradeClosingProps = {
  bankBalance: string;
};

export default function TradeClosing({ bankBalance }: TradeClosingProps) {
  const [state, dispatch] = useGlobalState();
  const { data: game } = useGame();
  const [sliderRef, setSliderRef] = useState<Slider>(null);
  const [showBack, setShowBack] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const { cancelTradePreview, resetTradePreview, saveTradePreview } = useMarketplace();
  const { data: session } = useSession();
  const currentEntryId = state.lineup.activeEntryId;
  const currentEntry = session?.entries?.find((entry) => entry.entry_id === currentEntryId);
  const INITIAL_COUNTDOWN_TIMER_VAL = 60;
  const [secs, setTime] = useState(INITIAL_COUNTDOWN_TIMER_VAL);

  const teamsInSlider = state.modal.options.tradeClosingTeams;

  function handleSliderChange(index: number) {
    setPageIndex(index + 1);
    index !== 0 ? setShowBack(true) : setShowBack(false);
    index === teamsInSlider?.length - 1 ? setShowNext(false) : setShowNext(true);
  }

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    onInit: () => (index) => handleSliderChange(index),
    afterChange: (index) => handleSliderChange(index),
  };

  useEffect(() => {
    setShowBack(false);
    setShowNext(true);
  }, []);

  let timerId;

  const getSelectedTeam = (teamId: number) => game?.teams?.find((team) => team.team_id === teamId);

  useEffect(() => {
    if (state.modal.openModal === "TRADE_CLOSING") {
      const tick = () => {
        if (secs === 0) {
          discardTrade();
        } else {
          setTime(secs - 1);
        }
      };

      const timerId = setInterval(() => tick(), 1000);
      return () => {
        clearInterval(timerId);
      };
    }
  });

  function resetCountdown() {
    clearInterval(timerId);
    setTime(INITIAL_COUNTDOWN_TIMER_VAL);
  }

  const discardTrade = () => {
    dispatch((state) => {
      state.modal.openModal = null;
    });
    cancelTradePreview(currentEntry?.bank_balance);
    resetCountdown();
  };

  const submitTrade = async () => {
    await saveTradePreview().then(() => {
      resetTradePreview();
      dispatch((state) => {
        state.modal.openModal = null;
      });
      resetCountdown();
    });
  };

  const disableTradeConfirm = Number(bankBalance) < 0;

  return (
    <MMLModalWrapper>
      <MMLModal
        title="Trade Closing"
        maxWidth={303}
        showCloseIcon={false}
        isOpen={state.modal.openModal === "TRADE_CLOSING"}
      >
        <div>
          <Description>
            These teams have gone live. You have 60 seconds to confirm this trade or it will be discarded.
          </Description>
          <CountdownTimer>{secs}</CountdownTimer>
          <TradeClosingPreview>
            <TradeClosingPreviewTeamsContainer>
              <SelectedPicksList>
                <SliderContainer ref={setSliderRef} {...settings}>
                  {teamsInSlider &&
                    teamsInSlider.map((team) => (
                      <>
                        <TradeClosingPreviewHeaderContainer>
                          <TradePreviewColumnHeader>{team.isBuy ? "Buy" : "Sell"}</TradePreviewColumnHeader>
                        </TradeClosingPreviewHeaderContainer>
                        <li key={team.team_id}>
                          {team.team_id ? (
                            <SelectedTeam team={team} isTradeClosingModal={true} isSellingTeam={true} />
                          ) : (
                            <SelectedTeam
                              team={getSelectedTeam(team.slot_id)}
                              isTradeClosingModal={true}
                              isSellingTeam={true}
                            />
                          )}
                        </li>
                      </>
                    ))}
                </SliderContainer>
                {teamsInSlider?.length > 1 && (
                  <ControlsContainer>
                    <span className={`prev-control ${!showBack ? "hide-control" : ""}`} onClick={sliderRef?.slickPrev}>
                      <LeftArrow />
                    </span>
                    <span className="page-indicator">
                      {pageIndex} <span>OF</span> {teamsInSlider?.length}
                    </span>
                    <span className={`next-control ${!showNext ? "hide-control" : ""}`} onClick={sliderRef?.slickNext}>
                      <RightArrow />
                    </span>
                  </ControlsContainer>
                )}
              </SelectedPicksList>
            </TradeClosingPreviewTeamsContainer>
          </TradeClosingPreview>
        </div>
        <ErrorMessageText style={{ textAlign: "center" }} $shouldShow={disableTradeConfirm}>
          Not enough points to complete transaction.
        </ErrorMessageText>
        <ButtonContainer>
          <Button size="md" $primary onClick={() => submitTrade()} disabled={disableTradeConfirm}>
            Confirm Trade
          </Button>
          <Button size="md" $secondary onClick={() => discardTrade()}>
            Discard Trade
          </Button>
        </ButtonContainer>
      </MMLModal>
    </MMLModalWrapper>
  );
}
