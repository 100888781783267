import { colors, media, pxToRem } from "@styles/styleUtils";
import styled, { css, keyframes } from "styled-components";

export const AngledLineDivider = styled.div`
  width: 100%;
  height: 8px;
  background: url("/assets/images/landing/assets-angled-line-super-tight-lvl-2.png");
`;

export const ManageGroupdDialogCSS = css`
  border-radius: 0;
  max-width: 500px;
  padding: 0;

  ${media.mvp} {
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    border-radius: 0px;
    margin: 0;
  }

  & .dialog-title {
    text-align: center;
    font-weight: 900;
    font-size: ${pxToRem(20)};
    letter-spacing: -0.37px;
    margin-bottom: 10px;
    text-transform: uppercase;
    padding: 40px 0 0 0;

    ${media.mvp} {
      font-size: ${pxToRem(16)};
      letter-spacing: -0.3px;
      border-bottom: 0.5px solid ${colors.lvl_2};
      padding: 20px 0 10px 0;
    }

    ${media.svp} {
      font-size: ${pxToRem(16)};
      letter-spacing: 0.7px;
    }
  }
`;

// May or may not be needed for the SELECT_GROUP modal
const slideUp = keyframes`
  from { 
    transform: translateY(100%); 
  };
  to { 
    transform: translateY(0); 
    
  };
`;

const slideDown = keyframes`
  from { 
    transform: translateY(0); 
  };
  to { 
    transform: translateY(100%); 
  };
`;

export const BottomDrawerSelectCSS = css`
  padding: 22px 0;
  max-width: 410px;

  width: 100%;
  max-width: 1075px;
  max-height: 85vh;
  bottom: 0;
  top: auto;
  transform: translate(0%, 0%);
  left: 0;
  right: 0;
  border-radius: 12px 12px 0 0;
  padding-bottom: 0;
  margin: 0 auto;

  ${media.motion} {
    &[data-state="open"] {
      animation: ${slideUp} 350ms ease-in-out;
    }
    &[data-state="closed"] {
      animation: ${slideDown} 350ms ease-in-out;
    }
  }
`;
