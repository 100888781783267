import useAnalytics from "@hooks/useAnalytics";
import useGame from "@hooks/useGame";
import useGlobalState from "@hooks/useGlobalState";
import { useDeleteLineupMutation, useEditLineupMutation } from "@hooks/useLineupMutation";
import useSession from "@hooks/useSession";
import StarOff from "@icons/default-star-off.svg";
import StarOn from "@icons/default-star-on.svg";
import DeleteOff from "@icons/glyphs-bcg-delete-off.svg";
import { RefObject } from "react";
import { Button } from "@styles/buttons.styled";
import { Input, InputErrorText, InputRow } from "@styles/inputs.styled";
import { colors, media, pxToRem } from "@styles/styleUtils";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";

const LineupInputContainer = styled.div`
  height: 44px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  position: relative;
`;

const EditButton = styled.button`
  position: absolute;
  top: 0;
  right: 8px;
  height: 100%;
  letter-spacing: 1px;

  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: ${pxToRem(10)};

  svg {
    width: 28px;
    height: 28px;
  }

  &:focus-visible {
    outline: 2px solid ${colors["lvl_-2"]};
    border-radius: 3px;
  }
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled.button`
  color: var(--color_live_error);
  font-size: ${pxToRem(10)};
  font-weight: 500;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  ${media.mvp} {
    text-transform: none;
  }

  svg {
    margin-right: 6px;
    transform: scale(1);
    ${media.mvp} {
      margin: 0;
    }
  }

  &:focus-visible {
    outline: 2px solid ${colors.live_error};
    outline-offset: 4px;
    border-radius: 3px;
  }
`;

const FavoriteButton = styled.button<{ $isDeleteDisabled: boolean }>`
  width: 100%;
  margin-bottom: ${({ $isDeleteDisabled }) => !$isDeleteDisabled && "32px"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
  }

  h3 {
    margin-bottom: 7px;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: start;
    line-height: 1.07;
    letter-spacing: 0.5px;

    ${media.mvp} {
      font-size: ${pxToRem(12)};
      line-height: 1.25;
      letter-spacing: 0.4px;
    }
  }

  p {
    font-size: ${pxToRem(14)};
    line-height: 1.14;

    ${media.mvp} {
      font-size: ${pxToRem(12)};
      line-height: 1.33;
      letter-spacing: 0.4px;
    }
  }

  &:focus-visible {
    outline: 2px solid ${colors["lvl_-2"]};
    outline-offset: 4px;
    border-radius: 3px;
  }
`;

const ConfirmDeleteContainer = styled.div`
  text-align: center;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.svp} {
    min-height: 50vh;
  }

  h3 {
    margin-bottom: 28px;
    font-size: ${pxToRem(28)};
    font-weight: 600;
    font-style: normal;
    line-height: 0.96;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 20px;
    font-size: 1.6rem;
    line-height: 1.38;
  }

  div {
    display: flex;

    button:first-of-type {
      margin-right: 20px;
    }

    button {
      width: 100%;
    }
  }
`;

const LineupLabel = styled.label`
  font-size: 10px;
  font-style: normal;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export default function LineupSettings() {
  const [state, dispatch] = useGlobalState();
  const { data: game } = useGame();
  const { data: session } = useSession();
  const { updateDataLayer, pushEventData } = useAnalytics();
  const lineupInputRef = useRef(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentLineupName, setCurrentLineupName] = useState("");

  const lineupEntryId = Number(state.modal.options.entryId);
  const lineups = session?.entries || [];
  const originalLineup = lineups.find((entry) => entry.entry_id === lineupEntryId);

  const originalLineupName = originalLineup?.name ?? "";
  const favorite = originalLineup?.is_default ?? false;

  const { mutateAsync: editLineup, isPending: isEditLoading, error, isError } = useEditLineupMutation();
  const { mutateAsync: deleteLineup, isPending: isDeleteLoading } = useDeleteLineupMutation();

  const isDeleteDisabled = lineups.length < 2 || !game.state?.can_create_lineups;

  function resetModalState() {
    setEditMode(false);
    setShowConfirmDelete(false);
    setCurrentLineupName("");
  }

  function onCloseChange(openState: boolean) {
    if (openState === false) {
      resetModalState();
      dispatch((state) => {
        state.modal.openModal = null;
      });
    }
  }

  function handleSave(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    editLineup({ id: lineupEntryId, name: currentLineupName, is_default: favorite }).then(() => {
      resetModalState();
    });
  }

  function handleFavorite(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (!favorite) editLineup({ id: lineupEntryId, name: originalLineupName, is_default: true });
  }

  function handleDelete(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    deleteLineup({ id: lineupEntryId, name: originalLineupName, is_default: favorite }).then(() => {
      pushEventData({
        eventName: "delete_entry",
        eventAction: "delete_bracket",
        eventTarget: "delete_bracket",
        eventType: "slc",
        challengeGameName: "slc",
      });
    });
    onCloseChange(false);
  }

  function handleToggleEditMode(editMode: boolean) {
    if (editMode) {
      setCurrentLineupName(originalLineupName);
    }
    setEditMode(editMode);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (editMode) {
        lineupInputRef.current.focus();
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [editMode]);

  useEffect(() => {
    if (showConfirmDelete) {
      updateDataLayer({
        page: {
          pageInfo: {
            pageName: "slc lineup delete",
            mmlUrl: "ncaa:watchmml:play:slc:lineup:delete",
            section: "play",
            subsection: "play:slc",
            challengeGameName: "slc",
          },
        },
      });
    }
  }, [showConfirmDelete, updateDataLayer]);

  const isOpen = useMemo(() => state.modal.openModal === "LINEUP_SETTINGS", [state.modal.openModal]);

  useEffect(() => {
    if (isOpen) {
      updateDataLayer({
        page: {
          pageInfo: {
            pageName: "slc lineup settings",
            mmlUrl: "ncaa:watchmml:play:slc:lineup:settings",
            section: "play",
            subsection: "play:slc",
            challengeGameName: "slc",
          },
        },
      });
    }
  }, [isOpen, updateDataLayer]);

  return (
    <MMLModal
      maxWidth={450}
      svpFullScreen
      handleClose={() => onCloseChange(false)}
      isOpen={isOpen}
      title="LINEUP SETTINGS"
    >
      {!showConfirmDelete ? (
        <>
          <EditLineupName
            editMode={editMode}
            currentLineupName={currentLineupName}
            originalLineupName={originalLineupName}
            setCurrentLineupName={setCurrentLineupName}
            handleToggleEditMode={handleToggleEditMode}
            handleSave={handleSave}
            lineupInputRef={lineupInputRef}
          />
          <InputErrorText $shouldShow={isError} style={{ top: -26, textAlign: "center" }}>
            {error as any}
          </InputErrorText>
          <FavoriteButton $isDeleteDisabled={isDeleteDisabled} onClick={handleFavorite} disabled={isEditLoading}>
            <div>
              <h3>Favorite</h3>
              <p>Make this your default lineup</p>
            </div>
            {favorite ? <StarOn /> : <StarOff />}
          </FavoriteButton>
          {!isDeleteDisabled && (
            <DeleteButtonContainer>
              <DeleteButton onClick={() => setShowConfirmDelete(true)} disabled={isDeleteDisabled || isDeleteLoading}>
                <DeleteOff />
                Delete Lineup
              </DeleteButton>
            </DeleteButtonContainer>
          )}
        </>
      ) : (
        <>
          <ConfirmDeleteContainer>
            <h3>Are you sure you want to delete your lineup?</h3>
            <p>This lineup will be removed from the game, and you will not be able to recover it.</p>
            <div>
              <Button onClick={() => setShowConfirmDelete(false)} $secondary size="md">
                Cancel
              </Button>
              <Button onClick={handleDelete} $primary size="md">
                Delete
              </Button>
            </div>
          </ConfirmDeleteContainer>
        </>
      )}
    </MMLModal>
  );
}

const EditLineupRowContainer = styled.div`
  width: 100%;
`;

interface EditLineupNameProps {
  editMode: boolean;
  currentLineupName: string;
  originalLineupName: string;
  setCurrentLineupName: (eventValue) => void;
  handleToggleEditMode: (boolean) => void;
  handleSave: (e) => void;
  lineupInputRef?: null | RefObject<HTMLInputElement>;
}

export function EditLineupName({
  editMode,
  currentLineupName,
  originalLineupName,
  setCurrentLineupName,
  handleToggleEditMode,
  handleSave,
  lineupInputRef,
}: EditLineupNameProps) {
  return (
    <EditLineupRowContainer className={"edit-lineup-container"}>
      <LineupLabel htmlFor="Lineup Name">Lineup Name</LineupLabel>
      <InputRow>
        <LineupInputContainer>
          <Input
            ref={lineupInputRef}
            disabled={!editMode}
            value={editMode ? currentLineupName : originalLineupName}
            onChange={(e) => setCurrentLineupName(e.target.value)}
            placeholder="Lineup Name"
            aria-label="Lineup Name"
            maxLength={18}
            id="Lineup Name"
          />
          {!currentLineupName || originalLineupName === currentLineupName ? (
            editMode ? (
              <EditButton onClick={() => handleToggleEditMode(!editMode)}>SAVE</EditButton>
            ) : (
              <EditButton onClick={() => handleToggleEditMode(true)}>EDIT</EditButton>
            )
          ) : (
            <EditButton onClick={handleSave}>SAVE</EditButton>
          )}
        </LineupInputContainer>
      </InputRow>
    </EditLineupRowContainer>
  );
}
