import { Checkbox } from "@components/lineup/checkbox";
import useGlobalState from "@hooks/useGlobalState";
import { useRemoveLineupMutation } from "@hooks/useGroupMutation";
import { useGroup } from "@hooks/useGroups";
import useSession from "@hooks/useSession";
import { Button, CircleLoader } from "@styles/buttons.styled";
import { ListLoading } from "@styles/groups/groups.styled";
import { colors } from "@styles/styleUtils";
import { FormEvent, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";

const MMLModalWrapper = styled.div`
  *.modal-content-wrapper {
    padding: 16px !important;
  }
`;

const ContentContainer = styled.div`
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DescriptionContainer = styled.div`
  width: 80%;
  max-width: 350px;
  margin: 0 auto 25px auto;
`;

const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.14;
  text-align: center;
`;

const LineupRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
  border-top: 0.5px solid ${colors.lvl_2};

  &:first-child {
    border-top: none;
  }

  button {
    align-self: center;
    margin-right: 10%;
  }

  label {
    margin-right: auto;
    padding-left: 10%;
    align-self: center;
  }
`;

const LineupsContainer = styled.div`
  border-top: 0.5px solid ${colors.lvl_2};
  max-height: 35vh;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  text-align: center;
  padding-top: 32px;
  border-top: 0.5px solid ${colors.lvl_2};
  display: flex;
  justify-content: center;
  column-gap: 8px;

  button {
    width: 100%;
  }
`;
interface Props {
  id: number;
}

export default function RemoveLineupsModal({ id }: Props) {
  const { data: session } = useSession();
  const [currentPage, setCurrentPage] = useState(1);
  const { data: groupQueryData } = useGroup(id, currentPage);
  const [{ modal }, dispatch] = useGlobalState();
  const { mutate: removeLineup, isPending, isSuccess } = useRemoveLineupMutation();
  const myEntryIds = session?.entries?.map((x) => x.entry_id);

  const checkedIds: number[] = [];

  const [scrollSettings, setScrollSettings] = useState({
    listItems: [],
    showItems: 0,
    hasMore: false,
  });

  useEffect(() => {
    setScrollSettings({
      listItems: groupQueryData?.leaderboard
        ? scrollSettings.listItems.concat(groupQueryData?.leaderboard)
        : scrollSettings.listItems,
      showItems: groupQueryData?.leaderboard.length,
      hasMore: groupQueryData?.pagination.has_next,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupQueryData]);

  const memberList = useMemo(() => {
    return scrollSettings.listItems.filter((x) => {
      return !myEntryIds?.includes(x.entry_id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myEntryIds, groupQueryData]);

  const handleRemoveClick = (e: FormEvent) => {
    e.preventDefault();
    removeLineup({ entry_ids: checkedIds, group_id: id });
    setTimeout(() => {
      dispatch((state) => {
        state.modal.openModal = null;
      });
    }, 1000);
  };

  const handleToggleClick = (entry_id: number) => {
    if (checkedIds.some((x) => x == entry_id)) {
      const index = checkedIds.indexOf(entry_id);
      if (index > -1) {
        return checkedIds.splice(index, 1);
      }
    } else {
      return checkedIds.push(entry_id);
    }
  };

  const members = [...memberList];

  return (
    <MMLModalWrapper>
      <MMLModal
        maxWidth={303}
        isOpen={modal.openModal === "REMOVE_LINEUPS"}
        handleClose={() => {
          dispatch((state) => {
            state.modal.openModal = null;
          });
        }}
        title="Remove Lineups"
      >
        <ContentContainer>
          <DescriptionContainer>
            <Description>Select which lineups you would like to remove from the group</Description>
          </DescriptionContainer>
          <form>
            <InfiniteScroll
              dataLength={scrollSettings.listItems.length}
              next={() => setCurrentPage(currentPage + 1)}
              hasMore={scrollSettings.hasMore}
              loader={<ListLoading>Loading...</ListLoading>}
              endMessage={
                scrollSettings.listItems.length > 25 && (
                  <ListLoading style={{ textAlign: "center" }}>No more groups to load</ListLoading>
                )
              }
            >
              <LineupsContainer>
                {members?.length != 0 ? (
                  members?.map(({ name, entry_id }) => {
                    return (
                      <LineupRow key={entry_id} onClick={() => handleToggleClick(entry_id)}>
                        <label>{name}</label>
                        <Checkbox name={name} />
                      </LineupRow>
                    );
                  })
                ) : (
                  <div style={{ margin: "20px 0" }}>There are no lineups to remove.</div>
                )}
              </LineupsContainer>
            </InfiniteScroll>
            <ButtonContainer>
              <Button
                onClick={() =>
                  dispatch((state) => {
                    state.modal.openModal = null;
                  })
                }
                type="button"
                $secondary
                size="md"
              >
                Cancel
              </Button>
              <Button
                disabled={isPending || memberList?.length == 0}
                $primary
                type="submit"
                size="md"
                onClick={(e) => handleRemoveClick(e)}
              >
                {isPending ? (
                  <CircleLoader style={{ borderLeftColor: `${colors.lvl_5}` }} />
                ) : isSuccess ? (
                  "Success!"
                ) : (
                  "remove"
                )}
              </Button>
            </ButtonContainer>
          </form>
        </ContentContainer>
      </MMLModal>
    </MMLModalWrapper>
  );
}
