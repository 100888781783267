import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import useApiFetch, { staticSlcApiEndpoint } from "./useApiFetch";

export default function useTransactions(entryId: number) {
  const { fetchApiEndpoint } = useApiFetch();

  return useQuery({
    queryKey: ["transactions", entryId],

    queryFn: () =>
      fetchApiEndpoint<TransactionInterface[]>(`${staticSlcApiEndpoint}/entries/${entryId}/transactions.json`),
  });
}

export function useTeamLoyaltyPoints(entryId): {
  getTeamLoyaltyPoints: (round: number, teamId: number) => number;
  getHistoricalTeamLoyaltyPoints: (round: number) => number;
} {
  const { data: transactions } = useTransactions(entryId);

  return {
    getTeamLoyaltyPoints: (round: number, teamId: number) => {
      if (!transactions?.length || round < 2) {
        return 0;
      }
      return transactions.reduce((acc, transaction) => {
        let newVal = acc;
        if (transaction.round_id === round && transaction.reason === "dividend" && transaction.team_id === teamId) {
          newVal += Number(transaction.transaction_value);
        }
        return newVal;
      }, 0);
    },
    getHistoricalTeamLoyaltyPoints: (round: number) => {
      if (!transactions?.length || round < 2) {
        return 0;
      }
      return transactions.reduce((acc, transaction) => {
        let newVal = acc;
        if (transaction.round_id <= round && transaction.reason === "dividend") {
          newVal += Number(transaction.transaction_value);
        }
        return newVal;
      }, 0);
    },
  };
}
export function useReceivedLoyaltyPoints(entryId: number, round: number, myTeams: number[]) {
  const { data: transactions } = useTransactions(entryId);

  return useMemo(() => {
    if (!transactions || round < 2) {
      return false;
    }

    return transactions.some((transaction) => transaction.round_id === round && transaction.reason === "dividend");
  }, [transactions, round, myTeams]);
}

export interface TransactionInterface {
  created_at: string;
  entry_id: number;
  id: number;
  reason: string;
  round_id: number;
  slot_id: number;
  team_id: number;
  transaction_summary: string;
  transaction_value: string;
  updated_at: string;
}
