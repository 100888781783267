import useAnalytics from "@hooks/useAnalytics";
import useGame, { getGameTeamImage } from "@hooks/useGame";
import useGlobalState from "@hooks/useGlobalState";
import useSession from "@hooks/useSession";
import Bolt from "@icons/Bolt-lg.svg";
import { media, pxToRem } from "@styles/styleUtils";
import { useEffect, useMemo } from "react";
import settings from "src/settings";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { useTeamLoyaltyPoints } from "../../hooks/useTransactions";
import { ringsideCondensed } from "../../lib/fonts";
import { MMLLinkButton } from "@sportstech/f2p-ncaa-component-library";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
const { BASE_PATH } = settings;

const MMLModalWrapper = styled.div`
  *.modal-content-wrapper {
    padding: 16px !important;
    padding-top: 0 !important;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: center;
    font-size: ${pxToRem(24)};
    padding: 0 25px;
    margin-bottom: 8px;
    line-height: normal;
  }

  svg {
    margin: 22px 0;
  }
`;

const Description = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.5;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
`;

const TeamsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 12px 16px 12px;

  li {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const TeamInfoContainer = styled.div`
  align-items: flex-end;
  display: flex;
  font-family: ${ringsideCondensed.style.fontFamily};
  font-size: 2.2rem;
  font-stretch: condensed;
  font-style: normal;
  font-weight: ${ringsideCondensed.style.fontWeight};
  line-height: normal;
  letter-spacing: normal;
  margin-right: auto;

  sub {
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    position: relative;
    bottom: 3px;
  }
`;

const TeamName = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: flex-end;
  white-space: nowrap;

  sub {
    position: relative;
    display: block;
    font-size: 14px;
    margin-right: 5px;
  }
`;

const Loyalty = styled.span`
  font-size: 9px;
  line-height: normal;
  font-weight: 325;
  margin-right: 5px;
`;

const PointsValue = styled.span`
  font-family: ${ringsideCondensed.style.fontFamily};
  font-size: 2.2rem;
  font-stretch: condensed;
  font-style: normal;
  font-weight: ${ringsideCondensed.style.fontWeight};
  letter-spacing: normal;
  line-height: normal;
  margin-left: 5px;

  ${media.mvp} {
    font-size: 1.1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    width: 100%;
  }
`;

interface LineupLoyaltyPointsProps {
  id: string | string[];
  currentTournamentRound: number;
  myTeams: number[];
}

export default function LineupLoyaltyPoints({ id, currentTournamentRound, myTeams }: LineupLoyaltyPointsProps) {
  const [state, dispatch] = useGlobalState();
  const { data: game } = useGame();
  const { data: session } = useSession();
  const { getTeamLoyaltyPoints } = useTeamLoyaltyPoints(id);
  const { updateDataLayer } = useAnalytics();
  const { theme: theme } = useTheme();
  const imageBackground = theme === "dark" ? "bgd" : "bgl";

  const isOpen = useMemo(() => state.modal.openModal === "LINEUP_LOYALTY_POINTS", [state.modal.openModal]);

  const getTeam = (pickId) => {
    const selectedTeam = game?.teams?.find((team) => team.team_id === Number(pickId));

    return selectedTeam;
  };

  const entry = session?.entries?.find((entry) => entry.entry_id === Number(id));

  const loyaltyTeams = myTeams
    .filter((pick) => getTeamLoyaltyPoints(Number(currentTournamentRound), pick) > 0)
    .map((team) => getTeam(team));

  useEffect(() => {
    if (isOpen) {
      updateDataLayer({
        page: {
          pageInfo: {
            pageName: "slc",
            mmlUrl: "ncaa:watchmml:play:slc",
            section: "play",
            subsection: "play:slc",
            challengeGameName: "slc",
          },
        },
      });
    }
  }, [isOpen, updateDataLayer]);

  return (
    <MMLModalWrapper>
      <MMLModal
        handleClose={() => {
          dispatch((state) => {
            state.modal.openModal = null;
          });
        }}
        removeContentPadding
        maxWidth={303}
        isOpen={isOpen}
      >
        <Title>
          <h2>YOU&apos;VE GOT LOYALTY POINTS!</h2>
        </Title>
        <Description>
          You&apos;ve receieved loyalty points from these teams from keeping them in your lineup for more than one
          round.
        </Description>
        <TeamsListContainer>
          {loyaltyTeams.map((team) => (
            <>
              <li key={team.team_id}>
                <TeamInfoContainer>
                  <img src={getGameTeamImage(team.link, imageBackground)} width="32" height="32" alt={team.school} />
                  <TeamName>
                    <sub>{team.seed}</sub> {team.abbrev}
                  </TeamName>
                </TeamInfoContainer>
                <Loyalty>LOYALTY</Loyalty>
                <Bolt /> <PointsValue>{getTeamLoyaltyPoints(Number(currentTournamentRound), team.team_id)}</PointsValue>
              </li>
            </>
          ))}
        </TeamsListContainer>
        <ButtonContainer>
          <MMLLinkButton
            size="md"
            href={`${BASE_PATH}/lineup/${entry && entry.entry_id}`}
            onClick={() => {
              dispatch((state) => {
                state.modal.openModal = null;
              });
            }}
            $primary
          >
            See my lineup
          </MMLLinkButton>
        </ButtonContainer>
      </MMLModal>
    </MMLModalWrapper>
  );
}
