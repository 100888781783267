import useAnalytics from "@hooks/useAnalytics";
import useGlobalState from "@hooks/useGlobalState";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import { Button } from "@styles/buttons.styled";
import { media, pxToRem } from "@styles/styleUtils";
import { useEffect, useMemo } from "react";
import styled, { useTheme } from "styled-components";

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: ${pxToRem(24)};
    margin-bottom: 8px;
  }
  svg {
    margin: 22px 0;
  }
`;

const Description = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.5;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    width: 335px;
    ${media.svp} {
      width: 210px;
    }
    margin-bottom: 8px;
  }
`;

export default function MakeTrades() {
  const theme = useTheme();
  const [state, dispatch] = useGlobalState();
  const { updateDataLayer } = useAnalytics();

  const isOpen = useMemo(() => state.modal.openModal === "MAKE_TRADES", [state.modal.openModal]);

  useEffect(() => {
    if (isOpen) {
      updateDataLayer({
        page: {
          pageInfo: {
            pageName: "slc onboarding",
            mmlUrl: "ncaa:watchmml:play:slc:onboarding",
            section: "play",
            subsection: "play:slc",
            challengeGameName: "slc",
          },
        },
      });
    }
  }, [isOpen, updateDataLayer]);

  return (
    <MMLModal maxWidth={380} isOpen={isOpen}>
      <Title>
        <img src={`/assets/images/onboarding/3-Onboarding-Render-${theme.mode === "light" ? "LTMD" : "DKMD"}.gif`} />
        <h2>MAKING TRADES</h2>
      </Title>
      <Description>
        Sell any team you want (whether they&apos;ve lost or won) and you&apos;ll get their current point value added to
        your points bank. Then use your points bank to buy new teams.
      </Description>
      <ButtonContainer>
        <Button
          size="md"
          onClick={() =>
            dispatch((state) => {
              state.modal.openModal = null;
            })
          }
          $primary
        >
          Continue
        </Button>
      </ButtonContainer>
    </MMLModal>
  );
}
