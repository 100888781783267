import useAnalytics from "@hooks/useAnalytics";
import useGlobalState from "@hooks/useGlobalState";
import RulesOff from "@icons/glyphs-rules-off.svg";
import { Button } from "@styles/buttons.styled";
import { colors, pxToRem } from "@styles/styleUtils";
import Link from "next/link";
import { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import settings from "src/settings";
import styled, { useTheme } from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
const { BASE_PATH } = settings;

const ViewFullRules = styled.p`
  padding: 0 22px;
  font-size: ${pxToRem(12)};
  font-weight: 500;
  letter-spacing: 1.2px;
  transform: translateY(-8px);
  display: flex;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
  display: inline-flex;

  svg {
    margin-right: 8px;
  }

  a {
    text-decoration: none;
    text-transform: uppercase;
  }
`;

const CustomDot = styled.div`
  height: 8px;
  width: 8px;
  border: 1px solid var(--color_lvl_-5);
  border-radius: 50px;
`;

const SliderConatiner = styled(Slider)`
  padding-bottom: 30px;

  @media (max-height: 750px) {
    img {
      width: 100%;
      object-fit: cover;
      height: 325px;
    }
  }

  .slick-dots .slick-active div {
    background: ${colors.slc_accent};
  }
`;

const TextContentsContainer = styled.div`
  padding: 0 40px;

  h2 {
    font-size: ${pxToRem(20)};
    letter-spacing: 0px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 12px;
  }

  p {
    letter-spacing: 0;
    text-align: center;
    color: var(--color_lvl_-2);
  }
`;

const ControlsContainer = styled.div`
  margin-top: 50px;

  padding: 0 calc(16px + 22px);
  display: flex;
  justify-content: space-between;

  .prev {
    border: none;
  }

  .next {
    width: 128px;
  }
`;

export default function OnboardingCarousel() {
  const theme = useTheme();
  const [state, dispatch] = useGlobalState();
  const { updateDataLayer } = useAnalytics();
  const [sliderRef, setSliderRef] = useState<Slider>(null);

  const [showBack, setShowBack] = useState(false);
  const [showContinue, setShowContinue] = useState(false);

  function handleSliderChange(index: number) {
    index !== 0 ? setShowBack(true) : setShowBack(false);
    index === 4 ? setShowContinue(true) : setShowContinue(false);
  }

  const isOpen = useMemo(() => state.modal.openModal === "ONBOARDING_CAROUSEL", [state.modal.openModal]);

  useEffect(() => {
    if (isOpen) {
      updateDataLayer({
        page: {
          pageInfo: {
            pageName: "slc onboarding",
            mmlUrl: "ncaa:watchmml:play:slc:onboarding",
            section: "play",
            subsection: "play:slc",
            challengeGameName: "slc",
          },
        },
      });
    }

    return () => {
      setShowBack(false);
      setShowContinue(false);
    };
  }, [isOpen, updateDataLayer]);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    customPaging: () => <CustomDot />,
    onInit: () => (index) => handleSliderChange(index),
    afterChange: (index) => handleSliderChange(index),
  };

  return (
    <MMLModal svpFullScreen isOpen={isOpen}>
      <Link href={`${BASE_PATH}/how-to-play`}>
        <ViewFullRules>
          <RulesOff />
          Full Rules
        </ViewFullRules>
      </Link>
      <SliderConatiner ref={setSliderRef} {...settings}>
        <>
          <img src={`/assets/images/onboarding/1-Onboarding-Render-${theme.mode === "light" ? "LTMD" : "DKMD"}.gif`} />
          <TextContentsContainer>
            <h2>MAKE YOUR PICKS</h2>
            <p>
              Pick a lineup of five teams before the tournament starts, staying under a 1000-point budget. The higher a
              team&apos;s seed, the more they will cost.
            </p>
          </TextContentsContainer>
        </>
        <>
          <img src={`/assets/images/onboarding/2-Onboarding-Render-${theme.mode === "light" ? "LTMD" : "DKMD"}.gif`} />
          <TextContentsContainer>
            <h2>EARN POINTS</h2>
            <p>
              Teams get points if they win. The better the team they beat, the more points they get. When a team loses,
              their point total is locked at its last value.
            </p>
          </TextContentsContainer>
        </>
        <>
          <img src={`/assets/images/onboarding/3-Onboarding-Render-${theme.mode === "light" ? "LTMD" : "DKMD"}.gif`} />
          <TextContentsContainer>
            <h2>TRADE YOUR TEAMS</h2>
            <p>
              After every round, sell any of the teams in your lineup to add points to your bank. Use those points to
              buy new teams.
            </p>
          </TextContentsContainer>
        </>
        <>
          <img src={`/assets/images/onboarding/4-Onboarding-Render-${theme.mode === "light" ? "LTMD" : "DKMD"}.gif`} />
          <TextContentsContainer>
            <h2>STAY LOYAL, GET REWARDED</h2>
            <p>
              The longer you hold a team, the more you&apos;ll be rewarded. Earn Loyalty Points directly to your bank
              when a team stays in your lineup for multiple rounds.
            </p>
          </TextContentsContainer>
        </>
        <>
          <img src={`/assets/images/onboarding/5-Onboarding-Render-${theme.mode === "light" ? "LTMD" : "DKMD"}.gif`} />
          <TextContentsContainer>
            <h2>CUT DOWN THE NET</h2>
            <p>At the end of the tournament, the player with the most valuable lineup wins the game. Good luck!</p>
          </TextContentsContainer>
        </>
      </SliderConatiner>
      <ControlsContainer>
        {showBack ? (
          <Button $secondary size="md" className="prev" onClick={sliderRef?.slickPrev}>
            BACK
          </Button>
        ) : (
          <div></div>
        )}
        {showContinue ? (
          <Button
            size="md"
            $primary
            className="next"
            onClick={() =>
              dispatch((state) => {
                state.modal.openModal = null;
              })
            }
          >
            Continue
          </Button>
        ) : (
          <Button size="md" $secondary className="next" onClick={sliderRef?.slickNext}>
            Next
          </Button>
        )}
      </ControlsContainer>
    </MMLModal>
  );
}
