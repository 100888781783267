import TeamBubbleCongrats from "@components/marketplace/congrats-team-bubble";
import useAnalytics from "@hooks/useAnalytics";
import useGame, { isFirstFourPairs } from "@hooks/useGame";
import useGlobalState, { SelectedPick } from "@hooks/useGlobalState";
import { Button } from "@styles/buttons.styled";
import { colors, pxToRem } from "@styles/styleUtils";
import useSession from "@hooks/useSession";
import { useRouter } from "next/router";
import { EditLineupName } from "./LineupSettings";
import { useEffect, useMemo, useState } from "react";
import { useEditLineupMutation } from "@hooks/useLineupMutation";
import { InputErrorText } from "@styles/inputs.styled";
import styled from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";

const MMLModalWrapper = styled.div`
  .modal-content-wrapper {
    padding: 0px 16px 24px 16px !important;
  }
`;

const SubHeading = styled.p`
  color: ${colors["lvl_-2"]};
  font-size: ${pxToRem(12)};
  font-weight: normal;
  font-style: normal;
  line-height: ${pxToRem(16)};
  letter-spacing: 0.4px;
  text-align: center;
  max-width: 220px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const TeamListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .action-button {
    margin-bottom: 8px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default function PicksSavedCongrats(props: { selectedPicks: SelectedPick[] }) {
  const { selectedPicks } = props;
  const [state, dispatch] = useGlobalState();
  const { data: game } = useGame();
  const router = useRouter();
  const { pushEventData } = useAnalytics();
  const { data: session } = useSession();
  const { mutateAsync: editLineup, error, isError } = useEditLineupMutation();
  const [editMode, setEditMode] = useState(false);
  const [currentLineupName, setCurrentLineupName] = useState("");
  const [isErrorOverwrite, setIsErrorOverwrite] = useState(false);

  const lineupEntryId = Number(state.modal.options.entryId);
  const lineups = session?.entries || [];
  const originalLineup = lineups.find((entry) => entry.entry_id === lineupEntryId);

  const originalLineupName = originalLineup?.name ?? "";
  const favorite = originalLineup?.is_default ?? false;

  function handleToggleEditMode(editMode: boolean) {
    if (editMode) {
      setCurrentLineupName(originalLineupName);
    }
    setEditMode(editMode);
  }

  function resetEditLineupState() {
    setIsErrorOverwrite(false);
    setEditMode(false);
    setCurrentLineupName("");
  }

  function handleSave(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    editLineup({ id: lineupEntryId, name: currentLineupName, is_default: favorite }).then(() => {
      resetEditLineupState();
    });
  }

  const getSelectedTeam = (teamId) => {
    const combinedTeam = isFirstFourPairs(teamId, game?.teams, game?.first_four_pairs);
    return combinedTeam || game?.teams?.find((team) => team.team_id === teamId);
  };

  const isOpen = useMemo(() => state.modal.openModal === "PICKS_SAVED_CONGRATS", [state.modal.openModal]);

  useEffect(() => {
    setIsErrorOverwrite(isError);
  }, [isError]);

  useEffect(() => {
    if (isOpen) {
      pushEventData({
        eventName: "complete_entry",
        eventAction: "bracket_complete",
        eventType: "slc",
        eventTarget: "complete",
        challengeGameName: "slc",
      });
      try {
        window.trackMetrics({
          type: "starting-lineup-complete",
          data: {},
        });
      } catch (e) {} // eslint-disable-line
    }
  }, [isOpen, pushEventData]);

  return (
    <MMLModalWrapper>
      <MMLModal
        maxWidth={303}
        handleClose={() => {
          resetEditLineupState();
          dispatch((state) => {
            state.modal.openModal = null;
          });
        }}
        isOpen={isOpen}
        title={"Congratulations"}
      >
        <SubHeading>You have successfully picked your first five teams</SubHeading>
        <TeamListContainer>
          {selectedPicks.map((pick) => (
            <TeamBubbleCongrats key={pick.slot_id} team={getSelectedTeam(pick.slot_id)} />
          ))}
        </TeamListContainer>
        <ButtonContainer>
          <InputErrorText $shouldShow={isErrorOverwrite} style={{ textAlign: "center" }}>
            {error as any}
          </InputErrorText>
          <EditLineupName
            editMode={editMode}
            currentLineupName={currentLineupName}
            originalLineupName={originalLineupName}
            setCurrentLineupName={setCurrentLineupName}
            handleToggleEditMode={handleToggleEditMode}
            handleSave={handleSave}
          />
          <Button
            className="action-button"
            size="sm"
            onClick={() => router.push("/mens-tournament-run/dashboard")}
            $primary
          >
            Go To Dashboard
          </Button>
          <Button
            className="action-button"
            size="sm"
            onClick={() =>
              dispatch((state) => {
                state.modal.openModal = null;
              })
            }
            $secondary
          >
            Edit Picks
          </Button>
        </ButtonContainer>
      </MMLModal>
    </MMLModalWrapper>
  );
}
