import { defaultBCGErrors } from "@hooks/useApiFetch";
import useGlobalState from "@hooks/useGlobalState";
import { useInviteUserMutation } from "@hooks/useGroupMutation";
import { Button } from "@styles/buttons.styled";
import {
  ActionButton,
  ButtonContainer,
  ContentContainer,
  MessageTextArea,
  Note,
  TextAreaLabel,
} from "@styles/groups/settings/emailInvite.styles";
import { Input, InputErrorText, InputRow } from "@styles/inputs.styled";
import { useState } from "react";
import { EmailShareButton } from "react-share";
import { gameUrls } from "src/lib/url-helpers";
import settings from "src/settings";
import { ManageGroupdDialogCSS } from "@styles/modal.styled";
import useClipBoard from "@hooks/useClipboard";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";

const { APP_ENV } = settings;

export default function EmailGroupModal({ group, id, joinToken }) {
  const [{ modal }, dispatch] = useGlobalState();
  const { mutateAsync: sendInvite, isPending, isSuccess, isError, reset } = useInviteUserMutation();
  const [emails, setEmails] = useState("");
  const { copyToClipboard } = useClipBoard();
  const gameName = "March Madness Men's Tournament Run";
  const passwordlessShareUrl = `${gameUrls[APP_ENV]}${gameUrls.slcPath}/group/${id}?joinToken=${joinToken}&iid=bcg_share_web_other_group_email`;
  const textAreaDefaultValue = `You're invited to join "${group?.name}" in ${gameName}. Join here: ${passwordlessShareUrl}`;

  const [copied, setCopied] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (emails.length) {
      const emailsArray = emails
        .trim()
        .split(/[\s,]+/)
        .filter((x) => x != " ");
      sendInvite({ emails: emailsArray, group_id: id }).then(() => setTimeout(() => onOpenChange(false), 700));
    }
  };

  function onOpenChange(openState: boolean) {
    if (openState === false) {
      reset();
      dispatch((state) => {
        state.modal.openModal = null;
      });
    }
  }

  return (
    <MMLModal
      css={ManageGroupdDialogCSS}
      isOpen={modal.openModal === "EMAIL_GROUP"}
      title="Invite Friends"
      handleClose={() => {
        dispatch((state) => {
          state.modal.openModal = null;
        });
      }}
    >
      <ContentContainer>
        <form id="ncaa-play-message-group">
          <InputRow className="flex-flow-column">
            <Input
              id="subject"
              name="subject"
              type="text"
              placeholder="Recipients"
              aria-label="Recipients"
              onChange={(e) => setEmails(e.target.value)}
            />
            <Note>Note: Please seperate emails with a space to invite multiple people.</Note>
          </InputRow>
          <InputRow>
            <TextAreaLabel>Recipients will receive the following message:</TextAreaLabel>
            <MessageTextArea placeholder="Message" defaultValue={textAreaDefaultValue} />
          </InputRow>
          <InputRow className="space-between">
            <EmailShareButton
              className="align-center"
              subject={`Join "${group?.name}" in the ${gameName}`}
              body={`You're invited to join group "${group?.name}!" Join here: `}
              url={passwordlessShareUrl}
            >
              <ActionButton>Send Yourself</ActionButton>
            </EmailShareButton>
            <ActionButton as="button" type="button" onClick={() => copyToClipboard(textAreaDefaultValue, setCopied)}>
              {copied ? "Copied!" : "Copy to clipboard"}
            </ActionButton>
          </InputRow>
          <ButtonContainer>
            <Button onClick={(e) => onSubmit(e)} $primary type="submit" disabled={isSuccess || isPending}>
              {isSuccess ? "Success!" : "Send"}
            </Button>
          </ButtonContainer>
          <InputErrorText $shouldShow={isError} style={{ textAlign: "center" }}>
            {defaultBCGErrors.generic}
          </InputErrorText>
        </form>
      </ContentContainer>
    </MMLModal>
  );
}
