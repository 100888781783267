import { colors, media } from "@styles/styleUtils";
import { pxToRem } from "src/styles/styleUtils";
import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;

  form {
    margin: 0 7% 20px 7%;

    ${media.mvp} {
      max-width: 628px;
      width: 58%;
      margin: 0 auto;
    }

    ${media.mvp} {
      width: 90%;
    }
  }

  ${media.svp} {
    min-height: 50vh;
  }
`;

export const ButtonContainer = styled.div`
  button {
    display: block;
    width: 80%;
    max-width: 345px;
    margin: 0 auto;
  }

  ${media.svp} {
    button {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const MessageTextArea = styled.textarea`
  border: none;
  background-color: ${colors.lvl_4};
  border-radius: 3px;
  height: 32px;
  font-size: 1.4rem;
  width: 100%;
  max-width: 100%;
  height: 232px;
  margin-bottom: 5px;
  padding: 5px 12px;
  color: ${colors["lvl_-5"]};
  padding-top: 20px;
  font-size: ${pxToRem(12)};
  transition: all 0.2s ease-in-out;

  :focus {
    outline: 2px solid ${colors["lvl_-5"]};
    background-color: ${colors.lvl_5};
  }

  ${media.mvp} {
    margin-bottom: 30px;
  }

  ${media.svp} {
    margin-bottom: 10px;
  }
`;

export const Note = styled.p`
  font-size: 10px;
  font-weight: 300;
  margin-top: 10px;
`;

export const TextAreaLabel = styled.div`
  position: absolute;
  top: 0.5em;
  left: 0;
  width: 100%;
  pointer-events: none;
  text-align: center;
  font-size: 10px;
`;

export const ActionButton = styled.div`
  font-size: ${pxToRem(16)};
  font-weight: normal;
  letter-spacing: 0.04rem;
  text-decoration: none;
  border: none;
  color: ${colors.bcg_accent_1};
  transition: color 0.2s ease-in-out;

  :hover {
    color: inherit;
  }
`;
