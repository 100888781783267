import useGlobalState from "@hooks/useGlobalState";
import { useMarketplace } from "@hooks/useMarketplace";
import SaveWarningIcon from "@icons/ExitWithoutSaving.svg";
import { Button } from "@styles/buttons.styled";
import { pxToRem } from "@styles/styleUtils";
import { useRouter } from "next/router";
import settings from "src/settings";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import styled, { css } from "styled-components";
const { BASE_PATH } = settings;

const ModalContainerStyles = css`
  text-align: center;

  p {
    margin-bottom: 18px;
  }
`;

const Title = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: ${pxToRem(24)};
  margin-bottom: 8px;
  svg {
    margin: 22px 0;
  }
`;

const Description = styled.div`
  font-size: ${pxToRem(14)};
  text-align: center;
  margin-bottom: 10px;
  span {
    line-height: 1.5;
    display: block;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  & button {
    width: 250px;
  }
`;

export default function MarketplaceClosed({ showTradeView }) {
  const router = useRouter();
  const { savePicks, saveTradePreview } = useMarketplace();
  const [state, dispatch] = useGlobalState();

  function handleSaveAndExit() {
    showTradeView ? saveTradePreview() : savePicks();
    dispatch((state) => {
      state.modal.options.forceLeave = true;
    });
    router.push(`${BASE_PATH}/dashboard`);
  }

  return (
    <MMLModal
      maxWidth={320}
      showCloseIcon={false}
      css={ModalContainerStyles}
      isOpen={state.modal.openModal === "MARKETPLACE_CLOSED"}
    >
      <Title>
        <SaveWarningIcon />
        <p>THE MARKETPLACE IS CLOSED</p>
      </Title>
      <Description>
        <span>The marketplace has now closed.</span>
        <span>Would you like to save your changes?</span>
      </Description>
      <ButtonContainer>
        <Button onClick={handleSaveAndExit} size="md" $primary>
          Save And Exit
        </Button>
        <Button onClick={() => router.push(`${BASE_PATH}/dashboard`)} size="md" $secondary>
          Exit Without Saving
        </Button>
      </ButtonContainer>
    </MMLModal>
  );
}
