import useAnalytics from "@hooks/useAnalytics";
import { defaultBCGErrors } from "@hooks/useApiFetch";
import useGlobalState from "@hooks/useGlobalState";
import { useCommissionerGroupMessageMutation } from "@hooks/useGroupMutation";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import { Button } from "@styles/buttons.styled";
import {
  ButtonContainer,
  ContentContainer,
  Description,
  DescriptionContainer,
  MessageInput,
  MessageTextArea,
} from "@styles/groups/settings/message.styles";
import { InputErrorText, InputRow } from "@styles/inputs.styled";
import { FormEvent, useEffect, useMemo, useState } from "react";

interface Props {
  id: number;
}

export default function MessageGroupModal({ id }: Props) {
  const [, dispatch] = useGlobalState();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const { mutateAsync: messageGroup, isSuccess, isError, isPending, reset } = useCommissionerGroupMessageMutation();
  const [{ modal }] = useGlobalState();
  const { updateDataLayer } = useAnalytics();

  const isOpen = useMemo(() => modal.openModal === "GROUP_MESSAGE", [modal.openModal]);

  useEffect(() => {
    if (isOpen) {
      updateDataLayer({
        page: {
          pageInfo: {
            pageName: "slc groups message",
            mmlUrl: "ncaa:watchmml:play:slc:groups:detail:message",
            section: "play",
            subsection: "play:slc",
            challengeGameName: "slc",
          },
        },
      });
    }
  }, [isOpen, updateDataLayer]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    messageGroup({ subject, message, group_id: id }).then(() => setTimeout(() => handleClose(), 500));
  };

  function handleClose() {
    reset();
    dispatch((state) => {
      state.modal.openModal = null;
    });
  }

  return (
    <MMLModal
      removeContentPadding
      maxWidth={383}
      isOpen={isOpen}
      title="Message Group"
      handleClose={() => handleClose()}
    >
      <ContentContainer>
        <DescriptionContainer>
          <Description>All members of the group will receive the message via email</Description>
          <InputErrorText $shouldShow={isError} style={{ textAlign: "center" }}>
            {defaultBCGErrors.generic}
            {/* 📝Todo: Once BE is set up handle error message */}
            {/* {error.message} */}
          </InputErrorText>
        </DescriptionContainer>
        <form id="ncaa-play-message-group">
          <InputRow>
            <MessageInput
              id="subject"
              name="subject"
              type="text"
              placeholder="Message Subject"
              aria-label="Message Subject"
              onChange={(e) => setSubject(e.target.value)}
            />
          </InputRow>
          <InputRow>
            <MessageTextArea placeholder="Message" onChange={(e) => setMessage(e.target.value)} />
          </InputRow>
          <ButtonContainer>
            <Button onClick={(e) => onSubmit(e)} $primary size="md" type="submit" disabled={isSuccess || isPending}>
              {isSuccess ? "Success!" : "Send"}
            </Button>
          </ButtonContainer>
        </form>
      </ContentContainer>
    </MMLModal>
  );
}
