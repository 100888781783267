import { useQuery } from "@tanstack/react-query";
import useApiFetch, { dynamicSlcApiEndpoint, staticSlcApiEndpoint } from "./useApiFetch";
import { PickRecord } from "./useBundle";
import { IPagination, LeaderboardEntry } from "./useLeaderboard";

export function useGroups(pageNumber, query?) {
  const { fetchApiEndpoint } = useApiFetch(false);

  return useQuery({
    queryKey: ["groups", pageNumber, query],

    queryFn: () =>
      query != ""
        ? fetchApiEndpoint<GroupsQueryData>(`${staticSlcApiEndpoint}/groups/page/1.json?search=${query}`)
        : fetchApiEndpoint<GroupsQueryData>(`${staticSlcApiEndpoint}/groups/page/${pageNumber}.json`),
  });
}

export function useGroup(group_id, pageNumber) {
  const { fetchApiEndpoint } = useApiFetch(false);
  return useQuery({
    queryKey: ["group", group_id],

    queryFn: () =>
      fetchApiEndpoint<GroupLeaderboardQueryData>(
        `${staticSlcApiEndpoint}/leaderboards/group/${group_id}/page/${pageNumber}.json`,
      ),

    // disables query garbage collection
    gcTime: Infinity,

    // the data will never be considered stale
    staleTime: Infinity,
  });
}

export function useFilterSchoolGroups(pageNumber, schoolQuery?) {
  const { fetchApiEndpoint } = useApiFetch(false);
  return useQuery({
    queryKey: ["schoolGroup", pageNumber, schoolQuery],

    queryFn: () =>
      schoolQuery != ""
        ? fetchApiEndpoint<GroupsQueryData>(
            `${staticSlcApiEndpoint}/groups/filter/school/page/1.json?search=${schoolQuery}`,
          )
        : fetchApiEndpoint<GroupsQueryData>(`${staticSlcApiEndpoint}/groups/filter/school/page/${pageNumber}.json`),
  });
}

export function useGetRollOverGroups(group_id) {
  const { fetchApiEndpoint } = useApiFetch(true);

  return useQuery({
    queryKey: ["groups"],

    queryFn: () =>
      fetchApiEndpoint<GroupsQueryData>(`${dynamicSlcApiEndpoint}/groups/${group_id}/rollover-members.json`),
  });
}

export interface GroupsQueryData {
  groups: GroupData[];
  users?: RolloverUsersData[];
  pagination: IPagination;
}

interface RolloverUsersData {
  id: number;
  name: string;
}
export interface GroupData {
  group_id: number;
  entry_id: number;
  group_size: number;
  group_rank: number;
  is_private: boolean;
  name: string;
  creator_id: string | number;
  max_entries: number;
  can_join_postlock: boolean;
  verified_type: "not_verified" | "school" | "custom";
  color: string;
  image_url: string | null;
}

export interface GroupLeaderboardQueryData {
  group: GroupData;
  leaderboard: GroupLeaderboardEntry[];
  pagination: IPagination;
}
export interface GroupLeaderboardEntry extends LeaderboardEntry {
  current_round_picks: PickRecord;
}
