import { Round } from "@hooks/useGame";
import { useQuery } from "@tanstack/react-query";
import useApiFetch, { v3StaticApiEndpoint } from "./useApiFetch";
import useGlobalState from "./useGlobalState";
import { GroupData } from "./useGroups";

/**
 * Hook to access the Mega Bundle which contains entries from all games.
 */
export default function useBundle() {
  const [{ staticEndpointKey }] = useGlobalState();
  const { fetchApiEndpoint } = useApiFetch();

  return useQuery({
    queryKey: ["bundle"],
    queryFn: () => fetchApiEndpoint<Bundle>(`${v3StaticApiEndpoint}/${staticEndpointKey}`),
    enabled: Boolean(staticEndpointKey),
    gcTime: null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export interface Bundle {
  wbcg_entries?: BCGEntry[];
  mbcg_entries?: BCGEntry[];
  mslc_entries?: SLCEntry[];
  mctp_entries?: CTPEntry[];
  wctp_entries?: CTPEntry[];
  user: {
    bcgId: number;
    brand: string;
    forceLogout: boolean;
    id: string;
    mbcgPlayerStatus: number;
    wbcgPlayerStatus: number;
    mslcPlayerStatus: number;
    mctpPlayerStatus: number;
    wctpPlayerStatus: number;
    name: string;
    staticEndpointKey: string;
    urbanAirshipId: string;
    emailSubscribed: boolean | null;
    favoriteGame: "ncaamsalarycap" | "ncaabracketchallenge" | "not_set";
  };
}

export interface CTPEntry {
  picks: CTPPick[];
  name: string;
  percentile: number;
  total_points: number;
  rank: number;
  is_default: boolean;
  confidence_mode: boolean;
  sequence: number;
  group_memberships: GroupMembership[];
  /** Timestamp: e.g. "2022-02-03T11:59:22.000-05:00" */
  created_at: string;
  /** Timestamp: e.g. "2022-02-03T11:59:22.000-05:00" */
  updated_at: string;
  entry_id: number;
  tiebreaker: number;
}

export interface CTPPick {
  conference_id: number;
  team_id: number;
  confidence?: number;
}

export interface SLCEntry {
  bank_balance: string;
  name: string;
  net_worth: string;
  user_id: number;
  sequence: number;
  is_default: boolean;
  group_memberships: GroupMembership[];
  /** Timestamp: e.g. "2022-02-03T11:59:22.000-05:00" */
  created_at: string;
  /** Timestamp: e.g. "2022-02-03T11:59:22.000-05:00" */
  updated_at: string;
  entry_id: number;
  picks: {
    [round in Round["round_number"]]: PickRecord;
  };
  rank: number;
  net_worth_by_round: {
    [round in Round["round_number"]]: string;
  };
  /** JSON string we can parse and read for custom values instead of localStorage */
  flags?: string;
}

type TeamId = number;
type SlotNumbers = 1 | 2 | 3 | 4 | 5;
export type PickRecord = {
  [slot in SlotNumbers]?: TeamId;
};

// An Entry's Group Membership is similar to the overall group leaderboard data but different. Consitent values picked from GroupData and name is group_name here.
export type GroupMembership = Pick<
  GroupData,
  "group_id" | "entry_id" | "group_rank" | "group_size" | "verified_type" | "creator_id"
> & {
  group_name: string;
  join_token: string;
};

export interface BCGEntry {
  /** Timestamp: e.g. "2022-02-03T11:59:22.000-05:00" */
  createdAtTimestamp: string;
  groups: BCGGroup[];
  id: string;
  isDefault: boolean;
  isPostlock: boolean;
  name: string;
  percentile: number;
  picks: BCGPick[];
  /** Timestamp: e.g. "2022-02-03T11:59:22.000-05:00" */
  picksLockedInAt: string | null;
  possiblePointsRemaining: number;
  rank: number | null;
  tiebreakerLoser: number;
  tiebreakerWinner: number;
  totalPoints: number;
  tplusTotalPoints: number;
  upsetPossiblePointsRemaining: number;
  userId: string;
}

export interface BCGPick {
  gameId: string;
  teamId: string;
}

export interface BCGGroup {
  autoRenewEnabled: boolean;
  creatorId: string;
  groupMembershipKey: string;
  groupPoints: number;
  groupPossiblePointsRemaining: number;
  group_id: string;
  isPrivate: boolean;
  group_rank: number;
  maxMembersPerUser: number;
  membersCount: number;
  name: string;
  rank: null;
  scoringMode: "traditional" | "traditionalPlus";
  showPttcPromo: boolean;
  chatId: string;
}
