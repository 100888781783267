import BlueCheck from "@components/icons/glyphs-bcg-added-blue.svg";
import useGlobalState from "@hooks/useGlobalState";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { RadioFlex, RadioGroup, RadioLabel } from "@styles/inputs.styled";
import { media } from "@styles/styleUtils";
import Link from "next/link";
import React, { useMemo, useState } from "react";
import settings from "src/settings";
import styled from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
const { BASE_PATH } = settings;

const ContentContainer = styled.div`
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.svp} {
    padding: 10px 0 0 0;
  }
`;

export default function SelectGroup({ session, group }) {
  const [state, dispatch] = useGlobalState();
  const [value, setValue] = useState(group?.name);

  const handleValueChange = (name) => {
    setValue(name);
    dispatch((state) => {
      state.modal.openModal = null;
    });
  };

  const groups = useMemo(() => {
    const allMemberships = session?.entries
      ?.map((entry) => entry?.group_memberships)
      .flat(1)
      .filter((value, index, self) => {
        return index == self.findIndex((group) => group?.group_id == value?.group_id);
      });
    return allMemberships;
  }, [session.entries]);

  return (
    <MMLModal isOpen={state.modal.openModal == "SELECT_GROUP"} showCloseIcon={false}>
      <ContentContainer>
        <form id="ncaa-play-select-group">
          <RadioGroup defaultValue={value} aria-label="View density">
            {groups?.map((group, key) => {
              return (
                <Link key={group?.group_id} href={`${BASE_PATH}/group/${group?.group_id}`}>
                  <RadioFlex groupSelect onClick={() => handleValueChange(group?.group_name || group?.name)}>
                    <RadioLabel htmlFor={`r${key}`}>{group?.group_name || group?.name}</RadioLabel>
                    <RadioGroupPrimitive.Item value={group?.group_name || group?.name} id={`r${key}`}>
                      <RadioGroupPrimitive.Indicator>
                        <BlueCheck />
                      </RadioGroupPrimitive.Indicator>
                    </RadioGroupPrimitive.Item>
                  </RadioFlex>
                </Link>
              );
            })}
          </RadioGroup>
        </form>
      </ContentContainer>
    </MMLModal>
  );
}
