import useGlobalState from "@hooks/useGlobalState";
import { useJoinGroupMutation } from "@hooks/useGroupMutation";
import useSession from "@hooks/useSession";
import { Button } from "@styles/buttons.styled";
import { InputRow, PwInput, StyledVisibilityToggle } from "@styles/inputs.styled";
import { colors, pxToRem } from "@styles/styleUtils";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import settings from "src/settings";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import styled, { css } from "styled-components";

const { BASE_PATH } = settings;

const ModalContainerStyles = css`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  h2 {
    font-size: ${pxToRem(24)};
    margin-bottom: 8px;
  }

  p {
    max-width: 60%;
    margin-bottom: 25px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  margin-bottom: 1.6rem;
  color: ${colors.live_error};
`;

export default function GroupPassword() {
  const [state, dispatch] = useGlobalState();
  const { data: session } = useSession();
  const router = useRouter();
  const { mutateAsync: joinGroup, error } = useJoinGroupMutation();
  const [joinButtonDisabled, setJoinButtonDisabled] = useState(true);
  const [pwVisibilityPressed, setPwVisibilityPressed] = useState(false);
  const defaultEntry = session?.entries.filter((obj) => obj.is_default == true);
  const [errorMsg, setErrorMsg] = useState(null);

  const [password, setPassword] = useState("");

  const groupId = state.modal.options.activeGroup?.group_id;
  const groupName = state.modal.options.activeGroup?.name;

  async function handleJoinGroup(e) {
    e.preventDefault();
    await joinGroup({
      entry_ids: [defaultEntry[0] ? defaultEntry[0].entry_id : session?.entries[0].entry_id],
      group_id: groupId,
      password: password,
    }).then(() => {
      setJoinButtonDisabled(false);
      if (session?.entries?.length == 1) {
        dispatch((state) => {
          state.modal.openModal = null;
        });
        router.push(`${BASE_PATH}/group/${groupId}`);
      } else {
        dispatch((state) => {
          state.modal.openModal = "MANAGE_LINEUPS";
        });
      }
    });
  }

  useEffect(() => {
    if (error) setErrorMsg(error);
  }, [error]);

  return (
    <MMLModal
      handleClose={() => {
        setErrorMsg(null);
        dispatch((state) => {
          state.modal.openModal = null;
        });
      }}
      maxWidth={303}
      css={ModalContainerStyles}
      isOpen={state.modal.openModal === "GROUP_PASSWORD"}
      title="PRIVATE GROUP"
      description={`Please enter the password for "${groupName}"`}
    >
      <Form>
        <InputRow>
          <PwInput
            type={pwVisibilityPressed ? "text" : "password"}
            placeholder="Enter group password"
            aria-label="Enter group password"
            id="password"
            name="password"
            onChange={(e) => {
              e.target.value.length < 1 ? setJoinButtonDisabled(true) : setJoinButtonDisabled(false);
              setPassword(e.target.value);
              dispatch((state) => {
                state.modal.options.pwInput = e.target.value;
              });
            }}
            className={errorMsg === "That password is not correct" ? "error" : null}
          />
          <StyledVisibilityToggle
            pressed={pwVisibilityPressed}
            onPressedChange={setPwVisibilityPressed}
            className={errorMsg === "That password is not correct" ? "error" : null}
          />
        </InputRow>
        <ErrorMessage className="error">{errorMsg}</ErrorMessage>
        <Button disabled={joinButtonDisabled} onClick={(e) => handleJoinGroup(e)} type="submit" size="md">
          Join Group
        </Button>
      </Form>
    </MMLModal>
  );
}
