import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import useGlobalState from "@hooks/useGlobalState";
import { RadioFlex, RadioGroup, RadioGroupIndicator, RadioGroupRadio, RadioLabel } from "@styles/inputs.styled";
import { media } from "@styles/styleUtils";
import React from "react";
import styled from "styled-components";
import { AngledLineDivider } from "@styles/modal.styled";

type SortTeamsProps = {
  changeSortOrder: (sortValue: string) => void;
  activeSortOrder: string;
};

const ModalWrap = styled.div`
  *.modal-content-wrapper {
    h2 {
      margin-bottom: 16px;
    }
  }
`;

const ContentContainer = styled.div`
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    padding: 20px 0;
    line-height: 16px;
  }

  ${media.svp} {
    padding: 10px 0 0 0;
    width: 300px;
  }
`;

const Space = styled.div`
  height: 20px;
  width: 18px;
`;

export default function SortTeams({ changeSortOrder, activeSortOrder }: SortTeamsProps) {
  const [state, dispatch] = useGlobalState();

  const handleSortTeams = (value: string) => {
    changeSortOrder(value);
    dispatch((state) => {
      state.modal.openModal = null;
    });
  };

  return (
    <ModalWrap>
      <MMLModal
        isOpen={state.modal.openModal === "SORT_TEAMS"}
        title="Sort Teams"
        showCloseIcon={true}
        removeContentPadding={true}
        handleClose={() =>
          dispatch((state) => {
            state.modal.openModal = null;
          })
        }
        maxWidth={303}
      >
        <AngledLineDivider />
        <ContentContainer>
          <form>
            <RadioGroup
              defaultValue={activeSortOrder}
              aria-label="Sort Teams"
              onValueChange={(value) => handleSortTeams(value)}
            >
              <RadioFlex>
                <Space></Space>
                <RadioLabel htmlFor="r1">Game Start Time</RadioLabel>
                <RadioGroupRadio value="game-start-time" id="r1">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
              </RadioFlex>
              <RadioFlex>
                <Space></Space>
                <RadioLabel htmlFor="r2">Alphabetical</RadioLabel>
                <RadioGroupRadio value="alphabetical" id="r2">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
              </RadioFlex>
              <RadioFlex>
                <Space></Space>
                <RadioLabel htmlFor="r3">Current Points: High to Low</RadioLabel>
                <RadioGroupRadio value="current-points-high-low" id="r3">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
              </RadioFlex>
              <RadioFlex>
                <Space></Space>
                <RadioLabel htmlFor="r4">Current Points: Low to High</RadioLabel>
                <RadioGroupRadio value="current-points-low-high" id="r4">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
              </RadioFlex>
              <RadioFlex>
                <Space></Space>
                <RadioLabel htmlFor="r5">Potential Points: High to Low</RadioLabel>
                <RadioGroupRadio value="potential-points-high-low" id="r5">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
              </RadioFlex>
              <RadioFlex>
                <Space></Space>
                <RadioLabel htmlFor="r6">Potential Points: Low to High</RadioLabel>
                <RadioGroupRadio value="potential-points-low-high" id="r6">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
              </RadioFlex>
              <RadioFlex>
                <Space></Space>
                <RadioLabel htmlFor="r7">Seed: High to Low</RadioLabel>
                <RadioGroupRadio value="seed-high-low" id="r7">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
              </RadioFlex>
              <RadioFlex>
                <Space></Space>
                <RadioLabel htmlFor="r8">Seed: Low to High</RadioLabel>
                <RadioGroupRadio value="seed-low-high" id="r8">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
              </RadioFlex>
            </RadioGroup>
          </form>
        </ContentContainer>
      </MMLModal>
    </ModalWrap>
  );
}

export type SortOptions =
  | "game-start-time"
  | "alphabetical"
  | "current-points-high-low"
  | "current-points-low-high"
  | "potential-points-high-low"
  | "potential-points-low-high"
  | "seed-high-low"
  | "seed-low-high";
