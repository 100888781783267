import CheckIcon from "@icons/glyphs-check-box-on.svg";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { colors, media } from "@styles/styleUtils";
import { useState } from "react";
import styled from "styled-components";

/**
 * Usage: add `checked` and `onCheckedChange` props to handle state.
 * Docs: https://www.radix-ui.com/docs/primitives/components/checkbox
 */
export function Checkbox(props: CheckboxPrimitive.CheckboxProps) {
  const [checked, setChecked] = useState<CheckboxPrimitive.CheckedState>(props.checked || false);

  function handleCheckedChange(checked: boolean) {
    props.onCheckedChange?.(checked);
    setChecked(checked);
  }

  const isChecked = props.checked ?? checked === true;

  return (
    <StyledCheckbox {...props} checked={props.checked ?? checked} onCheckedChange={handleCheckedChange}>
      <CheckboxPrimitive.Indicator>{isChecked && <CheckIcon />}</CheckboxPrimitive.Indicator>
    </StyledCheckbox>
  );
}

const StyledCheckbox = styled(CheckboxPrimitive.Root)`
  all: unset;
  width: 22px;
  height: 22px;

  background-color: ${({ checked }) => (checked ? colors.bcg_accent_2 : "white")};
  border: ${({ checked }) => (checked ? "1px solid transparent" : `1px solid ${colors["lvl_-5"]}`)};
  transition: box-shadow 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 100%;
    height: 100%;
  }

  &:focus-visible {
    outline: 2px solid ${colors["lvl_-5"]};
    border-radius: 3px;
    outline-offset: 2px;
  }

  ${media.hover} {
    &:hover:not(:disabled) {
      cursor: pointer;
    }
  }
`;
