import useGlobalState from "@hooks/useGlobalState";
import { useDeleteGroupMutation } from "@hooks/useGroupMutation";
import { Button } from "@styles/buttons.styled";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import { ButtonContainer, Description, DescriptionContainer } from "@styles/groups/settings/message.styles";
import { colors } from "@styles/styleUtils";
import { useRouter } from "next/router";
import settings from "src/settings";
import { ManageGroupdDialogCSS } from "@styles/modal.styled";
const { BASE_PATH } = settings;

export default function DeleteGroupModal({ id }) {
  const [state, dispatch] = useGlobalState();
  const { mutateAsync: deleteGroup } = useDeleteGroupMutation();
  const router = useRouter();

  const onDeleteClick = () => {
    router.push(`${BASE_PATH}/groups`);
    deleteGroup(id);
  };

  return (
    <MMLModal
      css={ManageGroupdDialogCSS}
      isOpen={state.modal.openModal === "DELETE_GROUP"}
      title="Delete Group"
      handleClose={() => {
        dispatch((state) => {
          state.modal.openModal = null;
        });
      }}
    >
      <DescriptionContainer>
        <Description>
          Are you sure? This group will be removed from the game and you will not be able to recover it.
        </Description>
      </DescriptionContainer>
      <form>
        <ButtonContainer>
          <Button
            style={{ background: `${colors.live_error}`, color: `${colors.lvl_5}` }}
            type="submit"
            onClick={() => onDeleteClick()}
          >
            Delete
          </Button>
        </ButtonContainer>
      </form>
    </MMLModal>
  );
}
