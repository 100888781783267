export enum ENV_NAMES {
  DEV = "development",
  QA = "qa",
  STAGING = "staging",
  PROD = "production",
  TEST = "test",
}
type BackendDomains = "play" | "play-qa" | "play-staging" | "play-rehearsal" | "play-postlock" | "play-ss";
type APIHostnames = `https://${BackendDomains}.ncaa.com`;

const { QA, DEV, TEST } = ENV_NAMES;
const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV ?? DEV;
const API_BACKEND_HOSTNAMES = {
  qa: "https://play-qa.ncaa.com",
  rehearsal: "https://play-rehearsal.ncaa.com",
  staging: "https://play-staging.ncaa.com",
  postlock: "https://play-postlock.ncaa.com",
  ss: "https://play-ss.ncaa.com",
  production: "https://play.ncaa.com",
};

// We want to allow the backend api url to be set optionally via the cli.
// If not set via cli, we determine the backend api url via the app_env.
// The default backend api url is QA.
const API_BACKEND_URL: APIHostnames =
  API_BACKEND_HOSTNAMES[process.env.NEXT_PUBLIC_API_BACKEND] ??
  (API_BACKEND_HOSTNAMES[APP_ENV] || API_BACKEND_HOSTNAMES[QA]);

const SENTRY_DSN = [`${DEV}`, `${TEST}`].includes(APP_ENV)
  ? ""
  : "https://b4e182ee6727403d80b1d6a785297339@sentry.eks-demo.shub.fish/563";

const SEASON = 2022;

// Created this since we are so late in the season and if we update the above const it will break auth and possibly some other stuff.
const CURRENT_TOURNAMENT = 2024;

export const BASE_PATH = "/mens-tournament-run";

export default {
  APP_ENV,
  API_BACKEND_URL,
  SENTRY_DSN,
  ENV_NAMES,
  BASE_PATH,
  SEASON: SEASON as 2022, // give Season a literal type. Useful for defining variables that contain the curerent season. e.g. 'bcg2022Player'
  CURRENT_TOURNAMENT,
};
