import useGlobalState from "@hooks/useGlobalState";
import { MMLLinkButton } from "@sportstech/f2p-ncaa-component-library";
import { colors } from "@styles/styleUtils";
import { useRouter } from "next/router";
import settings from "src/settings";
import styled from "styled-components";
import { MMLModal } from "@sportstech/f2p-ncaa-component-library";
import { ManageGroupdDialogCSS } from "@styles/modal.styled";
import { ContentContainer, Description } from "./ManageLineup";
const { BASE_PATH } = settings;

const StyledContentContainer = styled(ContentContainer)`
  .copy {
    font-size: 12px;
    color: ${colors["lvl_-1"]};
    margin-bottom: 10px;
  }
  button {
    width: 95%;
    max-width: 330px;
    margin: 0 auto;
  }
`;

export default function GroupCreationSuccess() {
  const [state, dispatch] = useGlobalState();
  const router = useRouter();

  return (
    <MMLModal
      showCloseIcon={false}
      maxWidth={303}
      css={ManageGroupdDialogCSS}
      isOpen={state.modal.openModal === "GROUP_CREATION_SUCCESS"}
      handleClose={() => {
        dispatch((state) => {
          state.modal.openModal = null;
        });
      }}
      title="Success!"
    >
      <StyledContentContainer>
        <form>
          <Description className="copy">Your group has been created.</Description>
          <MMLLinkButton
            $primary
            size="sm"
            onClick={() => {
              router.push(`${BASE_PATH}/group/${state.modal.options.activeGroup.group_id}`);
              dispatch((state) => {
                state.modal.openModal = null;
                state.modal.options = {};
              });
            }}
          >
            View Group
          </MMLLinkButton>
        </form>
      </StyledContentContainer>
    </MMLModal>
  );
}
