import VisibilityOffIcon from "@icons/glyphs-visibility-off.svg";
import VisibilityOnIcon from "@icons/glyphs-visibility-on.svg";
import * as Toggle from "@radix-ui/react-toggle";
import { media } from "@styles/styleUtils";
import { RefAttributes, useState } from "react";
import styled from "styled-components";

/**
 * Usage: add `pressed` and `onPressedChange` props to handle state.
 * Docs: https://www.radix-ui.com/docs/primitives/components/toggle
 */
export function VisibilityToggle({ pressed, onPressedChange, ...otherProps }: VisibilityToggleProps) {
  const [localPressed, setLocalPressed] = useState(pressed ?? false);

  return (
    <StyledToggle
      aria-label="Toggle visibility"
      pressed={pressed}
      onPressedChange={onPressedChange ? onPressedChange : setLocalPressed}
      {...otherProps}
    >
      {pressed ?? localPressed ? <VisibilityOnIcon /> : <VisibilityOffIcon style={{ opacity: 0.5 }} />}
    </StyledToggle>
  );
}

const StyledToggle = styled(Toggle.Root)`
  all: unset;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.hover} {
    &:hover:not(:disabled) {
      cursor: pointer;
    }
  }
`;

type VisibilityToggleProps = Toggle.ToggleProps & RefAttributes<HTMLButtonElement>;
